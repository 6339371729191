import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectConfigState, SoundManager, soundManager, useCheckLobbyPage, dismissToasts, Sound, GameHelper } from '@quanticogames/gameclient-core';
import { isMobileApp } from 'constants/common';


const useSound = () => {
    const configState = useSelector(selectConfigState);
    const isLobby = useCheckLobbyPage();
    const isApp = isMobileApp()
    const isMobileDevice = GameHelper.isMobile();
    const [appState, setAppState] = React.useState('active'); // active, background, inactive
    useEffect(() => {
        SoundManager.muteVolume(!configState?.sound);
    }, [configState?.sound]);

    useEffect(() => {
        soundManager.setVolume(1);
    }, [configState?.soundVolume]);

    useEffect(() => {
        if (isLobby) {
            soundManager.play(Sound.backgroundLobby.toString());
            dismissToasts();
        } else {
            soundManager.stop(Sound.backgroundLobby.toString());
        }
    }, [isLobby]);

    const onFocus = () => {
        if (!isMobileDevice) return;
        soundManager.setVolume(1);
        soundManager.play(Sound.backgroundLobby.toString());
    };

    const onBlur = () => {
        if (!isMobileDevice) return;
        soundManager.stop(Sound.backgroundLobby.toString());
        soundManager.setVolume(0);
    };

    useEffect(() => {
        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        onFocus();
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        };
    }, []);

    useEffect(() => {
       
        if (isApp) {
            const msgHandler = function (event) {
                const data = event.data;
                if (data?.type === 'appState') setAppState(data.payload);
            }
            window.addEventListener('message', msgHandler);
            return () => window.removeEventListener('message', msgHandler);
        }
    }, []);

    useEffect(() => {
        if (appState === 'background') {
            soundManager.stop(Sound.backgroundLobby.toString());
            soundManager.setVolume(0);
        }
        if (appState === 'active') {
            soundManager.setVolume(1);
            soundManager.play(Sound.backgroundLobby.toString());
        }
    }, [appState]);
};

export default useSound;
