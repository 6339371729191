import { ElementWrapper, GameHelper, Sound, SoundManager, formatMoney } from '@quanticogames/gameclient-core';
import { gsap } from 'gsap';
import _ from 'lodash';
import { formatMoneyColpoGrosso } from 'utils/formatCurrency';

export interface ColpoGrossoOptions {
    data: any;
    container: string;
    onComplete: Function;
}

const POSSIBLE_MULTIPLIERS = [
    2.5, 10, 1000, 25000, 50000, 5, 100, 25, 50, 10000
];

export class ColpoGrosso extends ElementWrapper {
    private _onComplete: Function = () => { };
    private _container: string;
    private playing: boolean;
    private timeline: gsap.core.Timeline;
    private _data: any;
    private possiblePrizes: number[];

    constructor(options: ColpoGrossoOptions) {
        super();

        this.$element.classList.add('colpo-grosso-board');

        if (options.onComplete) {
            this._onComplete = options.onComplete;
        }

        this._container = options.container;
        this._data = options.data;
        const cost = this._data?.cost || 0;
        if(this._data?.cost === 0) {
            this.possiblePrizes = POSSIBLE_MULTIPLIERS.map(multiplier => multiplier);
        } else {
            this.possiblePrizes = POSSIBLE_MULTIPLIERS.map(multiplier => cost * multiplier);
        }

        const resizeListener = _.debounce(() => {
            this.handleResize();
            if (this.playing) this.start();
        }, 50);
        window.addEventListener('resize', resizeListener);
        this.handleResize();
    }

    public start() {
        this.reset();
        const $container = document.querySelector(this._container);
        this.mount($container);
        this.playing = true;

        this.dealCards(() => {
            this.playing = false;
            gsap.delayedCall(2, () => this._onComplete());
        });
    }

    private reset() {
        this.$element.innerHTML = '';
        this.timeline?.kill();

        this.unmount();
    }

    private createShuffledPrizes(): number[] {
        if (this._data.multipliers) {
            return this._data?.cost === 0 ? this._data.multipliers : this._data.multipliers.map(x => x * this._data?.cost);
        }

        const prize = this._data?.prize;
        const availablePrizes = this.possiblePrizes.filter(p => p !== prize);

        const randomPrizes = [];
        while (randomPrizes.length < 2) {
            const randomIndex = this.getRandomNumber(0, availablePrizes.length - 1);
            const randomPrize = availablePrizes[randomIndex];
            if (!randomPrizes.includes(randomPrize)) {
                randomPrizes.push(randomPrize);
            }
        }

        const prizes = [prize, ...randomPrizes];
        const shuffledPrizes = _.shuffle(prizes.filter(prize => prize !== undefined));

        return shuffledPrizes;
    }

    private dealCards(onComplete) {
        this.timeline = gsap.timeline({
            onComplete: () => onComplete(),
        });

        let positions;
        if (GameHelper.isMobile() && GameHelper.isPortrait()) {
            positions = [
                { x: 139, y: 130 },
                { x: 139, y: 316 },
                { x: 139, y: 503 }
            ];
        } else {
            positions = [
                { x: 348, y: 288 },
                { x: 825, y: 288 },
                { x: 1301, y: 288 }
            ];
        }

        SoundManager.play(Sound.cardShuffle);
        gsap.delayedCall(1, () => SoundManager.play(Sound.cardShuffle));

        const dealDuration = 0.5; // Thời gian cho mỗi lá bài từ chia bài đến vị trí đích
        const delayDuration = 2; // Thời gian delay trước khi chia bài
        const flipDuration = 1; // Thời gian cho mỗi lần zoom và flip
        const revealDuration = 1; // Thời gian ngửa bài

        const zoomStart = positions.length * dealDuration + delayDuration; // Thời gian bắt đầu zoom sau khi chia bài xong

        const shuffledPrizes = this.createShuffledPrizes();

        positions.forEach((pos, index) => {
            const $card = this.createCardElement();
            const prize = formatMoneyColpoGrosso(shuffledPrizes[index]);
            $card.dataset.prize = prize?.toString();
            $card.querySelector('.front-text').textContent = `${prize}`;
            $card.querySelector('.front-text').setAttribute('data-text', `${prize}`);
            this.$element.appendChild($card);

            // Set initial position and opacity
            gsap.set($card, { x: window.innerWidth / 2, y: -300, rotation: 180, opacity: 0, display: 'none', zIndex: 1 });

            // Deal cards animation
            this.timeline.to($card, { display: 'block', opacity: 1, x: pos.x, y: pos.y, rotation: 0, duration: dealDuration, onStart: () => SoundManager.play(Sound.cardDistribution) }, index * dealDuration + delayDuration);
        });

        const finalPrize = this._data?.prize || 0;

        const posibilePrizePos = shuffledPrizes.map(
            (value, index) => ({ value, index })
        );

        const listPrizePos = posibilePrizePos.filter(({ value }) => value === finalPrize);
        const prizeIndex = this.getRandomNumber(0, listPrizePos.length - 1);
        const winPos = listPrizePos[prizeIndex].index;

        const zoomAndFlipSequence = [1, 0, 2, 0, 1, 2];

        zoomAndFlipSequence.forEach((idx, i) => {
            const delay = zoomStart + i * flipDuration;
            const $card = this.$element.children[idx] as HTMLElement;

            this.timeline
                .to($card, {
                    scale: 1.12, duration: flipDuration / 2, onStart: () => {
                    }
                }, delay)
                .to($card, { scale: 1, duration: flipDuration / 2 }, delay + flipDuration / 2);
        });

        const cards = Array.from(this.$element.children);
        const chosenCard = cards[winPos] as HTMLElement;
        const nonChosenCards = cards.filter((_, idx) => idx !== winPos);

        // Flip the chosen card first
        this.timeline.to(chosenCard, {
            rotationY: 180, duration: revealDuration, onComplete: () => {
                this.revealCard(chosenCard);
            }
        });

        this.timeline.to(chosenCard, { scale: 1.15, duration: revealDuration, onStart: () => SoundManager.play(Sound.soundColpoGrosso2) });

        this.timeline.staggerTo(nonChosenCards, revealDuration, { rotationY: 180, duration: revealDuration, delay: revealDuration }, `+=${revealDuration}`);

        this.timeline.add(() => {
            const $textPremio = this.showTextChoose();
            this.$element.appendChild($textPremio);
        gsap.set($textPremio, { scale: 0.5, opacity: 0, display: 'none', zIndex: 1 });

        this.timeline
            .to($textPremio, { display: 'block', opacity: 0.5, scale: 1, duration: 0.5, onStart: () => SoundManager.play(Sound.soundColpoGrosso3) })
            .to($textPremio, { scale: 1, duration: 1 })
    
            gsap.delayedCall(2, () => {
                const $overlay = document.createElement('div');
                $overlay.style.position = 'absolute';
                $overlay.style.top = '0';
                $overlay.style.left = '0';
                $overlay.style.width = '100%';
                $overlay.style.height = '100%';
                $overlay.style.backgroundColor = 'black';
                $overlay.style.opacity = '0';
                $overlay.style.pointerEvents = 'none';
                $overlay.style.zIndex = '999';
                this.$element.appendChild($overlay);
    
                this.timeline.to($overlay, { opacity: 1, duration: 1 });
            });
            gsap.delayedCall(3, () => {
                onComplete();
            });
        }, `+=${revealDuration}`);
    }

    private getRandomNumber(min: number, max: number) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    private createCardElement(): HTMLElement {
        const $card = document.createElement('div');
        $card.classList.add('card-container');
        $card.innerHTML = `<div class="card-back"></div>
        <div class="card-front">
            <p class="front-top">PREMIO</p>
            <p class="front-text"></p>
            <p class="front-bottom">COLPO GROSSO</p>
        </div>`;
        return $card;
    }

    private revealCard($card: HTMLElement) {
        $card.classList.add('flipped');
    }

    private showTextChoose() {
        const finalPrize = this._data?.prize || 0;
        const $cardChoose = document.createElement('div');
        $cardChoose.classList.add('card-choose');
        const prize = formatMoneyColpoGrosso(finalPrize);
        $cardChoose.innerHTML = `Premio € ${prize}`;
        return $cardChoose;
    }

    private handleResize() {
        const wrapperEl = document.querySelector(this._container) as HTMLElement;
        const boardEl = document.querySelector('.colpo-grosso-container') as HTMLElement;
        if (wrapperEl) {
            const wW = boardEl.clientWidth;
            const wH = boardEl.clientHeight;
            let elW;
            let elH;
            if (GameHelper.isMobile() && GameHelper.isPortrait()) {
                elW = 375;
                elH = 812;
            } else {
                elW = 1920;
                elH = 1080;
            }

            const scaleH = elH / wH;
            const scaleW = elW / wW;
            const scale = Math.max(scaleH, scaleW);
            wrapperEl.style.width = elW + 'px';
            wrapperEl.style.height = elH + 'px';
            wrapperEl.style.transform = `scale(${(1 / scale).toFixed(3)})`;
        }
    }
}
