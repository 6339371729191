import { Box, styled, Typography } from '@mui/material';
import { ParticipantIcon } from '../../../../assets/icons/ParticipantIcon';
import { useTranslation } from 'react-i18next';
import { convertCentToEuro, numberWithDecimals } from 'utils/formatCurrency';

const Root = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const TextWrapper = styled(Box)(() => ({
  marginTop: '32px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const EmptyData = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <ParticipantIcon />
      <TextWrapper>
        <Typography variant="h5">{t("No participants yet")}</Typography>
      </TextWrapper>
    </Root>
  )
}

export const CrazyPrizeRule = ({ cost }) => {
  const { t } = useTranslation();
  const winUpPrize = (cost || 0) * 50000;
  return (
    <Root>
      <ParticipantIcon />
      <TextWrapper>
        <Typography variant="h5">{t('Win up to')} €{numberWithDecimals(convertCentToEuro(winUpPrize), 0)}</Typography>
      </TextWrapper>
    </Root>
  )
}
