import { CountDown } from "@quanticogames/gameclient-components"
import { ChatException, ChatExceptionType, chatActions, selectException, selectIsEndSession } from "@quanticogames/gameclient-core"
import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import ModalComponent from "shared/components/modal"

export const RateLimitChat = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState<any>();
    const exception = useSelector(selectException) as ChatException;

    useEffect(() => {
        if (!exception) return;

        setIsOpen(true);
        if (exception.type == ChatExceptionType.RateLimit) {
            setMessage(
                <>
                    {t('Too many requests')}! {t('Please try again in')}
                    <span className="chat-noti-countdown"><CountDown second={exception?.time} format="mm: ss" onComplete={() => setIsOpen(false)} /></span>
                    {t('seconds')}
                </>
            );
        } else if (exception.type == ChatExceptionType.Ban) {
            if (exception.time > 0) {
                setMessage(
                    <>
                        {t('Your account has been banned')}! {t('Please try again in')}
                        <span className="chat-noti-countdown"><CountDown second={exception?.time} format="mm: ss" onComplete={() => setIsOpen(false)} /></span>
                        {t('seconds')}
                    </>
                );
            } else {
                setMessage(t("Chat.BanForever"));
            }
        } else {
            setMessage(t("Chat.Error"));
        }
        dispatch(chatActions.resetException({}));
    }, [exception]);

    const onClose = () => {
        setIsOpen(false);
    }

    return (
        <ModalComponent
            open={isOpen}
            onClose={onClose}
            title="Chat Notification"
            size="small"
        >
            {message}
        </ModalComponent>
    )
}
