import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';
import "./index.scss"
export interface ToastProps {
    open: boolean;
    content: string;
    type: AlertColor;
    onClose: () => void;
 }

export const LobbyToast: React.FC<ToastProps> = ({open, content, type, onClose}) => {

    return (
        <div className='toast-lobby-container'>
            <Snackbar open={open} autoHideDuration={4000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={onClose}>
                <Alert
                    onClose={onClose}
                    severity={type}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {content}
                </Alert>
            </Snackbar>
        </div>
    )
};