import { selectFavoriteCashTables, CashTable } from "@quanticogames/gameclient-core";
import PinIcon from "assets/icons/Pin";
import PinActiveIcon from "assets/icons/PinActive";
import { useSelector } from "react-redux";
import React from "react";

interface FavoriteProps { 
    tour: CashTable;
    handleActionFavorite: (tour: CashTable, favorite: any) => void;
}

const Favorite = (props: FavoriteProps) => {
    const { tour, handleActionFavorite } = props;
    const favoriteTables = useSelector(selectFavoriteCashTables);
    const favorite = favoriteTables.find((f) => f.id === tour.id);
    return (
        <div className="tournament-favorite" id={`tournament-favorite-${tour?.id}`} onClick={(e: any) => {
            e.stopPropagation();
            handleActionFavorite(tour, favorite);
        }}>
            {favorite?.id ? <PinActiveIcon /> : <PinIcon />}
        </div>
    )
}

export default React.memo(Favorite);