

import { Button, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButtonUnchecked } from '@mui/icons-material';
import { RadioIcon } from 'assets/icons/RadioIcon'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBackIcon from '@mui/icons-material/NavigateBefore';
import { selectActiveClub } from '@quanticogames/gameclient-core';
import { useSelector } from 'react-redux';
import Timeout from 'pages/club/components/timeout';
interface ClubSettingsProps {
    invitePlayers: { label: string, value: string }[];
    setInvitePlayers: (value: { label: string, value: string }[]) => void;
    onNext: () => void;
    onBack: () => void;
}

const InviteItem = (props: { label: string, checked: boolean, handleCheck: () => void }) => {
    return (
        <div>
            <Checkbox
                icon={<RadioButtonUnchecked style={{
                    color: '#fff',
                    width: 26,
                    height: 25,
                }} />} checkedIcon={<RadioIcon />} checked={props.checked} onChange={props.handleCheck} /> {props.label}
        </div>
    )
}

const ListPlayerInvite = (props: ClubSettingsProps) => {
    const { invitePlayers, setInvitePlayers, onNext, onBack } = props;
    const activeClub: any = useSelector(selectActiveClub)
    const { t } = useTranslation('club')
    const [totalPlayers, setTotalPlayers] = useState(2);

    useEffect(() => {
        setTotalPlayers(activeClub?.clubSetting?.maxSeats || 2);
    }, [activeClub]);

    return (
        <div className='list-player-container'>
            <p className='title'>
                {t('2. Invite your players')}
            </p>
            <div className='list-invite'>
                <p style={{
                    textAlign: 'center',
                    margin: '10px 0',
                    fontSize: 16,
                }}>
                    {t('You')}
                </p>
                <div className={`other-invite ${totalPlayers > 5 ? 'other-invite-overflow' : null}`}>
                    {totalPlayers > 0 && Array.from({ length: totalPlayers - 1 }).map((_, index) => {
                        const checked = Boolean(invitePlayers[index]?.value);
                        return (
                            <InviteItem key={index} label={invitePlayers[index]?.label ?? t('Empty')} checked={checked} handleCheck={() => {
                                if (!checked) return;
                                setInvitePlayers(invitePlayers.filter((_, i) => i !== index))
                            }} />
                        )
                    })}
                </div>

            </div>
            <div className='bottom-container'>
                <Timeout phase="invite" isOwner={true} />
                <div className='list-invite-buttons'>
                    <div className='button-prev club-button'>
                        <NavigateBackIcon style={{
                            marginTop: 2,
                            color: '#fff'
                        }} />
                        <Button onClick={onBack}>{t('cancel')}
                        </Button>

                    </div>
                    <div className='button-submit club-button'>
                        <Button onClick={onNext}>{t('accept and invite')}
                        </Button>
                        <NavigateNextIcon style={{
                            marginTop: 2,
                            color: '#fff'
                        }} />
                    </div>
                </div>

            </div>

        </div>
    );
}

export default React.memo(ListPlayerInvite);