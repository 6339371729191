import { Tournament } from "@quanticogames/gameclient-core";
import { useEffect } from "react";

interface ListTournamentHelpersType {
    listTournaments: Tournament[];
    sortedListTournaments: Tournament[];
    onSelect: (id: number) => void;
}

export const ListTournamentHelpers = (props: ListTournamentHelpersType) => {
    const { listTournaments, sortedListTournaments, onSelect } = props;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tournamentSearch = params.get('code')
    const url = new URL(window.location.href);

    useEffect(() => {

    }, [sortedListTournaments]);

    useEffect(() => {
        if (tournamentSearch && sortedListTournaments.length > 0) {
            const tournamentIndex = sortedListTournaments.findIndex(tournament => tournament.code === tournamentSearch);
            if (tournamentIndex > -1) {
                onSelect(sortedListTournaments[tournamentIndex]?.id);
                window.history.replaceState(null, "", url.pathname);
                const element = document.getElementsByClassName('table-container')[0];
                const top = tournamentIndex * 50;
                if (element) {
                    element.scrollTo({ behavior: 'smooth', top: top });
                }
            }
        }
    }, [sortedListTournaments, url.pathname, listTournaments]);

}
