import {
    describeAssoPigliaTuttoMove,
    describeBestiaMove,
    describeBriscolaMove,
    describeBuracoMove,
    describeCirullaMove,
    describeItPokerMove,
    describePeppaMove,
    describePokerCashMove,
    describeScala40Move,
    describeScopaMove,
    describeScoponeMove,
    describeSetteEMezzoMove,
    describeSolitarioMove,
    describeTelesinaCashMove,
    describeTelesinaSitMove,
    describeTrentunoMove,
    describeTresetteMove,
    describeGinRummyMove,
} from './require';

export interface ComponentTypeMap {
    [key: string]: Function;
}

// Define game component types and their corresponding React components
export const componentTypes: ComponentTypeMap = {
    Scopa: describeScopaMove,
    ItalianPoker: describeItPokerMove,
    Burraco: describeBuracoMove,
    Scala40: describeScala40Move,
    Briscola: describeBriscolaMove,
    AssoPigliaTutto: describeAssoPigliaTuttoMove,
    Bestia: describeBestiaMove,
    Tresette: describeTresetteMove,
    Cirulla: describeCirullaMove,
    Telesina: describeTelesinaSitMove,
    TelesinaCash: describeTelesinaCashMove,
    Scopone: describeScoponeMove,
    SetteEMezzo: describeSetteEMezzoMove,
    SetteEMezzoCash: describeSetteEMezzoMove,
    Solitario: describeSolitarioMove,
    ItalianPokerCash: describePokerCashMove,
    Peppa: describePeppaMove,
    Trentuno: describeTrentunoMove,
    GinRummy: describeGinRummyMove,
};
