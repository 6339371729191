import { IClubSettings, convertCentToEuro, formatMoney, selectActiveClub } from '@quanticogames/gameclient-core'
import { mapMatchTypeToLabel, mapSpeed } from 'pages/club/components/helpers'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import "../index.scss"
const MatchInfo = () => {

    const activeClub: any = useSelector(selectActiveClub)
    const { t } = useTranslation();
    const settings = activeClub?.clubSetting as IClubSettings;
    
    return (
        <>
            <div className='club-information'>
                <p>{t("Tournament Code")}: {t(settings?.tournamentCode)}</p>
                {settings?.matchType && <p>{t("Mode")}: {t(mapMatchTypeToLabel(settings?.matchType, t))}</p>}
                {settings?.speed && <p>{t("Speed")}: {t(mapSpeed(settings?.speed, t).label)}</p>}
                {
                    <>
                        <p>{t("Cost")}: € {formatMoney(convertCentToEuro(settings?.cost))}</p>
                        <p>{t("Winner prize")}: € {formatMoney(convertCentToEuro(settings?.fee))}</p>
                    </>
                }
            </div>
        </>
    )
}

export default MatchInfo
