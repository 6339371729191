import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Box,
  styled,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  selectBalance,
  tournamentActions,
  selectWaitingTournament,
  Tournament,
  selectCurrentUser,
  User,
  selectErrorReplyTournament,
  ErrorTournamentReply,
  TournamentStatusCode,
  gameApi,
  selectActiveGame,
  selectBalanceInfo,
  BalanceInfo,
  RegistrationMethod,
  selectOrientation,
} from '@quanticogames/gameclient-core';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';
import ModalComponent from 'shared/components/modal';
import ButtonComponent from 'shared/components/button';
import { Plus } from 'assets/icons/Plus';
import { WalletIcon } from 'assets/icons/WalletIcon';
import { toast } from 'react-toastify';
import { FlexBox } from 'shared/Flex';


type ModalConfirmSubscribeProps = {
  isOpen: boolean;
  data: any;
  onCloseModal?: () => void;
  confirmToken?: (token: string) => void;
};

export const StyledBox = styled(Box)`
  margin-bottom: 25px;
  @media all and (max-width: 576px) {
    margin-bottom: 30px;
  }
  .mobile-landscape & {
    width: 100%;
  }
  .MuiFormControl-root {
    @media all and (max-width: 576px) {
      width: 100%;
    }
    .mobile-landscape & {
      width: 100%;
    }
  }
  .MuiInputBase-root {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 200px;
    @media all and (max-width: 576px) {
      width: 100%;
      height: 40px;
    }
    .mobile-landscape & {
      width: 100%;
      height: 40px;
    }
    input {
      text-align: center;
      padding: 0;
    }
  }
`;

const ModalConfirmSubscribe = ({ isOpen, data, onCloseModal, confirmToken }: ModalConfirmSubscribeProps) => {
  const fee = data?.fee;
  const cost = data?.cost;
  const bet = useMemo(() => cost - fee, [cost, fee]);
  const [isHanldeSubscribe, setIsHandleSubscribe] = useState<boolean>(false);
  const balance = useSelector(selectBalance) as number;
  const waitingTournament = useSelector(selectWaitingTournament(data?.id)) as Tournament;
  const dispatch = useDispatch();
  const tournamentId = data?.id;
  const hasTournamentWithPassword = data?.hasPassword == true;
  const passWord = data?.password;
  const errorTournament = useSelector(selectErrorReplyTournament(data?.id)) as ErrorTournamentReply;
  const [isOpenModalConfirmSubscribe, setIsOpenModalConfirmSubscribe] = useState<boolean>(false);
  const [value, setValue] = useState<string | number>('');
  const [messageError, setMessageError] = useState<boolean>(false);
  const [checkedPayToken, setCheckedPayToken] = useState<boolean>(false);
  const [chooseToken, setChooseToken] = useState();
  const [tokenList, setTokenList] = useState([]);
  const currentUser: User = useSelector(selectCurrentUser);
  const gameCode: any = useSelector(selectActiveGame);
  const balanceInfo = useSelector(selectBalanceInfo) as BalanceInfo;
  let totalBalance = formatMoney(convertCentToEuro(Number(balanceInfo?.balance ?? 0) + (balanceInfo?.bonusBalance ?? 0)));
  if(totalBalance === 'NaN') {
      totalBalance = '0.00';
  }
  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';

  const fetchData = async (isFetchNew = false) => {
    const rootApi = tournamentId ?
      `listAvailableToken?tournamentId=${tournamentId}&gameCode=${gameCode?.code}&userId=${currentUser?.id}` :
      `listToken?userId=${currentUser?.id}&status=1`;

    const response: any = await gameApi.getTokens(rootApi);
    setTokenList(response);
  }

  useEffect(() => {
    fetchData()
  }, []);

  const handleOpenToken = () => {
    setCheckedPayToken(true);
    setIsOpenModalConfirmSubscribe(true);
  };

  useEffect(() => {
    if (waitingTournament?.aamsCode || waitingTournament?.isWaiting || cost == 0) {
      setIsHandleSubscribe(true);
    }
  }, [waitingTournament?.aamsCode]);

  useEffect(() => {
    setTimeout(() => {
      if (waitingTournament?.aamsCode || (cost == 0 && waitingTournament?.aamsCode === null)) {
        handleCloseModal();
        return;
      }
    }, 1000);
  }, [waitingTournament]);

  const handleCloseModal = () => {
    onCloseModal();
    setMessageError(false)
    dispatch(
      tournamentActions.resetErrorReplyTournament({
        errorCode: 0,
        id: data?.id,
      })
    );
  };
  const handleConfirmSucribe = () => {
    setMessageError(true);

    if (hasTournamentWithPassword && waitingTournament?.aamsCode) {
      setIsHandleSubscribe(true);
    }

    if (checkedPayToken && !chooseToken) {
      toast.error(`${t('No token. Subscription failed')}`, {
        position: 'top-right',
        autoClose: 4000,
      });
      return;
    } 

    if(isAllowCreditOption === false && !checkedPayToken){
      toast.error(`${t('No pay option. Subscription failed')}`, {
        position: 'top-right',
        autoClose: 4000,
      });
      return;
    }

    dispatch(
      tournamentActions.subscribeTournament({
        tournamentId: tournamentId,
        tokenCode: chooseToken || null,
        tournamentPassword: value || passWord,
      })
    );
  };

  const handleClickCost = () => {
    setCheckedPayToken(false);
    setIsOpenModalConfirmSubscribe(false);
    setChooseToken(null);
  }

  const handleChooseToken = (event) => {
    const value = event.target.value
    setChooseToken(value);
    confirmToken(value);
  }

  const isShowTextConfirm = isHanldeSubscribe && waitingTournament?.aamsCode;

  const isAllowCreditOption = useMemo(() => {
    if(data?.registrationMethod && data?.registrationMethod !== RegistrationMethod.CREDIT) return false;
    return true;
  }, [data]);

  const isAllowTokenOption = useMemo(() => {
    if(data?.registrationMethod && data?.registrationMethod !== RegistrationMethod.TOKEN) return false;
    return data?.allowToken;
  }, [data]);

  return (
    <>
      <ModalComponent
        open={isOpen}
        title={t('Registration tournament')}
        onClose={onCloseModal}
        className='modal-subsccribe'
        size='small'
        showFooter={true}
        textCancel={(isMobileScreenWidth || isMobileLandscape) ? '' : t('Cancel')}
        onCancel={onCloseModal}
        textOk={`${(isShowTextConfirm || (balance < cost && balance !== undefined)) ? '' : t('Subscribe')}`}
        onOk={handleConfirmSucribe}
        typeColorOk={isMobileScreenWidth ? 'success' : 'primary'}
      >
        <div className={`${(isShowTextConfirm || (data?.cost === 0 && !isShowTextConfirm)) ? '' : 'flex-1'} subcribe-main h-full`}>
          {isShowTextConfirm ?
            (<p className="mb-[10px]">{t('Validating AAMS code')}...</p>) :
            <FlexBox
              className="modal-subsccribe-content"
              width="100%"
              height="100%"
              flexDirection={isMobileLandscape ? "row" : "column"}
              justifyContent="flex-start"
            >
              <FlexBox
                className="subcribe-left"
                flexDirection="column"
                alignItems={isMobileLandscape ? "flex-start" : "center"}
                justifyContent="flex-start"
                width="100%"
                height={isMobileLandscape ? "100%" : "auto"}
              >
                {data?.cost !== 0 && (
                  <div className='subcribe-money'>
                    <p className="subcribe-title">{t('Your current balance')}</p>
                    <div className="header-balance">
                      <WalletIcon />
                      <div className="header-balance-text"> € {totalBalance}</div>
                      <ButtonComponent
                        size="small"
                        icon={<Plus />}
                        variant="text"
                        className="header-balance-btn"
                        sx={{
                          padding: 0,
                          minWidth: 0
                        }}
                      />
                    </div>
                  </div>
                )}
                {hasTournamentWithPassword && (
                  <>
                    <p className="font-medium mb-[15px]">
                      {t('Enter password to register')}
                    </p>
                    <StyledBox>
                      <TextField
                        placeholder={t('*******')}
                        value={passWord || value}
                        onChange={(e) => setValue(e.target.value)}
                        error={false}
                        variant="outlined"
                        className='text-center subcribe-password-input'
                      />
                      {(messageError && errorTournament?.errorCode == TournamentStatusCode.wrongPassword) && (
                        <p className="text-error">
                          {t('Wrong password')}! {t('The registration was unsuccessful')}
                        </p>
                      )}
                    </StyledBox>
                  </>
                )}
              </FlexBox>
              {data?.cost !== 0 && (
                <FlexBox
                  className="subcribe-right"
                  flexDirection="column"
                  alignItems={isMobileLandscape ? "flex-start" : "center"}
                  justifyContent="flex-start"
                  width="100%"
                  height={isMobileLandscape ? "100%" : "auto"}
                >
                  <div className="subcribe-pay">{t('Choose how to pay')}</div>
                  <FlexBox justifyContent={isMobileLandscape ? "flex-start" : "center"} width={isMobileLandscape ? "100%" : "auto"}>
                    <FormControl className="form-control w-full">
                      <RadioGroup defaultValue="Matto" name="payment_method">
                        {isAllowCreditOption ? <>
                          {(balance >= cost) ? <FormControlLabel
                            value="Matto"
                            control={<Radio size="small" style={{ padding: '5px' }} />}
                            checked={!checkedPayToken}
                            onClick={handleClickCost}
                            label={
                              <>
                                <span>{`€ ${formatMoney(convertCentToEuro(Number(cost)))}`}</span>
                                {data?.type !== 'Crazy_Prize' && (
                                  <span>
                                    (€ {formatMoney(convertCentToEuro(Number(bet)))} + €
                                    {formatMoney(convertCentToEuro(Number(fee)))})
                                  </span>
                                )}
                              </>
                            }
                          /> :
                            <FormControlLabel
                              value="Matto"
                              control={<Radio size="small" style={{ padding: '5px' }} />}
                              checked={false}
                              label={t('Insufficient credit')}
                              disabled={true}
                            />}
                        </> : <></>
                        }
                        {isAllowTokenOption && <FormControlLabel
                          value="Choose_your_token"
                          control={<Radio size="small" style={{ padding: '5px' }} />}
                          label={t('Choose your token')}
                          onClick={() => handleOpenToken()}
                          checked={checkedPayToken}
                        />}
                        {isOpenModalConfirmSubscribe && (
                          <Select
                            value={chooseToken || ''}
                            onChange={handleChooseToken}
                            aria-label="Token"
                            variant="outlined"
                            className='select-component mt-[5px]'
                            displayEmpty
                          >
                            <MenuItem value="" disabled>{t('Choose token')}</MenuItem>
                            {(tokenList || []).map((item, index) => (
                              <MenuItem
                                key={index}
                                value={item.code}
                                className='!text-[#8E8E8E] !py-[4px] !px-[10px]'
                              >
                                <span className='flex-1'>{item.tokenIssuanceName}</span>
                                <span>€ {formatMoney(convertCentToEuro(Number(item.remainValue)))}</span>
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </RadioGroup>
                    </FormControl>
                  </FlexBox>
                </FlexBox>
              )}
            </FlexBox>
          }
        </div>
      </ModalComponent>
    </>
  );
};
export default ModalConfirmSubscribe;
