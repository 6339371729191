import React from 'react';
import { Box, styled, Typography, useTheme } from '@mui/material';
import { CommonTable } from '../CommonTable';
import { WarningTypeIconSvg } from '../../assets/icons/WarningTypeIconSvg';
import { useTranslation } from 'react-i18next';
import WarningItem from 'shared/Warnings/WarningItem/WarningItem';

export const ContentBox = styled(Box)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    @media (orientation: landscape) {
      display: flex;
    }
  }
`;
export const WarningsBox = styled(Box)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.breakpoints.down('md')} {
    @media (orientation: landscape) {
      flex-direction: column;
      padding: 11px 0 16px 51px;
    }
  }
`;
export const WarningBox = styled(Box)`
  width: 102px;
  height: 102px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid;

  &:nth-of-type(1) {
    background-color: ${({ theme }) => theme.palette.primary.light};
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    @media (orientation: landscape) {
      margin-bottom: 16px;
    }
  }
  &:nth-of-type(2) {
    background-color: ${({ theme }) => theme.palette.error.light};
    border-color: ${({ theme }) => theme.palette.error.main};
    color: ${({ theme }) => theme.palette.error.main};
    @media (orientation: landscape) {
      margin-bottom: 16px;
    }
  }
  &:nth-of-type(3) {
    background-color: ${({ theme }) => theme.palette.warning.light};
    border-color: ${({ theme }) => theme.palette.warning.main};
    color: ${({ theme }) => theme.palette.warning.main};
  }

  .MuiTypography-root {
    font-size: 2.375rem;
    font-weight: 700;
    line-height: 38px;
    ${({ theme }) => theme.breakpoints.down('md')} {
      @media (orientation: landscape) {
        font-size: 2.125rem;
      }
    }

    &:nth-of-type(2) {
      font-size: 0.625rem;
      font-weight: 400;
      line-height: 12px;
      margin-top: 4px;
      ${({ theme }) => theme.breakpoints.down('md')} {
        @media (orientation: landscape) {
          font-size: 0.875rem;
          line-height: 20px;
        }
      }
    }
  }
`;

export const TableBox = styled(Box)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    @media (orientation: landscape) {
      width: 100%;
      margin: 12px 51px 0 51px;
    }
  }
`;
const HEAD_DATA = [
  {
    id: 'name',
    label: 'Game',
  },
  {
    id: 'badge',
    label: 'Warn. type',
  },
  {
    id: 'date',
    label: 'Date',
  },
];

const DATA = [
  {
    id: 2,
    game: [{ id: 1, name: 'Briscola' }],
    warning: [
      { id: 1, type: 'yellow', name: 'Yellow', icon: <WarningTypeIconSvg /> },
    ],
    date: '09/11/22',
  },
  {
    id: 14,
    game: [{ id: 14, name: 'Solitario' }],
    warning: [
      { id: 1, type: 'yellow', name: 'Yellow', icon: <WarningTypeIconSvg /> },
    ],
    date: '09/11/22',
  },
  {
    id: 4,
    game: [{ id: 1, name: 'Asso Piglia tutto' }],
    warning: [
      { id: 1, type: 'red', name: 'Red', icon: <WarningTypeIconSvg /> },
    ],
    date: '09/11/22',
  },
  {
    id: 5,
    game: [{ id: 1, name: 'Solitario 2' }],
    warning: [{ id: 1, type: '', name: '--', icon: '' }],
    date: '09/11/22',
  },
];

type WarningsProps = {
  onClose?: React.MouseEventHandler<HTMLElement>;
};
export const Warnings: React.FC<WarningsProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const countRed = DATA.map((item) => item.warning[0].type === 'red').filter(
    Boolean
  ).length;

  const countYellow = DATA.map(
    (item) => item.warning[0].type === 'yellow'
  ).filter(Boolean).length;

  const countNotEmpty = DATA.map((item) => item.warning[0].type !== '').filter(
    Boolean
  ).length;

  return (
    <>
      <ContentBox sx={{ paddingBottom: '25px' }}>
        <WarningsBox>
          <WarningBox>
            <Typography>{countNotEmpty}</Typography>
            <Typography>{t('Total')}</Typography>
          </WarningBox>
          <WarningBox>
            <Typography>{countRed}</Typography>
            <Typography>{t('Red Warning')}</Typography>
          </WarningBox>
          <WarningBox>
            <Typography>{countYellow}</Typography>
            <Typography>{t('Yellow Warning')}</Typography>
          </WarningBox>
        </WarningsBox>
        <TableBox>
          <CommonTable
            showHeadOnMobile
            tableHeadData={HEAD_DATA}
            headerSx={{
              '.MuiTableCell-root.MuiTableCell-head': {
                '&:last-of-type': {
                  textAlign: 'right',
                },
              },
              [theme.breakpoints.down('md')]: {
                '@media (orientation: landscape)': {
                  '.MuiTableCell-root.MuiTableCell-head': {
                    padding: '12px 0 16px 0',
                  },
                },
              },
            }}
            sx={{
              pt: { md: '16px' },
            }}
          >
            {DATA.map((item) => (
              <WarningItem key={`warning-item-${item.id}`} data={item} />
            ))}
          </CommonTable>
        </TableBox>
      </ContentBox>
    </>
  );
};
