import { Box, styled } from "@mui/material";
import { COLORS } from "constants/colors";

export const ChatBox = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const MessageText = styled(Box)`
  padding: 12px;
  margin: 0 0 6px 32px;
  border-radius: 8px;
  color: ${COLORS.text_color};
  background-color: ${COLORS.text_bg};
  max-width: 307px;
  width: max-content;
  line-height: 17px;
  font-size: 14px;
  padding-bottom: 6px;
  flex-direction: row;

  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 296px;
    @media (orientation: landscape) {
      max-width: 364px;
    }
  }
`;
export const MessageTextTimeStamp = styled(Box)`
  float: right;
  font-size: 8px;
  padding-bottom: 8px;
`;

export const UserMessageText = styled(Box)`
  background-color: ${COLORS.base_light};
  margin: 0 0 12px 16px;
  padding: 12px;
  padding-bottom: 6px;
  max-width: 307px;
  width: max-content;
  align-self: flex-end;
  border-radius: 8px;
  line-height: 17px;
  font-size: 14px;
  color: ${COLORS.base_dark};

  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 296px;
    @media (orientation: landscape) {
      max-width: 364px;
    }
  }
`;
export const UserMessageTimeStamp = styled(Box)`
  display: flex;
  justify-content: flex-end;
  font-size: 8px;
`;

export const UserMessageBox = styled(Box)`
  margin-bottom: 10px;
  font-size: 12px;
  overflow: auto;
  font-weight: 500;
  padding: 0 15px;
`;

export const MessageDayBreak = styled(Box)`
  font-size: 12px;
  font-weight: 500;
  color: #8E8E8E;
  line-height: 17px;
  margin: 16px 0 6px 0;
`;

export const ChatInputBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

