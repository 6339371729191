import { useTranslation } from 'react-i18next';
import ModalComponent from 'shared/components/modal';
import "./club.scss";

interface ModalClubCanceledProps {
  open: boolean;
  onClose: () => void;
}

const ModalClubBalance = (props: ModalClubCanceledProps) => {
  const { t } = useTranslation('club');

  const { open, onClose } = props;
  return (
    <ModalComponent
      open={open}
      onClose={onClose}
      className="modal-club-canceled"
      size='small'
      title={t("Notification")}
      textOk={t('Close')}
      onOk={onClose}
      showFooter={false}
    >
      <p>{t('Not enough balance')}</p>
    </ModalComponent>
  );
};

export default ModalClubBalance;
