export const mockTableData = [
    {
        "id": 36748,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM000021",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS000000000045",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T17:08:03.948971",
        "updatedTime": "2024-07-08T17:08:10.889103",
        "award": 0
    },
    {
        "id": 36736,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000020",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T10:56:04.727164",
        "updatedTime": "2024-07-08T11:26:02.508786",
        "award": 0
    },
    {
        "id": 36734,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000019",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 3,
        "maxPlayers": 3,
        "minPlayers": 3,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T10:48:04.273679",
        "updatedTime": "2024-07-08T10:48:04.273679",
        "award": 0
    },
    {
        "id": 36724,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000018",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T07:16:04.981282",
        "updatedTime": "2024-07-08T10:58:33.506205",
        "award": 0
    },
    {
        "id": 36722,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000017",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T06:47:04.078522",
        "updatedTime": "2024-07-08T06:47:04.078522",
        "award": 0
    },
    {
        "id": 36713,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM000016",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS000000000031",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T04:28:05.952209",
        "updatedTime": "2024-07-08T04:28:13.482338",
        "award": 0
    },
    {
        "id": 36712,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM000015",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS00000000002F",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T04:28:05.952209",
        "updatedTime": "2024-07-08T04:28:13.419477",
        "award": 0
    },
    {
        "id": 36711,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000014",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T04:28:05.952209",
        "updatedTime": "2024-07-08T04:28:05.952209",
        "award": 0
    },
    {
        "id": 36709,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000012",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T04:28:05.952209",
        "updatedTime": "2024-07-08T04:28:05.952209",
        "award": 0
    },
    {
        "id": 36708,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000011",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T04:28:05.952209",
        "updatedTime": "2024-07-08T04:28:05.952209",
        "award": 0
    },
    {
        "id": 36707,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000010",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T04:28:04.74852",
        "updatedTime": "2024-07-08T04:28:04.74852",
        "award": 0
    },
    {
        "id": 36706,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000009",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T04:28:04.74852",
        "updatedTime": "2024-07-08T04:28:04.74852",
        "award": 0
    },
    {
        "id": 36633,
        "title": "Telesina Scheduled_1 ",
        "description": "",
        "code": "TLTM000006",
        "networkId": 1,
        "gameId": 15,
        "casinoId": 1007,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS00000000005B",
        "state": 1,
        "cost": 1400,
        "fee": 70,
        "feeRate": 0.05,
        "speed": 1,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "0",
        "objectiveValue": null,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,
        "structure": "KO",

        "playingTurnTime": 30,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": false,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-02T07:48:56.336755",
        "updatedTime": "2024-07-02T07:50:05.499883",
        "award": 0
    },
    {
        "id": 36625,
        "title": "Telesina Scheduled_1 ",
        "description": "",
        "code": "TLTM000001",
        "networkId": 1,
        "gameId": 15,
        "casinoId": 1007,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS000000000050",
        "state": 1,
        "cost": 1300,
        "fee": 65,
        "feeRate": 0.05,
        "speed": 1,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "0",
        "objectiveValue": null,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 30,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": false,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-02T03:11:48.165733",
        "updatedTime": "2024-07-02T03:13:02.429604",
        "award": 0
    },
   
]