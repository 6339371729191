import { MedalIcon } from 'assets/icons/MedalIcon';
import { CrazyPrize } from 'assets/icons/CrazyPrize';
import { AttachMoney } from '@mui/icons-material';
import { PATH_ROUTES } from 'constants/routes';
import { TournamentsMenuIconSvg } from 'assets/icons/TournamentsMenuIconSvg';
import { Treble } from 'assets/icons/Treble';

export const NAV_LINKS_SKILL_GAMES = [
  {
    title: 'Tournaments',
    link: PATH_ROUTES.TOURNAMENTS,
    icon: <TournamentsMenuIconSvg />,
    type: 'Scheduled',
  },
  {
    title: 'Sit & Go',
    link: PATH_ROUTES.SIT_AND_GO,
    icon: <MedalIcon />,
    type: 'Normal',
  },
  {
    title: 'Colpo Grosso',
    link: PATH_ROUTES.CRAZY_PRIZE,
    icon: <CrazyPrize />,
    type: 'Crazy_Prize',
  },
  {
    title: 'Filotto',
    link: PATH_ROUTES.FILOTTO,
    icon: <Treble />,
    type: 'Treble',
  },
];

export const NAV_LINK_CASH_GAMES = [
  {
    title: 'Tournaments',
    link: PATH_ROUTES.TOURNAMENTS,
    icon: <TournamentsMenuIconSvg />,
    type: 'Scheduled',
  },
  {
    title: 'Sit & Go',
    link: PATH_ROUTES.SIT_AND_GO,
    icon: <MedalIcon />,
    type: 'Normal',
  },
  {
    title: 'Cash Table',
    link: PATH_ROUTES.CASH_TABLE,
    icon: <AttachMoney />,
    type: 'CashTable',
  },
 
];

export const OTHER_GAMES = [
  {
    title: 'Sit & Go',
    link: PATH_ROUTES.SIT_AND_GO,
    icon: <MedalIcon />,
    type: 'Normal',
  },

];

export const SIT_AND_CASH = [
  {
    title: 'Sit & Go',
    link: PATH_ROUTES.SIT_AND_GO,
    icon: <MedalIcon />,
    type: 'Normal',
  },
  {
    title: 'Cash Table',
    link: PATH_ROUTES.CASH_TABLE,
    icon: <AttachMoney />,
    type: 'CashTable',
  },
 
]
export const TOURNAMENT_AND_SIT = [
  {
    title: 'Tournaments',
    link: PATH_ROUTES.TOURNAMENTS,
    icon: <TournamentsMenuIconSvg />,
    type: 'Scheduled',
  },
  {
    title: 'Sit & Go',
    link: PATH_ROUTES.SIT_AND_GO,
    icon: <MedalIcon />,
    type: 'Normal',
  }
]