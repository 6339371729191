
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import { selectActiveClub } from '@quanticogames/gameclient-core';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconLoading from 'assets/icons/Loading';
import { Input, InputWrapper } from './common';



interface Item {
    label: string;
    value: string;
}

interface ClubInfoProps {
    listPlayers: Item[];
    onReload?: () => void;
    setInvitePlayers: (value: Item[]) => void;
    invitePlayers: Item[];
    gettingListOnline: boolean;
}


const UserOnline = (props: ClubInfoProps) => {
    const { listPlayers, onReload, invitePlayers, setInvitePlayers, gettingListOnline } = props
    const { t } = useTranslation('club')
    const activeClub: any = useSelector(selectActiveClub)
    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        groupedOptions,
        setAnchorEl,
        focused,
        inputValue,
    } = useAutocomplete({
        id: 'use-autocomplete',
        options: listPlayers,
        getOptionLabel: (option) => option.label,
        multiple: true,
    });
    const maxPlayerInvite = activeClub?.clubSetting?.maxSeats - 1;
    return (
        <div className="club-info-container club-user-online-container">
            <div className='user-online-container'>
                <p className='title'>
                    {t('List players online')}
                </p>

                <div className='list-user-container'>
                    <div>
                        <div {...getRootProps()}>
                            <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
                                <Input className='club-settings-field' {...getInputProps()} placeholder={invitePlayers.length > 0 ? t('invite players', {
                                    count: invitePlayers.length,
                                }) : t('Select players')} />
                            </InputWrapper>
                        </div>
                        {groupedOptions.length > 0 ? (
                            <ul className='club-setting-ul' {...getListboxProps()}>
                                {(groupedOptions as typeof listPlayers).map((option: Item, index) => {
                                    const isSelected = invitePlayers.map(item => item.value).includes(option.value);
                                    return (
                                        <li key={option.value} onClick={() => {
                                            if (!invitePlayers.includes(option)) {
                                                if (invitePlayers.length >= maxPlayerInvite) {
                                                    return;
                                                }
                                                setInvitePlayers([...invitePlayers, option])
                                            }
                                            else {
                                                setInvitePlayers(invitePlayers.filter(item => item !== option))
                                            }
                                        }} className={`${isSelected ? 'li-selected' : ''} ${maxPlayerInvite === invitePlayers?.length ? 'no-hover' : ''} `}>
                                            {option.label}
                                        </li>
                                    );
                                })}
                            </ul>
                        ) :
                            null
                        }
                        {groupedOptions.length === 0 && inputValue.length > 0 && (
                            <ul className='club-setting-ul'>
                                <p className='text-no-result'>
                                {t('No results found')}
                                </p>
                            </ul>
                        )}
                    </div>
                    <div className='club-settings-field-icon'>
                        <ExpandMoreIcon />
                    </div>
                    {!gettingListOnline && <button className='reload-button' onClick={onReload}>
                        <img src='/images/club/reload.png' alt='reload' width={24} height={'auto'} />
                    </button>}
                    {gettingListOnline &&
                        <div style={{
                            position: 'absolute',
                            top: '6px',
                            right: '-40px'
                        }}>
                            <IconLoading size={'30px'} />
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default React.memo(UserOnline);