import { Container } from '@mui/material';
import { Outlet } from 'react-router';
import 'intro.js/introjs.css';
import './index.scss';
import { useRef } from 'react';
import {TournamentTableFake} from 'shared/components/TournamentListTable/tournament-table-fake';
import {
  selectCurrentUser,
  selectBalanceInfo,
  BalanceInfo,
  GameHelper,
  User,
  selectIsEndSession,
  configActions,
  selectConfigState,
  UserSettings
} from '@quanticogames/gameclient-core';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { tour, tourMobile, forceCloseTour } from 'utils/tour';
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/react';
import { useNavigate } from "react-router-dom";


type LayoutPageProps = {
  children?: React.ReactNode;
  isLobby?: boolean;
};

const LayoutPage = ({ children }: LayoutPageProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dontShowAgainRef = useRef(null);
  const currentUser = useSelector(selectCurrentUser) as User;
  const balanceInfo = useSelector(selectBalanceInfo) as BalanceInfo;
  
  const userSettings: UserSettings = useSelector(selectConfigState)
  const isAlreadyTour = localStorage.getItem('isAlreadyTour') === 'true';
  const isEndSession = useSelector(selectIsEndSession) as boolean;
  const isMobile = GameHelper.isMobile();
  const [openTour, setOpenTour] = React.useState(false);
  const [finishTour, setFinishTour] = React.useState(true);
  const [openDraw, setOpenDraw] = React.useState(false);
  const closeButton = document.querySelector('.drawer-header-close') as HTMLElement;
  Cookies.remove('introjs-dontShowAgain');  
  const endTour = () => {
    setFinishTour(true);
    localStorage.setItem('isAlreadyTour', 'true');
    navigate('/sit-and-go')
  }

  useEffect(() => {
    forceCloseTour();
  }, [isEndSession]);

  useEffect(() => {
    if (closeButton) {
      setOpenDraw(true);
      closeButton.addEventListener('click', () => {
        setOpenDraw(false);
        closeButton.removeEventListener('click', () => { });
      });
    }
  }, [closeButton]);

  useEffect(() => {
    if (
      userSettings?.showTutorial && balanceInfo &&
      balanceInfo.balance > 0 &&
      currentUser?.userName !== "" &&
      !openTour && !openDraw && !isAlreadyTour
    ) {
      setOpenTour(true);
      setFinishTour(false);
      if (!isMobile) {
        tour(endTour);
      } else {
        tourMobile(endTour);
      }
    }
  }, [currentUser, balanceInfo, openDraw]);

  useEffect(() => {
    if (openTour) {
      setTimeout(() => {
        const dontShowAgainElement = document.getElementById('introjs-dontShowAgain');
        if (dontShowAgainElement) {
          dontShowAgainRef.current = dontShowAgainElement as HTMLInputElement;
          dontShowAgainElement.addEventListener('change', () => {
            dispatch(configActions.updateSetting({...userSettings, showTutorial: !dontShowAgainRef.current.checked, notCallApi: false}))
          });
        }
      }, 1000);
    }
  }, [openTour]);

  useEffect(() => {
    Sentry.setUser({
      id: currentUser?.id,
      username: currentUser?.userName
    })
  }, [currentUser]);

  return (
    <>
      <Container className={`container-lobby-page`}>
        <div className='h-[100%]' id='layout-page'>
          {openTour && !finishTour && <TournamentTableFake />}
          {finishTour && children}
          <Outlet />
        </div>
      </Container>
    </>
  );
};

export default LayoutPage;
