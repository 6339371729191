import { Dialog, DialogContent, DialogTitle, Typography, styled } from '@mui/material';
import {
  CashTable,
  Game,
  Tournament,
  selectActiveGame,
  selectActiveMatchId,
  selectActiveTableId,
  selectActiveTournament,
  selectCashTable,
} from '@quanticogames/gameclient-core';
import { CloseIconSvg } from 'assets/icons/CloseIconSvg';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';

type ModalInformationProps = {
  onClose?: (...agrs: any) => void;
  openModalMatchInfo?: boolean;
  isCashTable?: boolean;
};

const StyledText = styled(Typography)(() => ({
  fontWeight: 600,
}));

const ModalInformation: React.FC<ModalInformationProps> = ({
  onClose,
  openModalMatchInfo,
  isCashTable = false,
}: ModalInformationProps) => {
  
  const { t } = useTranslation();
  const id: number = useSelector(isCashTable ? selectActiveTableId : selectActiveMatchId);
  const tournament: Tournament = useSelector(selectActiveTournament);
  const table: any = useSelector(selectCashTable);
  const [searchParams] = useSearchParams();
  const idFromUrl = searchParams.get('id');

  const currentId = useMemo(() => id || idFromUrl || null, [id, idFromUrl]);

  const data = {
    code: isCashTable ? table?.code : tournament?.code,
    cost: isCashTable ? 0 : tournament?.cost,
    fee: isCashTable ? table?.rake : tournament?.fee,
    minStake: isCashTable ? table?.minStake : 0,
    maxStake: isCashTable ? table?.maxStake : 0,
    bet: isCashTable ? 0 : tournament.cost - tournament.fee,
    type: isCashTable ? table?.tableType : tournament?.type,
  };
  const game: Game = useSelector(selectActiveGame);

  const renderTitle = () => {
    if(isCashTable) return `Game - ${game?.name}`;
    const getNameSpeed = () => {
      switch (tournament?.speed) {
        case 0:
          return t('Slow');
        case 1:
          return t('Normal');
        case 2:
          return t('Fast');
        case 3:
          return t('Turbo');
        default:
          return '';
      }
    };

    const getWinObject = () => {
      switch (tournament?.objective) {
        case 'm':
          return `${
            tournament?.objectiveValue > 1
              ? `${tournament?.objectiveValue} ${t('Matchs')}`
              : `${tournament?.objectiveValue} ${t('Match')}`
          }`;
        case 'p':
          return `${tournament?.objectiveValue} ${t('Points')}`;
        case 'b':
          return `${t('Best Of')} ${tournament?.objectiveValue}`;
        default:
          return '';
      }
    };
    return `${game?.name} ${getNameSpeed()} ${getWinObject()} - ${tournament?.code}`;
  };

  return (
    <>
      <Dialog
        open={openModalMatchInfo}
        sx={{
          borderRadius: '10px',
        }}
        className="font-raleway"
        onClose={onClose}
      >
        <DialogTitle
          sx={{
            background: '#0E345E',
            color: '#fff',
            padding: '10px 20px',
            height: '45px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontWeight: 400,
          }}
        >
          <div className="flex justify-center items-center ml-12">{`${t('Tournament')} ${data?.code}`}</div>
          <div onClick={onClose} className="flex justify-end items-end cursor-pointer ml-3">
            <CloseIconSvg />
          </div>
        </DialogTitle>
        <DialogContent
          sx={{
            fontSize: '15px',
            marginTop: '15px',
          }}
        >
          <StyledText>{renderTitle()}</StyledText>
          <StyledText>{`${t('Match type')}: ${t(data.type)}`}</StyledText>
          {!isCashTable && <StyledText>{`${t('Structure')}: ${tournament.structure}`}</StyledText>}
          {!isCashTable && (
            <StyledText>
              {`${t('Cost')}: ${
                tournament.cost === 0
                  ? t('Free')
                  : `€${formatMoney(convertCentToEuro(data.cost))}
                            ${
                              tournament?.type === 'Crazy_Prize'
                                ? ''
                                : `(€${formatMoney(convertCentToEuro(data.bet))} + €${formatMoney(
                                    convertCentToEuro(data.fee)
                                  )})`
                            }`
              }`}
            </StyledText>
          )}
          {isCashTable && (
            <>
                <StyledText>{`${t('Min Stake')}: €${formatMoney(convertCentToEuro(data.minStake))}`}</StyledText>
                <StyledText>{`${t('Max Stake')}: €${formatMoney(convertCentToEuro(data.maxStake))}`}</StyledText>
                <StyledText>{`${t('Rake')}: ${data.fee*100}%`}</StyledText>
            </>
          )}
          <StyledText>{`${t('Match Id')}: ${currentId}`}</StyledText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalInformation;
