const PinActiveIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5134_10427)">
                <path d="M1.74994 19C1.55994 19 1.36994 18.93 1.21994 18.78C0.929941 18.49 0.929941 18.01 1.21994 17.72L7.27994 11.67C7.56994 11.38 8.04994 11.38 8.33994 11.67C8.62994 11.96 8.62994 12.44 8.33994 12.73L2.27994 18.78C2.12994 18.93 1.93994 19 1.74994 19Z" fill="#FD907E" />
                <path d="M18.6299 6.68L13.3199 1.37C12.8199 0.869995 11.9599 0.869995 11.4599 1.37L10.7299 2.1C10.2099 2.62 10.0299 3.36999 10.2699 4.06999C10.3699 4.35999 10.2899 4.67 10.0799 4.88L7.92994 7.03C7.74994 7.21 7.51994 7.3 7.26994 7.3H6.38994C5.83994 7.3 5.31994 7.52 4.92994 7.9L4.56994 8.26C4.31994 8.51 4.18994 8.83999 4.18994 9.18999C4.18994 9.54 4.32994 9.87 4.56994 10.12L9.87994 15.43C10.1299 15.68 10.4599 15.81 10.8099 15.81C11.1599 15.81 11.4899 15.67 11.7399 15.43L12.0999 15.07C12.4899 14.68 12.6999 14.16 12.6999 13.61V12.73C12.6999 12.48 12.7999 12.24 12.9699 12.07L15.1199 9.92C15.3299 9.71 15.6399 9.62999 15.9299 9.72999C16.6199 9.95999 17.3799 9.78 17.8899 9.27L18.6199 8.54C19.1299 8.02999 19.1299 7.2 18.6199 6.68H18.6299Z" fill="#FC573B" />
            </g>
            <defs>
                <clipPath id="clip0_5134_10427">
                    <rect width="18.01" height="18" fill="white" transform="translate(1 1)" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default PinActiveIcon