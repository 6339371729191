import { Game, selectActiveClub, selectActiveGame } from '@quanticogames/gameclient-core';
import { roundNumberCost } from 'pages/club/components/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


interface ClubInfoProps {
    phase: 'create' | 'join' | 'invite';
    isOwner: boolean;
}

const ClubInfo = (props: ClubInfoProps) => {
    const { phase, isOwner } = props;
    const { t } = useTranslation('club')
    const game: Game = useSelector(selectActiveGame);
    const activeClub: any = useSelector(selectActiveClub)
    const cost = roundNumberCost(activeClub?.clubSetting?.cost);
    const fee = roundNumberCost(activeClub?.clubSetting?.fee)
    const ownerName = activeClub?.clubSetting?.clubMembers?.find(item => item.isOwner)?.userName;
    const title = t('Create your event and invite whoever you want');
    const content = phase === 'create' ?
        (
            <div style={{ textAlign: 'left', marginLeft: 50 }}>
                <p>{t('1. Create a table')}</p>
                <p>{t('2. Invite your friends')}</p>
                <p>{t('3. Play')}</p>
            </div>) :
        !isOwner ?
            <div className='match-info' style={{ textAlign: 'center' }}>
                <p>
                    {t('info guest', { game: game?.name, owner: ownerName })}
                </p>

                <p>
                    {activeClub?.clubSetting?.cost === 0 ? 'Free' : '€ ' + cost + ` (€ ${cost - fee} + € ${fee})`}
                </p>
            </div> :
            <p style={{ textAlign: 'center' }}> {t('You are waiting for the guests to your club game', { game: game?.name })} </p>

    return (
        <div className="club-info-container">
            <img src={'/images/club/background-right.webp'} className='image-right' width={'67%'} height={'auto'} />
            <img src={'/images/club/text.webp'} className='image-text' width={'67%'} height={'auto'} />
            <p className='club-info-title club-info-title-mobile'>
                {title}
            </p>
            <div className='info-container-desktop'>
                <p className='club-info-title club-info-title-desktop'>
                    {title}
                </p>
                <div className='info-content-container'>
                    <div className='content'>
                        {content}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(ClubInfo);