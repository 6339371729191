import { gameActions, selectCancelTournamentPopups, selectClubCanceled, selectCurrentPlayer, selectCurrentUser, tournamentActions } from '@quanticogames/gameclient-core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ModalComponent from 'shared/components/modal';
import "./index.scss";

const ModalTournamentDelete = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tournamentCancelPopups: any = useSelector(selectCancelTournamentPopups);


  const handleClose = (code: string) => {
    dispatch(tournamentActions.deleteCancelTournamentPopup({
      tournamentCode: code,
    }));
  }
  return (
    <>
      {
        tournamentCancelPopups.length > 0 && tournamentCancelPopups.map((item) => {
          return (
            <ModalComponent
              open={item.showPopup}
              onClose={() => handleClose(item.tournamentCode)}
              className="modal-canceled"
              size='small'
              title={t("Notification")}
              textOk={t('Close')}
              onOk={() => handleClose(item.tournamentCode)}
              showFooter={false}
            >
              <p>{t('tournament cancelled', { code: item.tournamentCode })}</p>
            </ModalComponent>
          )
        })
      }
    </>

  );
};

export default ModalTournamentDelete;
