import { Stack } from '@mui/material';
import {
  Game,
  Tournament,
  selectBalanceInfo,
  TournamentStateEnum,
  selectCurrentUser,
  selectActiveTournament,
  tournamentApi,
  ParticipantStatusEnum,
  tournamentActions,
  selectGames,
} from '@quanticogames/gameclient-core';
import React, {  useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { InfoList } from './tournament-info-list';

import ButtonComponent from 'shared/components/button';
import PassModal from 'pages/tournament/modal/modal-pass';
import { useDispatch } from 'react-redux';
import { getSpeedLabel, getWinObject } from 'utils/tournamentTitle';

type TournamentInfoProps = {
  onClose?: () => void;
  onAction?: (action: string, id: number, index?: number) => void;
  tournamentId: number;
};

export const TournamentInfo: React.FC<TournamentInfoProps> = ({ onAction, tournamentId, onClose }) => {

  const { t } = useTranslation();
  const currentUser: any = useSelector(selectCurrentUser);
  const tournament: Tournament = useSelector(selectActiveTournament);

  const isPlayButton = [TournamentStateEnum.inprogress, TournamentStateEnum.closed].includes(tournament?.state);
  const isSubscribed = !!(tournament?.participants || []).find((p) => p.userId === currentUser.id);
  const games: Game[] = useSelector(selectGames);
  const balance = useSelector(selectBalanceInfo);

  const [showPassModal, setShowPassModal] = useState<boolean>(false);
  const [contentDialog, setContentDialog] = useState<string>('');
  const dispatch = useDispatch();

  const notContainCurrentId = tournament?.participants?.every((e) => e.userId !== currentUser.id);

  let nonClickable =
    tournament?.state === TournamentStateEnum.scheduled ||
    tournament?.state === TournamentStateEnum.closed ||
    tournament?.state === TournamentStateEnum.terminated;
  nonClickable = nonClickable || tournament?.currentPlayerStatus == ParticipantStatusEnum.Eliminated
    || tournament?.participants?.some(item => item.userId == currentUser.id && item.status == ParticipantStatusEnum.Eliminated);
  // set back isSubscribed by participants

  const participantsLength = tournament?.participants?.length;
  const maxPlayers = tournament?.maxPlayers;

  const renderTitle = () => {
    const speed = getSpeedLabel(tournament?.speed);
    const winObj = getWinObject(tournament?.objective, tournament?.objectiveValue, t);

    const game = games?.find(g => g.id === tournament?.gameId);
    return `${game?.name} ${t(speed)} ${winObj} - ${tournament?.code || '123'}`;
  };

  const handlePlay = (id: number) => {
    const fetchData = async () => {
      try {
        const response = await tournamentApi.getPlayerStatus(id);
        if (response) {
          if (response?.status === 2) {
            setContentDialog('You have been eliminated from this tournament');
            setShowPassModal(true);
            return;
          }
          if (response?.status === 1 && response?.ongoingMatches?.length === 0) {
            setContentDialog(t('You have passed to the next round, wait for your opponent'));
            setShowPassModal(true);
            return;
          }
        }
        onAction('play', tournamentId);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  const closePassModal = () => {
    setShowPassModal(false);
    dispatch(tournamentActions.resetAdvanceToNextRound({}));
  }
  const canUnsubscribe =
  isSubscribed && tournament.state != TournamentStateEnum.closed && tournament.state != TournamentStateEnum.inprogress;
  const isMultipleMatch = tournament?.type === 'Crazy_Prize' || tournament?.type === 'Scheduled' || tournament?.type === 'Treble' || tournament?.parentGuaranteeCode // Satellite;
  const isNoneClickSpectator = tournament?.state === TournamentStateEnum.inprogress && isMultipleMatch && !isSubscribed;

  return (
    <div className="tournament-info">
      <div className="tournament-info-title">{renderTitle() || 'Scopa Lento 1 Partita - SCTM43808'}</div>
      <InfoList onClose={onClose} />
      <PassModal isOpen={showPassModal} onClose={() => closePassModal()} content={contentDialog} />

      <Stack
        direction="row"
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: '5px' }}
        className="tournament-info-btn"
      >
        {tournament?.state !== TournamentStateEnum.end && (
          <>
            {isPlayButton && !notContainCurrentId && (
              <ButtonComponent
                title={t('Play')}
                variant="outlined"
                fullWidth
                disabled={nonClickable}
                size="large"
                rouned="large"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePlay(tournamentId);
                }}
              />
            )}
            {!isSubscribed && tournament?.state !== TournamentStateEnum.inprogress && (
              <ButtonComponent
                variant="contained"
                title={t('Subscribe')}
                fullWidth
                color="success"
                size="large"
                rouned="large"
                disabled={nonClickable || balance === undefined || participantsLength == maxPlayers}
                onClick={(e) => {
                  e.stopPropagation();
                  onAction('subscribe', tournament as any);
                }}
              />
            )}

            {canUnsubscribe && (
                <ButtonComponent
                  variant="outlined"
                  color="error"
                  title={t('Unsubscribe')}
                  size="large"
                  rouned="large"
                  fullWidth
                  disabled={nonClickable || tournament.isSatelliteSubscriber}
                  onClick={(e) => {
                    e.stopPropagation();
                    onAction('unsubscribe', tournamentId);
                  }}
                />
              )}
          </>
        )}

        {notContainCurrentId && tournament?.state === TournamentStateEnum.inprogress && (
          <ButtonComponent
            variant="outlined"
            title={t('Spectator')}
            size="large"
            rouned="large"
            color="primary"
            fullWidth
            disabled={isNoneClickSpectator}
            onClick={(e) => {
              e.stopPropagation();
              onAction('play', tournamentId);
            }}
          />
        )}
      </Stack>
    </div>
  );
};
