import { selectSocketReconnect } from "@quanticogames/gameclient-core"
import IconLoading from "assets/icons/Loading"

import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import "./index.scss"

export const LostConnection = () => {
    const socketReconnect = useSelector(selectSocketReconnect)
    const { t } = useTranslation('common')
    
    return (
        <>
            {socketReconnect && <div className="lost-connection-container">
                <div className="reconnect-container">
                    <IconLoading size="30" />
                    <p>
                        {t('You have lost connection to the server, please check your network!')}
                    </p>
                </div>
            </div>}
        </>
    )
}