import './index.scss';
import {
  Sound,
  SoundManager,
  Tournament,
  tournamentActions,
  gameActions,
  selectSessionInfo,
  SessionInfo,
  selectGameState,
  selectRecommendTours,
  selectRecommendTourFullSubcribe,
} from '@quanticogames/gameclient-core';
import SliderTableItem from 'shared/components/slider-table/component/slider-table-item';
import { Container } from '@mui/material';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useEffect, useRef, useState } from 'react';
import { SideDrawer } from 'shared/components/side-drawer';
import useDrawerToggle from 'hooks/useDrawerToggle';
import { DRAWER_POSITIONING } from 'types/rightSideDrawer';
import { useDispatch, useSelector } from 'react-redux';
import ModalConfirmSubscribe from 'pages/tournament/modal/modal-confirm-subscribe';
import { useTranslation } from 'react-i18next';
import { ExpandRight } from 'assets/icons/ExpandRight';
import TournamentDetail from 'pages/tournament/component/detail/tournament-info-container';

type SliderTableProps = {
  id?: string;
};

const SliderTable = ({ id }: SliderTableProps) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const { drawerAnchor, toggleDrawer } = useDrawerToggle();
  const sessionInfo = useSelector(selectSessionInfo) as SessionInfo;
  const gameState = useSelector(selectGameState);
  const recommendTours: Tournament[] = useSelector(selectRecommendTours);
  const recommendTourFullSubcribe: Tournament = useSelector(selectRecommendTourFullSubcribe);
  const [recommendToursShow, setRecommendToursShow] = useState<Tournament[]>([]);
  const [tourId, setTourId] = useState<number>();
  const [infoTournamentSubcribe, setInfoTournamentSubcribe] = useState({});
  const [isOpenModalConfirmSubscribe, setIsOpenModalConfirmSubscribe] = useState<boolean>(false);
  const [tokenCode, setGetTokenCode] = useState<string>('');
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);
  const [previousTours, setPreviousTours] = useState<Tournament[]>([]);
  const [currentTours, setCurrentTours] = useState<Tournament[]>(recommendTours);
  const swiperRef = useRef<any>(null);

  useEffect(() => {
    if (gameState?.connected) dispatch(gameActions.joinTournamentRecommend({}));
  }, [gameState?.connected]);

  const confirmToken = (tokenCode) => {
    setGetTokenCode(tokenCode);
  };

  const handleOpenRightDrawer = () => {
    toggleDrawer(DRAWER_POSITIONING.RIGHT, true);
  };

  const handleCloseRightDrawer = () => {
    toggleDrawer(DRAWER_POSITIONING.RIGHT, false);
  };

  useEffect(() => {
    if (sessionInfo?.sessionId) {
      dispatch(tournamentActions.loadRecommendTournaments({}));
    };
  }, [sessionInfo?.sessionId]);

  const handleOpenDetail = async (id: number) => {
    setTourId(id);
    SoundManager.play(Sound.clickButton);
    handleOpenRightDrawer();
  };

  const onAction = (action: string, id: number) => {
    switch (action) {
      case 'subscribe':
        return subscribe(id);
      case 'unsubscribe':
        return unsubscribe(id);
      case 'play':
        return play(id);
    }
  };

  const play = async (id: number) => {
    SoundManager.play(Sound.clickButton);
    localStorage.setItem('lobbyUrl', window.location.pathname);
    dispatch(tournamentActions.play({tournamentId: id}));
  };

  const unsubscribe = (tournamentId: number) => {
    SoundManager.play(Sound.clickUnsubscribe);
    dispatch(tournamentActions.unsubscribeTournament({ tournamentId }));
  };

  const subscribe = (data) => {
    SoundManager.play(Sound.clickSubscribe);
    setInfoTournamentSubcribe(data);
    const tournamentId = data?.id;
    if (data?.cost > 0 || (data?.cost === 0 && data?.hasPassword === true)) {
      setTimeout(() => {
        setIsOpenModalConfirmSubscribe(true);
      }, 100);
    } else {
      dispatch(tournamentActions.subscribeTournament({ tournamentId, tokenCode: tokenCode || null }));
    }
  };

  useEffect(() => {
    if (JSON.stringify(previousTours) !== JSON.stringify(recommendTours)) {
      setPreviousTours(currentTours);
      setCurrentTours(recommendTours);

      const newItems = recommendTours.filter(newItem =>
        !previousTours.some(oldItem => oldItem.id === newItem.id)
      );

      if (newItems.length > 0 || currentTours[currentTours.length - 1] !== previousTours[previousTours.length - 1]) {
        setTimeout(() => {
          setRecommendToursShow(recommendTours);
        }, 1500);
      } else {
        setRecommendToursShow(recommendTours);
      }
    }
  }, [recommendTours]);

  useEffect(() => {
    if (recommendTourFullSubcribe?.id) {
      setShouldAnimate(true);
      handleCloseRightDrawer();
      const timer = setTimeout(() => {
        setShouldAnimate(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [recommendTourFullSubcribe]);


  const removeAllSlideEnterClasses = () => {
    document.querySelectorAll('.slide-enter').forEach(el => {
      el.classList.remove('slide-enter');
    });
  };

  useEffect(() => {
    if (shouldAnimate && recommendTourFullSubcribe?.id) {
      const currentIndex = recommendToursShow.findIndex(item => item.id === recommendTourFullSubcribe.id);
      const nextIndex = (currentIndex + 1) % recommendToursShow.length;
      const nextSlide = document.querySelectorAll('.swiper-slide')[nextIndex];
      const allSlides = document.querySelectorAll('.swiper-slide');

      removeAllSlideEnterClasses();

      if (nextSlide) {
        const addClassTimer = setTimeout(() => {
          for (let i = currentIndex + 1; i < allSlides.length; i++) {
            allSlides[i].classList.add('slide-enter');
          }
        }, 1000);

        const removeClassTimer = setTimeout(() => {
          for (let i = currentIndex + 1; i < allSlides.length; i++) {
            allSlides[i].classList.remove('slide-enter');
          }
        }, 1500);

        return () => {
          clearTimeout(addClassTimer);
          clearTimeout(removeClassTimer);
          removeAllSlideEnterClasses();
        };
      }
    }
  }, [shouldAnimate, recommendTourFullSubcribe, recommendToursShow]);

  return (
    <div className="slider-table-container" id={id}>
      {isOpenModalConfirmSubscribe && (
        <ModalConfirmSubscribe
          isOpen={isOpenModalConfirmSubscribe}
          data={infoTournamentSubcribe}
          onCloseModal={() => setIsOpenModalConfirmSubscribe(false)}
          confirmToken={confirmToken}
        />
      )}

      <Container sx={{ display: 'flex', alignItems: 'flex-end', height: '100%', }}>
        <Swiper
          ref={swiperRef}
          modules={[Navigation]}
          spaceBetween={10}
          slidesPerView={2.4}
          speed={1000}
          loop={false}
          initialSlide={0}
          slidesPerGroup={1}
          grabCursor={true}
          navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
          breakpoints={{
            600: {
              slidesPerView: 3.3,
            },
            768: {
              slidesPerView: 4.3,
            },
            1170: {
              slidesPerView: 5.3,
            },
          }}
        >
          {recommendToursShow?.length > 0 &&
            recommendToursShow.map((item, index) => (
              <SwiperSlide
                key={`tour-${item?.id}-${index}`}
                onClick={() => handleOpenDetail(item?.id)}
                className={`${item.id === recommendTourFullSubcribe?.id ? 'slide-and-fade-out' : ''}`}
              >
                <SliderTableItem game={item} />
              </SwiperSlide>
            ))}

          <div className="swiper-button-next">
            <ExpandRight />
          </div>
          <div className="swiper-button-prev">
            <ExpandRight />
          </div>
        </Swiper>

        <SideDrawer
          anchor="right"
          open={drawerAnchor.right}
          onClose={handleCloseRightDrawer}
          showHeader={true}
          titleHeader={t('Tournament information')}
          showButtonBack={false}
          className="tournament-info-drawer"
        >
          <TournamentDetail onClose={handleCloseRightDrawer} onAction={onAction} id={tourId} />
        </SideDrawer>
      </Container>
    </div>
  );
};
export default SliderTable;
