import React from 'react';
import DesktopFake from './desktop-fake'
import { GameHelper, selectOrientation } from '@quanticogames/gameclient-core';
import TournamentItemMobile from 'pages/tournament/component/list/tournament-item-mobile-fake';
import { useMediaQuery, useTheme } from '@mui/material';
import { mockTableData } from 'constants/tutorial';
import { getHighlightClass } from 'utils/tournamentTable';
import { useSelector } from 'react-redux';

type TournamentTableProps = {
  type?: string;
};

export const TournamentTableFake: React.FC<TournamentTableProps> = ({ type }) => {
  const tournaments = mockTableData;
  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = GameHelper.isMobile();
  const isLandscape = useSelector(selectOrientation) === 'landscape';
  return (
    <div className='tournament-container'>
      {isMobileScreenWidth || isMobile ? (
        <div className="">
          {
            tournaments.map((item, index) => (
              <div 
                style={{height: isLandscape?  55: 120}}
                key={`tournament-mobile-${item.id}`}
              >
                <TournamentItemMobile
                  index={index}
                  data={item}
                  className={getHighlightClass(item?.highlight)}
                  isFake={true}
                />
              </div>
             
            ))
          }
        </div>
      ) : (
          <DesktopFake />
      )}
    </div>
  );
};
