import moment from "moment";

export const parseISOLocal = s => {
    if (!s) return;
    const b = s?.split(/\D/);
    const dateSplit = new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5])
    return dateSplit;
}

const addS = n => n == 1 || n == 0 ? '' : 's';

export const formatDHMS = ms => {
    let timeObject = {}
    const d = ms / 8.64e7 | 0;
    const h = (ms % 8.64e7) / 3.6e6 | 0;
    const m = (ms % 3.6e6) / 6e4 | 0;
    return timeObject = {
        day: d,
        hour: h,
        minite: m
    }
}

export const calTimeBetweenTwoDate = (date1, date2) => {
    const difference = date1.getTime() - date2.getTime();
    return difference;
}

export const getCurrentTime = () => {
    return moment.utc(new Date()).local().format("HH:mm");
}


export const convertSecondToTime = (second: number) => {
    const minutes = Math.floor(second / 60)
    const seconds = second % 60
   return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}