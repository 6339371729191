import { TournamentStateEnum } from '@quanticogames/gameclient-core';
import { useTranslation } from 'react-i18next';

export const useTourFilter = () => {
    const { t } = useTranslation();
    return {
        cost: {
            title: t('Cost'),
            options: [
                { id: 1, label: t('Free'), costRange: { costFrom: 0, costTo: 0 } },
                { id: 2, label: `${t('Micro')} (≤€1)`, costRange: { costFrom: 1, costTo: 100 } },
                { id: 3, label: `${t('Low')} (€1.01-€5)`, costRange: { costFrom: 101, costTo: 500 } },
                { id: 4, label: `${t('Medium')} (€5.01-€20)`, costRange: { costFrom: 501, costTo: 2000 } },
                { id: 5, label: `${t('High')} (>€20.01)`, costRange: { costFrom: 2001, costTo: 5000 } },
            ],
        },
        objective: {
            title: t('Objective'),
            options: [
                { id: 1, label: t('In points'), objective: 'p' },
                { id: 2, label: t('FilterMatch'), objective: 'm' },
            ],
        },
        matchType: {
            title: t('Match type'),
            options: [
                { id: 1, label: t('Head to Head'), matchType: 0 },
                { id: 2, label: t('4 in 2'), matchType: 1 },
                { id: 3, label: t('In pairs'), matchType: 2 },
            ],
        },
        timeSpeed: {
            title: t('Time speed'),
            options: [
                { id: 1, label: t('Slow'), timeSpeed: 0 },
                { id: 2, label: t('Normal'), timeSpeed: 1 },
                { id: 3, label: t('Turbo'), timeSpeed: 2 },
                { id: 4, label: t('Superturbo'), timeSpeed: 3 },
            ],
        },
    }
}



export const useTourItPokerFilter = (gameCode: string) => {
    const { t } = useTranslation();
    let maxPlayers = {}
    switch (gameCode) {
        case 'ItalianPoker':
        case 'Bestia':
        case 'Telesina':
            maxPlayers = {
                title: t('Seat number'),
                options: [
                    { id: 1, label: `2 ${t('seats')}`, maxPlayers: 2 },
                    { id: 2, label: `3 ${t('seats')}`, maxPlayers: 3 },
                    { id: 3, label: `4 ${t('seats')}`, maxPlayers: 4 },
                    { id: 4, label: `5 ${t('seats')}`, maxPlayers: 5 },
                    { id: 5, label: `6 ${t('seats')}`, maxPlayers: 6 },
                ],
            }
            break;
        case 'SetteEMezzo':
            maxPlayers = {
                title: t('Seat number'),
                options: [
                    { id: 1, label: `2 ${t('seats')}`, maxPlayers: 2 },
                    { id: 2, label: `4 ${t('seats')}`, maxPlayers: 4 },
                    { id: 3, label: `6 ${t('seats')}`, maxPlayers: 6 },
                    { id: 4, label: `8 ${t('seats')}`, maxPlayers: 8 },
                    { id: 5, label: `10 ${t('seats')}`, maxPlayers: 10 },
                ],
            }
            break;
    }
    return {
        cost: {
            title: t('Cost'),
            options: [
                { id: 1, label: t('Free'), costRange: { costFrom: 0, costTo: 0 } },
                { id: 2, label: `${t('Micro')} (≤€1)`, costRange: { costFrom: 1, costTo: 100 } },
                { id: 3, label: `${t('Low')} (€1.01-€5)`, costRange: { costFrom: 101, costTo: 500 } },
                { id: 4, label: `${t('Medium')} (€5.01-€20)`, costRange: { costFrom: 501, costTo: 2000 } },
                { id: 5, label: `${t('High')} (>€20.01)`, costRange: { costFrom: 2001, costTo: 5000 } },
            ],
        },
        maxPlayers: maxPlayers,
        timeSpeed: {
            title: t('Time speed'),
            options: [
                { id: 1, label: t('Slow'), timeSpeed: 0 },
                { id: 2, label: t('Normal'), timeSpeed: 1 },
                { id: 3, label: t('Turbo'), timeSpeed: 2 },
                { id: 4, label: t('Superturbo'), timeSpeed: 3 },
            ],
        },
    }
}

export const useCashTableFilter = () => {
    const { t } = useTranslation();
    return {
        cost: {
            title: t('Ante'),
            options: [
                { id: 1, label: `${t('Micro')} (≤€1)`, costRange: { costFrom: 1, costTo: 100 } },
                { id: 2, label: `${t('Low')} (€1.01-€5)`, costRange: { costFrom: 101, costTo: 500 } },
                { id: 3, label: `${t('Medium')} (€5.01-€20)`, costRange: { costFrom: 501, costTo: 2000 } },
                { id: 4, label: `${t('High')} (>€20.01)`, costRange: { costFrom: 2001, costTo: 5000 } },
            ]
        },
        maxPlayers: {
            title: t('Seat number'),
            options: [
                { id: 2, label: `4 ${t('seats')}`, maxPlayers: 4 },
                { id: 3, label: `5 ${t('seats')}`, maxPlayers: 5 },
                { id: 4, label: `6 ${t('seats')}`, maxPlayers: 6 },
            ]
        }
    }
}

export const TOURNAMENT_STATUS_FILTER = {
    status: {
        title: 'Status',
        options: [
            { id: 1, status: TournamentStateEnum.scheduled, label: 'Scheduled' },
            { id: 2, status: TournamentStateEnum.open, label: 'Open' },
            { id: 3, status: TournamentStateEnum.closed, label: 'Closed' },
            { id: 4, status: TournamentStateEnum.inprogress, label: 'In Progress' },
            // { id: 5, status: TournamentStateEnum.end, label: 'End' },
            // { id: 6, status: TournamentStateEnum.canceled, label: 'Canceled' },
            { id: 7, status: TournamentStateEnum.terminated, label: 'Terminated' },]
    }

}