import { useAutocomplete } from "@mui/material";
import { ClubStatus, gameActions, IClubSettings, selectActiveClub, selectCurrentPlayer, selectCurrentUser, selectUserClubs, User } from "@quanticogames/gameclient-core";
import { InputWrapper, Input } from "pages/club/components/common";
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "../index.scss"
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";


export const MobileTab = () => {

    const { t } = useTranslation('club');
    const activeClub: any = useSelector(selectActiveClub)
    const userClubs: any = useSelector(selectUserClubs)
    const [option, setOptions] = useState<any[]>()
    const dispatch = useDispatch();

    const currentUser: User = useSelector(selectCurrentUser)

    const checkHasClub = (userClubs) => {
        for (let i = 0; i < userClubs.length; i++) {
            if(!userClubs[i].clubSetting) continue;
            if (userClubs[i].clubSetting.clubMembers?.length > 0) {
                for (let j = 0; j < userClubs[i].clubSetting.clubMembers.length; j++) {
                    if (userClubs[i].clubSetting.clubMembers[j].isOwner && userClubs[i].clubSetting.clubMembers[j].userId === currentUser?.id) {
                        return true
                    }
                }
            }
        }
        return false;
    }
    useEffect(() => {
        if (userClubs.length > 0) {
            const hasClub = checkHasClub(userClubs);
            if (!hasClub) {
                const options = userClubs.concat([{
                    tournamentCode: t('Create'),
                    id: 0,
                    clubSetting: null,
                    active: false,
                    tournamentAAMS: '',
                    status: ClubStatus.Invitation,
                }])
                setOptions(options)
            }
            else {
                setOptions(userClubs)
            }
        }
        else {
            setOptions([])
        }
    }, [userClubs])
    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        groupedOptions,
        setAnchorEl,
        focused,
        getOptionProps,
    } = useAutocomplete({
        id: 'use-autocomplete',
        options: option,
        getOptionLabel: (option) => option.tournamentCode,
        multiple: false,
    });

    useEffect(() => {
        if (!activeClub) {
            const inputProps = getInputProps();
            if (inputProps) {
                const ref = inputProps.ref as React.RefObject<HTMLInputElement>;

                if (ref && ref.current) {
                    ref.current.value = ''
                }
            }
        }

    }, [activeClub])

    return (
        <div className="club-mobile-tabs">
            <p>
                {t('Select club')}
            </p>
            <div className="select">
                <div className=''>
                    <div {...getRootProps()}>
                        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
                            <Input className='club-settings-field' {...getInputProps()} value={activeClub?.tournamentCode} disabled={userClubs?.length === 0} placeholder={userClubs.length === 0 ? t('No club') : t('Create')} />
                        </InputWrapper>
                    </div>
                    {groupedOptions.length > 0 ? (
                        <ul id="user-club-dropdown-ul" className='club-setting-ul' {...getListboxProps()} >
                            {(groupedOptions as typeof userClubs).map((option, index) => {
                                const isSelected = activeClub?.id === option.id;
                                const { ...optionProps } = getOptionProps({ option, index });

                                return (
                                    <li key={option.id} onClick={(e) => {
                                        dispatch(gameActions.updateActiveClub({
                                            id: option.id,
                                        }))
                                        optionProps.onClick(e)

                                    }} className={isSelected ? 'li-selected' : ''}>
                                        {option.tournamentCode}
                                    </li>
                                );
                            })}
                        </ul>
                    ) : null}
                    <div className='select-field-icon'>
                        <ExpandMoreIcon />
                    </div>
                </div>
            </div>
        </div>
    )
}