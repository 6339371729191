import { useTranslation } from "react-i18next";
import './index.scss';

const UnexpectedPage = () => {
    const { t } = useTranslation();

    return <div className="unexpected-page">
        <div className="unexpected-images">
            <img src="/images/common/system-maintenance.webp"/>
        </div>
        <div className="unexpected-content">
            <p>{t("System maintenance in progress. We'll be back shortly.")}</p>
        </div>
    </div>
}
export default UnexpectedPage