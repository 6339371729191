import { TournamentInfo } from "pages/tournament/component/detail/tournament-info";
import { TournamentInfoTabs } from "pages/tournament/component/detail/tournament-info-tabs";

import { FlexBox } from "shared/Flex";
import { useEffect, useState } from "react";
import { Tournament, gameActions, selectActiveTournament, selectOrientation, tournamentActions } from "@quanticogames/gameclient-core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import IconLoading from "assets/icons/Loading";
import React from 'react';

interface TournamentDetailProps {
  onClose?: () => void;
  onAction?: (action: string, id: number, index?: number) => void;
  id?: number;
}

const Index: React.FC<TournamentDetailProps> = ({ onAction, onClose, id }) => {
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';
  const dispatch = useDispatch();
  const tournament: Tournament = useSelector(selectActiveTournament);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // websocket event fired a lot, must optimize later
    if (tournament?.id) {
      dispatch(gameActions.joinRoomTournament({
        gameId: tournament?.gameId,
        tournamentId: tournament?.id,
      }));
    }

    return () => {
      tournament && dispatch(gameActions.leaveRoomTournament({
        gameId: tournament?.gameId,
        tournamentId: tournament?.id,
      }));
    }
  }, [tournament?.id]);

  useEffect(() => {
    if (!tournament || tournament?.id !== id) {
      setLoading(true);
      dispatch(tournamentActions.tournamentsDetail(id));
    } else {
      setLoading(false);
    };
  }, [tournament, id]);

  return (
    <FlexBox
      className="tournament-info-container w-full h-full relative"
      flexDirection={isMobileLandscape ? "row" : "column"}
      sx={{ justifyContent: "flex-start" }}
      gap={isMobileLandscape ? 0 : 15}
    >
      {loading ? (
        <FlexBox
          className="w-full h-full absolute"
          sx={{ top: 0, left: 0, zIndex: 9999, background: "#00000036", alignItems: "center" }}
        >
          <IconLoading color={'#fff'} />
        </FlexBox>
      ) : (
        <>
          <TournamentInfo onClose={onClose} onAction={onAction} tournamentId={id} />
          <TournamentInfoTabs tournamentId={id} onClose={onClose} />
        </>
      )}
    </FlexBox>
  );
}

const TournamentDetail = React.memo(Index);
export default TournamentDetail;
