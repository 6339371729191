import SortTable from "assets/icons/SortTable"

const SortItem = ({name,  column, handleSort, sortColumn, sortDirection, t, isMinContent = true }) => {

    return (
        <div onClick={() => {
            handleSort(column)
        }} className='flex items-center text-left'>
            <span style={{
                width: isMinContent ? 'min-content' : 'auto',
            }
            }>
                {t(name)}
            </span>
            <div className={`${sortColumn === column ? `table-sort-${sortDirection}` : ''} sort-icon`}>
                <SortTable />
            </div>
        </div>
    )
}

export default SortItem