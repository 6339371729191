import {
  Box,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCashTableFilter } from 'shared/Filters/FilterConstants';
import { FilterItem } from 'shared/Filters/FilterItem';

interface CashTableProps {
  filterList: any;
  filterData: any
}

const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    maxWidth: '1440px',
  },
  [theme.breakpoints.down('md')]: {
    '@media (orientation: landscape)': {
      h4: {
        fontSize: '1.5rem',
        lineHeight: '33px',
      },
    },
  },
}));

export const CashTableFilter = (props: CashTableProps) => {
  const { filterData, filterList = {} } = props;
  const { t } = useTranslation();
  const cashTableFilter = useCashTableFilter();


  const calculateFilterValue = (name: string) => {
    const labels: string[] = filterList[`${name}Labels`] || [];
    const options = cashTableFilter[name].options.filter(o => labels.includes(o.label));
    return options.map(o => o.id); // return id list
  };

  return (
    <Root>
      <Box
        mt={'6px'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          overflowY: 'auto',
          padding: '0',
          '.MuiButtonBase-root.MuiAccordionSummary-root': {
            minHeight: '52px',
          }
        }}
      >
        {Object.entries(cashTableFilter).map(([name, filter]) => {
          return (
            <FilterItem
              key={`filter-item-${name}`}
              id={name}
              title={t(filter.title)}
              options={filter.options}
              values={calculateFilterValue(name)}
              onChange={(values) => filterData(name, values)}
            />
          );
        })}
      </Box>
    </Root>
  );
}