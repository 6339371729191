import { chipGames } from "constants/common";

export const DEFAULT_MATCH_FINISHED_START_PAGE = 1;
export const DEFAULT_MATCH_FINISHED_ITEM_PER_PAGE = 10;
export const ORDER_BY_RECENT_MATCH_TIME = 'updatedTime_desc';
export const INACTIVE_DETECT_TIME = 600;

export enum HISTORY_TYPE {
    TOURNAMENT = 1,
    CASH_TABLE = 2,
};

export const isChipGame = (gameCode: string) => {
    return chipGames.includes(gameCode);
}