import { selectActiveClub } from "@quanticogames/gameclient-core";
import moment from "moment";
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux";
import { convertSecondToTime } from "utils/caculateTime";



interface TimeoutProps {
    isOwner: boolean;
    phase: 'create' | 'invite' | 'join' 
}

const TimeOut = (props: TimeoutProps) => {
    const { isOwner, phase } = props
    const { t } = useTranslation('club')
    const [displayTime, setDisplayTime] = useState<string>("");
    const activeClub: any = useSelector(selectActiveClub);
    const [diffTime, setDiffTime] = useState(10000);

    useEffect(() => {
        if (diffTime > 150) return;
        let countDown = 150 - diffTime
        const interval = setInterval(() => {
            countDown -= 1
            const endTimeDisplay = convertSecondToTime(countDown)
            setDisplayTime(endTimeDisplay)
            if (countDown <= 0) {
                clearInterval(interval)
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [diffTime])

    useEffect(() => {
        if (activeClub?.clubSetting) {
            const diff = moment.utc().diff(moment.utc(activeClub?.clubSetting?.createdTime), 'seconds')
            setDiffTime(diff)
        }
    }, [activeClub])
    
    return (
        <>
            {true && <div className="timeout-container">
                <img src="/images/club/hour-glass.png" alt="hour-glass" className="hour-glass-image" />
                <div className="timeout-text">
                    <p>
                        {isOwner ? phase === 'invite' ? t('owner time invite') : t('owner time join') : t('You are invited to enter')}
                    </p>
                    <p className="time-text">
                        {displayTime}
                    </p>
                    <p>
                        {t('minutes')}
                    </p>
                </div>
            </div>}
        </>

    )
}

export default React.memo(TimeOut)