import { Avatar, Badge, Popover, useMediaQuery, useTheme } from '@mui/material';
import { RankingUser, User, selectCurrentUser, selectOrientation } from '@quanticogames/gameclient-core';
import { EditIcon } from 'assets/icons/EditIcon';

import { EditAvatar } from 'layouts/profiles/components/profile-edit-avatar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CloseButton } from 'shared';
import { FlexBox } from 'shared/Flex';

type ProfileAvatarProps = {
  onClick?: any;
  showIconEdit?: boolean;
  rankingUser: RankingUser;
};

const ProfileAvatar = ({ onClick, showIconEdit, rankingUser }: ProfileAvatarProps) => {
  const { t } = useTranslation();
  const currentUser: User = useSelector(selectCurrentUser);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'edit-avatar-popover' : undefined;
  const [avatar, setAvatar] = useState<string>(currentUser?.avatar);
  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAvatar = (avatar: string) => {
    setAvatar(avatar);
  };

  return (
    <>
      <div className="profile-avatar" onClick={showIconEdit ? handleClick : () => {}}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={showIconEdit ? <EditIcon /> : ''}
          onClick={onClick}
          sx={{ cursor: 'pointer', svg: { width: 'auto', height: 'auto' } }}
        >
          <Avatar
            alt={currentUser?.userName}
            src={`${`/images/avatar/${avatar ?? currentUser.avatar}`}`}
            className={`profile-avartar-image ranking-vip-${rankingUser?.vipLevel}`}
          />
        </Badge>
        <FlexBox className="profile-avatar-info" flexDirection="column">
          <p className="profile-avatar-name">{currentUser?.userName}</p>
          <p className="profile-account-code">
            {rankingUser?.accountCode}
          </p>
        </FlexBox>
      </div>

      <Popover
        open={open}
        id={id}
        onClose={handleClose}
        anchorEl={anchorEl}
        className={`popover-edit-avatar ${isMobileLandscape ? 'mobile-landscape': ''}`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {(isMobileScreenWidth || isMobileLandscape) && (
          <div className="popover-header">
            <span className="flex-1 text-center">{t('Pick your avatar')}</span>
            <FlexBox className="popover-header-action" alignItems={'center'}>
              <CloseButton onClick={handleClose} />
            </FlexBox>
          </div>
        )}
        <EditAvatar onSelectAvatar={handleSelectAvatar} onClose={handleClose} />
      </Popover>
    </>
  );
};
export default ProfileAvatar;
