
import { getWinObject } from "utils/tournamentTitle";

export const AppCrack: React.FC = () => {
    const renderTitle = () => {
        const objective = 'm';
        const objectiveValue = 1;
        const value = getWinObject(objective, objectiveValue, null);
        return value;
    }

    return (
        <div>
            {renderTitle()}
        </div>
    )
}