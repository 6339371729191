import { Button, Input, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { clubApi, selectActiveGame, IClubSettings, selectLanguage, gameApi, selectBalanceInfo, Game } from '@quanticogames/gameclient-core';
import { useSelector } from 'react-redux';
import { ConditionComponent } from 'shared/condition';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { chipGameIds, mapMatchTypeToMaxSeats, parseConfig, roundNumberCost } from './helpers'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


interface LabelProps {
    content: string;
    icon: string;
}

const Label = (props: LabelProps) => {
    return (
        <div className='club-settings-label'>
            <img src={props.icon} alt='icon' />
            <p>{props.content}</p>
        </div>
    )
}

interface IOption {
    label: any;
    value: any;
}
interface gameConfig {
    matchTypeOption?: IOption[];
    costOption?: IOption[];
    speedOption?: IOption[];
    maxPlayers?: IOption[];
    objectiveOption?: IOption[];
    structureOption?: IOption[];
    feeOption?: IOption[];
}

interface IGameSettings {
    id: number
    gameId: number
    gameCode: string
    speed: number
    objective: string
    matchType: number
    totalTime: number
    turnTime: number
    isFree: boolean
    initChip: number
    invitationType: number
    isDefault: boolean
    timeOutExtra: string;
}

const MenuProps = {
    PaperProps: {
        sx: {
            bgcolor: 'rgba(1, 30, 98)',
            border: '2px solid #4438CF',
            borderRadius: '5px',
            marginTop: '5px',
            '& li': {
                color: '#fff',
                padding: '10px 20px',
                '&:hover': {
                    backgroundImage: 'linear-gradient(to right, #225db350, #062656)',

                }
            }
        },
    }
}

interface ClubSettingsProps {
    onCreateFinish: (data: IClubSettings) => void;
}
const ClubSettings = (props: ClubSettingsProps) => {
    const { onCreateFinish } = props;
    const { t } = useTranslation('club');
    const activeGame: Game = useSelector(selectActiveGame);
    const [gameConfig, setGameConfig] = useState<gameConfig>()
    const language = useSelector(selectLanguage)
    const [submitForm, setSubmitForm] = useState<IClubSettings>()
    const [gameSetting, setGameSetting] = useState<IGameSettings[]>()
    const [isChipGame, setIsChipGame] = useState<boolean>(false)
    const [chipInitial, setChipInitial] = useState<number>(1500)
    const [gameTime, setGameTime] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(true)
    const [creating, setCreating] = useState<boolean>(false)
    const balanceInfo: any = useSelector(selectBalanceInfo);

    useEffect(() => {
        const fetchConfig = async () => {
            const gameSettings = await gameApi.getGameSettings(activeGame?.id)
            setGameSetting(gameSettings)
            const res = await clubApi.getGameConfig(activeGame?.id)
            if (!res[0]?.settings) {
                return
            }
            const config = parseConfig(res[0]?.settings, activeGame?.id, t)
            setGameConfig(config)
            const cost = config.costOption?.[0].value
            const feeRate = config.feeOption?.[0].value.fee
            const fee = cost * feeRate

            const maxSeat = (chipGameIds.includes(activeGame?.id) || activeGame?.id === 14) ? config?.maxPlayers?.[0]?.value : mapMatchTypeToMaxSeats(Number(config.matchTypeOption?.[0]?.value), activeGame?.id)

            setSubmitForm({
                gameId: activeGame?.id,
                type: config.structureOption?.[0].value ?? null,
                cost: cost ?? null,
                feeRate: config.feeOption[0].value.feeRate ?? null,
                fee: fee,
                speed: config.speedOption?.[0].value ?? null,
                objective: config.objectiveOption?.[0].value ?? null,
                matchType: config.matchTypeOption?.[0]?.value ?? null,
                initChip: null,
                maxSeats: maxSeat ?? null,
            })

            setIsChipGame(chipGameIds.includes(activeGame?.id))

            setLoading(false)
        }
        if (activeGame?.id) {
            setLoading(true)
            fetchConfig()
        }
    }, [activeGame, language])

    useEffect(() => {
        if (isChipGame) {
            const findSetting = gameSetting?.find((setting) => setting.speed === submitForm?.speed)
            if (findSetting) {
                setChipInitial(findSetting.initChip)
            }
        }
        if (!isChipGame) {
            if (activeGame?.id === 14) {
                const extraSetting = gameSetting?.find((setting) => setting.objective === submitForm?.objective)
                if (extraSetting) {

                    const timeOutExtra = JSON.parse(extraSetting?.timeOutExtra)
                    if (timeOutExtra) {
                        const time = timeOutExtra?.find((time) => time?.MaxPlayer === submitForm?.maxSeats)
                        if (time) {
                            setGameTime(Math.round(time?.TotalTime / 60 * 100) / 100 + t('minutes'))
                        }
                        else {
                            setGameTime(Math.round(extraSetting?.totalTime / 60 * 100) / 100 + t('minutes'))
                        }
                    }
                }
                return
            }
            const findSetting = gameSetting?.filter((setting) => String(setting.matchType) === String(submitForm?.matchType)).find((setting) => setting.objective === submitForm?.objective)
            if (findSetting) {
                setGameTime(Math.round(findSetting?.totalTime / 60 * 100) / 100 + t('minutes'))
            }
            else {
                setGameTime("")
            }

        }
    }, [submitForm, isChipGame, activeGame])


    const handleChangeField = (value: any, field: string) => {
        setSubmitForm({
            ...submitForm,
            [field]: value
        })
    }

    const handleSubmit = async () => {
        setCreating(true)
        try {
            if (creating) {
                return
            }
            await onCreateFinish(submitForm)

            setTimeout(() => {
                setCreating(false)
            }, 5000)
        }
        catch (error) {
            console.log(error)
            setCreating(false)
        }

    }

    return (
        <div className='settings-container'>
            <p className='title'>
                {t('1. Create club tournament')}
            </p>
            {!loading && <div className='setting-fields'>
                <ConditionComponent condition={gameConfig?.structureOption?.length > 0}>
                    <div>
                        <Label content={t('Match event')} icon='/images/club/cards.png' />
                        <Select
                            MenuProps={MenuProps}
                            disabled={gameConfig?.structureOption?.length < 2}
                            className='club-settings-field'
                            IconComponent={() => (
                                <ExpandMoreIcon style={{
                                    color: '#fff',
                                    fontSize: '25px',
                                    display: gameConfig?.structureOption?.length > 1 ? 'block' : 'none'
                                }} />
                            )}
                            value={submitForm?.type}
                            onChange={(e) => {
                                handleChangeField(e.target.value, 'type')
                            }}
                        >
                            {gameConfig?.structureOption?.map((option: any) => {
                                return (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                </ConditionComponent>
                <ConditionComponent condition={isChipGame}>
                    <div>
                        <Label content={t('Chip initial')} icon='/images/club/objective.png' />
                        <Input
                            inputProps={MenuProps}
                            className='club-settings-field'
                            value={chipInitial}
                            disabled
                            style={{
                                paddingLeft: 25,
                            }}
                        />
                    </div>
                </ConditionComponent>
                <ConditionComponent condition={gameConfig?.objectiveOption?.length > 0 && !isChipGame}>
                    <div>
                        <Label content={t('Objective')} icon='/images/club/objective.png' />
                        <Select
                            MenuProps={MenuProps}
                            className='club-settings-field'
                            defaultValue={gameConfig?.objectiveOption[0].value}
                            disabled={gameConfig?.objectiveOption?.length < 2}
                            IconComponent={() => (
                                <ExpandMoreIcon style={{
                                    color: '#fff',
                                    fontSize: '25px',
                                    display: gameConfig?.objectiveOption?.length > 1 ? 'block' : 'none'
                                }} />
                            )}
                            onChange={(e) => {
                                handleChangeField(e.target.value, 'objective')
                            }}
                        >
                            {gameConfig?.objectiveOption?.map((option: any) => {
                                return (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                </ConditionComponent>
                <ConditionComponent condition={gameConfig?.costOption?.length > 0}>
                    <div>
                        <Label content={t('Cost')} icon='/images/club/chip.png' />
                        <Select
                            MenuProps={MenuProps}
                            className='club-settings-field'
                            defaultValue={gameConfig?.costOption[0].value}
                            disabled={gameConfig?.costOption?.length < 2}
                            value={submitForm?.cost}
                            IconComponent={() => (
                                <ExpandMoreIcon style={{
                                    color: '#fff',
                                    fontSize: '25px',
                                    display: gameConfig?.costOption?.length > 1 ? 'block' : 'none'
                                }} />
                            )}
                            onChange={(e) => {
                                const cost = e.target.value
                                const feeRate = gameConfig?.feeOption?.find((option) => option.value.cost === cost)?.value.feeRate
                                const fee = cost * feeRate
                                setSubmitForm({
                                    ...submitForm,
                                    cost: cost,
                                    feeRate: feeRate,
                                    fee: fee
                                })
                            }}
                        >
                            {gameConfig?.costOption?.map((option: any) => {
                                const notEnoughBalance = balanceInfo?.balance < option.value
                                return (
                                    <MenuItem key={option.value} value={option.value} disabled={notEnoughBalance}>
                                        {t(option.label)}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                </ConditionComponent>
                <ConditionComponent condition={gameConfig?.speedOption?.length > 0 && isChipGame}>
                    <div>
                        <Label content={t('Speed Mode')} icon='/images/club/clock.png' />
                        <Select
                            MenuProps={MenuProps}
                            className='club-settings-field'
                            defaultValue={gameConfig?.speedOption[0].value}
                            disabled={gameConfig?.speedOption?.length < 2}
                            IconComponent={() => (
                                <ExpandMoreIcon style={{
                                    color: '#fff',
                                    fontSize: '25px',
                                    display: gameConfig?.speedOption?.length > 1 ? 'block' : 'none'
                                }} />
                            )}
                            onChange={(e) => {
                                handleChangeField(e.target.value, 'speed')
                            }}
                        >
                            {gameConfig?.speedOption?.map((option: any) => {
                                return (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                </ConditionComponent>
                <ConditionComponent condition={!isChipGame && activeGame?.id !== 16}>
                    <div>
                        <Label content={t('Speed Mode')} icon='/images/club/clock.png' />
                        <Input
                            inputProps={MenuProps}
                            className='club-settings-field'
                            value={gameTime}
                            disabled
                            style={{
                                paddingLeft: 25,
                            }}
                        />
                    </div>
                </ConditionComponent>
                <ConditionComponent condition={gameConfig?.maxPlayers?.length > 0}>
                    <div>
                        <Label content={t('Seat Count')} icon='/images/club/group.png' />
                        <Select className='club-settings-field'
                            MenuProps={MenuProps}
                            defaultValue={gameConfig?.maxPlayers?.[0]?.value}
                            disabled={gameConfig?.maxPlayers?.length < 2}
                            IconComponent={() => (
                                <ExpandMoreIcon style={{
                                    color: '#fff',
                                    fontSize: '25px',
                                    display: gameConfig?.maxPlayers?.length > 1 ? 'block' : 'none'
                                }} />
                            )}
                            onChange={(e) => {
                                handleChangeField(e.target.value, 'maxSeats')
                            }}
                        >
                            {gameConfig?.maxPlayers?.map((option: any) => {
                                return (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                </ConditionComponent>
                <ConditionComponent condition={gameConfig?.matchTypeOption?.length > 0}>
                    <div>
                        <Label content={t('Match type')} icon='/images/club/group.png' />
                        <Select
                            MenuProps={MenuProps}
                            disabled={gameConfig?.matchTypeOption?.length < 2}
                            className='club-settings-field'
                            IconComponent={() => (
                                <ExpandMoreIcon style={{
                                    color: '#fff',
                                    fontSize: '25px',
                                    display: gameConfig?.matchTypeOption?.length > 1 ? 'block' : 'none'
                                }} />
                            )}
                            value={submitForm?.matchType}
                            onChange={(e) => {
                                if (!gameConfig?.maxPlayers) {
                                    const maxSeats = mapMatchTypeToMaxSeats(Number(e.target.value), activeGame?.id)
                                    setSubmitForm({
                                        ...submitForm,
                                        maxSeats: maxSeats,
                                        matchType: e.target.value
                                    })
                                    return
                                }
                                handleChangeField(e.target.value, 'matchType')
                            }}
                        >
                            {gameConfig?.matchTypeOption?.map((option: any) => {
                                return (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                </ConditionComponent>
                <div className='button-create'>
                    <Button onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleSubmit()
                    }}>{t('create club')}
                    </Button>
                    <NavigateNextIcon style={{
                        marginTop: 2,
                        color: '#fff'
                    }} />
                </div>
            </div>}
        </div>
    );
}

export default React.memo(ClubSettings);