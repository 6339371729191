import { Box, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FlexBox } from "shared/Flex";

type ProfileCollapseDetailProps = {
    data: any[],
    onClickContent?: () => void,
}

const ProfileDetailContent = styled(Box)(() => ({
    borderStyle: 'solid',
    background: '#fff',
    borderRadius: 5,
    borderWidth: '1px 1px 0 1px',
    borderColor: '#D2D2D3'
}))

const ProfileItem = styled(FlexBox)(() => ({
    borderBottom: '1px solid #d2d2d3',
    alignItems: 'center',
    minHeight: '40px',
    padding: '5px 10px',
    justifyContent: 'flex-start',
    'img': {
        width: '34px'
    }
}))

const ProfileLable = styled('span')(() => ({
    width: 100
}))

const ProfileContent = styled('span')(() => ({
    flex: 1,
    color: '#8E8E8E',
    maxWidth: 'calc(100% - 100px)',
    wordBreak: 'break-word'
}))

interface IItem {
    label: string;
    content: any;
    hide?: boolean;
    className?: string;
}

const ProfileCollapseDetail = ({ data, onClickContent }: ProfileCollapseDetailProps) => {
    const { t } = useTranslation();
    return (
        <ProfileDetailContent className="history-detail">
            {(data || []).map((item: IItem, index) => {
                if(item?.hide) return null;
                return (
                    <ProfileItem className="history-detail-item" key={index}>
                        <ProfileLable className="history-detail-lable">{t(`${item.label}`)}</ProfileLable>
                        <ProfileContent className={`history-detail-text ${item.className}`} onClick={onClickContent}>{item.content}</ProfileContent>
                    </ProfileItem>
                )
            })}
        </ProfileDetailContent>
    )
}
export default ProfileCollapseDetail