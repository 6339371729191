import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CashTable, CashTableActiveState, isPopup, selectCashTable, selectCashTables } from '@quanticogames/gameclient-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ModalComponent from 'shared/components/modal';

const ModalTerminate: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const table: CashTable = useSelector(selectCashTable);
  const tables: CashTable[] = useSelector(selectCashTables)
  const navigate = useNavigate();
  const currentTableId = new URLSearchParams(window.location.href.split('?')[1])
  useEffect(() => {
    if (table?.state === CashTableActiveState.Terminated) {
      setOpen(true);
    }
  }, [table]);


  const onCloseModal = () => {
    setOpen(false);
    if (isPopup) {
      window.close();
    }
    else {
      navigate('/cash-tables');
    }
  };

  useEffect(() => {
    tables.forEach((table) => {
      if (String(table.id) === currentTableId.get('id') && table.state === CashTableActiveState.Terminated) {
        setOpen(true);
        return;
      }
    })
  }, [tables]);

  return (
    <>
      <ModalComponent
        open={open}
        onClose={onCloseModal}
        className="modal-club-canceled"
        size='small'
        title={t("Notification")}
        textOk={t('Close')}
        onOk={onCloseModal}
        showFooter={true}
      >
        <p>{t('Table was terminated')}</p>
      </ModalComponent>
    </>
  );
};

export default ModalTerminate;
