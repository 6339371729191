import * as React from 'react';
import ProfileCollapse from 'layouts/profiles/components/profile-collapse';
import { getChatWarning } from 'layouts/profiles/components/chat/chat.service';
import { useEffect, useState } from 'react';
import { DEFAULT_MATCH_FINISHED_ITEM_PER_PAGE, DEFAULT_MATCH_FINISHED_START_PAGE } from 'utils/constants';
import moment from 'moment';

export const Chat: React.FC = () => {
  
  
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(DEFAULT_MATCH_FINISHED_START_PAGE);
  const [totalChat, settotalChat] = useState<number>(0);
  const [chatList, setChatList] = useState<any>([]);
  const pageSize = DEFAULT_MATCH_FINISHED_ITEM_PER_PAGE;

  const formatDate = (inputTime) => {
    const date = moment(inputTime);
    return date.format('DD/MM/YYYY HH:mm');
  };

  const fetchData = async (isFetchNew = false) => {
    setLoading(true);
    try {
      const response = await getChatWarning({
        page: currentPage,
        pageSize,
      });

      const data = (response?.data || []).map((item: any) => {
        const finishedMatchItem = {
          date: formatDate(item.createdDate),
          message: item.message,
          actionTaken: item.actionTaken,
          detail: [
            {
              label: 'Date',
              content: formatDate(item.createdDate)
            },
            {
              label: 'Messages',
              content: item.message,
            },
            {
              label: 'Action',
              content: item.actionTaken,
            },
          ]
        };
        return finishedMatchItem;
      });

      settotalChat(response?.total);

      if (isFetchNew) {
        setChatList(data);
        setLoading(false);
        return;
      }

      const finishedMatchConcatOnShowmore = [...chatList, ...data];
      setChatList(finishedMatchConcatOnShowmore);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const columns = [
    {
      id: 'date',
      label: 'Date',
      align: 'left',
      render: (date) => (<span className='w-[85px] block'>{date}</span>),
    },
    {
      id: 'message',
      label: 'Messages',
      align: 'left',
      render: (message) => (<span className='max-w-[114px] break-word chat-message'>{message}</span>),
    },
    {
      id: 'actionTaken',
      label: 'Action',
      align: 'left',
      classNames: '',
    },
  ];

  return <ProfileCollapse 
    columns={columns} 
    data={chatList} 
    totalItem={totalChat}
    isLoading={loading}
    onLoadMore={() => setCurrentPage(currentPage + 1)}
  />;
};
