const PinIcon = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5134_10378)">
                <path d="M6.87797 12.1453L1.69586 17.318C1.55376 17.4601 1.37376 17.5264 1.19376 17.5264C1.00428 17.5264 0.824284 17.4601 0.682178 17.318C0.407442 17.0432 0.407442 16.5885 0.691652 16.3137L5.87376 11.1411" stroke="#D2D2D3" strokeMiterlimit="10" />
                <path d="M17.1758 5.85478L12.1453 0.824257C11.6716 0.350573 10.8569 0.350573 10.3832 0.824257L9.69164 1.51584C9.19901 2.00847 9.02848 2.71899 9.25585 3.38215C9.35059 3.65689 9.2748 3.95057 9.07585 4.14952L7.03901 6.18636C6.86848 6.35689 6.65059 6.44215 6.41374 6.44215H5.58006C5.05901 6.44215 4.56638 6.65057 4.1969 7.01057L3.85585 7.35163C3.61901 7.58847 3.49585 7.9011 3.49585 8.23268C3.49585 8.56426 3.62848 8.87689 3.85585 9.11373L8.88638 14.1443C9.12322 14.3811 9.43585 14.5043 9.76743 14.5043C10.099 14.5043 10.4116 14.3716 10.6485 14.1443L10.9895 13.8032C11.359 13.4337 11.558 12.9411 11.558 12.42V11.5864C11.558 11.3495 11.6527 11.1222 11.8137 10.9611L13.8506 8.92426C14.0495 8.72531 14.3432 8.64952 14.618 8.74426C15.2716 8.96215 15.9916 8.79163 16.4748 8.30847L17.1664 7.61689C17.6495 7.13373 17.6495 6.34742 17.1664 5.85478H17.1758Z" stroke="#D2D2D3" strokeMiterlimit="10" />
            </g>
            <defs>
                <clipPath id="clip0_5134_10378">
                    <rect width="18.0095" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default PinIcon