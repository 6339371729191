import React, { useEffect, useState } from 'react';
import { Box, MenuItem, Select, styled, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';

import { COLORS } from '../../../../constants/colors';
import { useTranslation } from 'react-i18next';
import {
  GameHelper,
  selectActiveTournament,
  Sound,
  SoundManager,
  Tournament,
  tournamentApi,
  TournamentStateEnum,
} from '@quanticogames/gameclient-core';
import { useSelector } from 'react-redux';
import { CrazyPrizeRule, EmptyData, TextWrapper } from 'pages/tournament/component/detail/paticipant-empty';
import { TableList } from 'pages/tournament/component/detail/table-list';
import { SatelliteTournament } from 'pages/tournament/component/detail/satellite-tournament'
import { ParticipantsTable } from 'pages/tournament/component/detail/participant-table';
import { PrizePoolTable } from 'pages/tournament/component/detail/prizePool-table';
import { Ranking } from 'pages/tournament/component/detail/ranking-table';
import { ParticipantIcon } from 'assets/icons/ParticipantIcon';
import { FlexBox } from 'shared/Flex';
import { chipGames } from 'constants/common';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`session-tabpanel-${index}`}
      aria-labelledby={`session-tab-${index}`}
      className="session-tab"
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export const TabStyled = styled(Tab)`
  font-weight: 400;
  font-size: 16px;
  font-family: Inter, sans-serif;
  line-height: 15px;
  letter-spacing: 0.01em;
  background-color: #E5E5E5;
  color: #ABABAB;
  padding: 0 15px;
  height: 30px;
  margin-right: 5px;
  border-radius: 30px;
  display: flex;
  min-height: 30px;
  &.Mui-selected {
    background-color: #06F;
    color: ${COLORS.white};
  }
`;

type TabsWithContentProps = {
  route?: string;
  tournamentId?: number;
  onClose: () => void;
};

export const TournamentInfoTabs: React.FC<TabsWithContentProps> = ({ route, tournamentId, onClose }) => {
  const { t } = useTranslation();
  const isMobile = GameHelper.isMobile();
  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('md'));
  const tournament: Tournament = useSelector(selectActiveTournament);
  const [activeTab, setActiveTab] = React.useState(0);
  const [prizeDistribution, setPrizeDistribution] = useState<any>([]);
  const [prizeInProgress, setPrizeInProgress] = useState<any>([]);
  const listParticipant = tournament?.participants;
  
  const isChipGames = chipGames.some(game => tournament?.title?.replace(/\s+/g, '').includes(game));
  const isMatchInprocess =
    tournament?.state === TournamentStateEnum.inprogress ||
    tournament?.state === TournamentStateEnum.end ||
    tournament?.state === TournamentStateEnum.terminated;

  function a11yProps(index: number) {
    return {
      id: `tournament-info-tab-${index}`,
      'aria-controls': `tournament-info-tabpanel-${index}`,
      className: "tournament-info-tabpanel"
    };
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const prizeDistributionResponse = await tournamentApi.getPrizePoolDistribution({ tournamentId });
        setPrizeDistribution(prizeDistributionResponse);

        const prizeInProgressResponse = await tournamentApi.getPrizePoolDistributionDetail({ tournamentId });
        setPrizeInProgress(prizeInProgressResponse);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [tournamentId]);

  const defaultTab = (state: number) => {
    switch (state) {
      case TournamentStateEnum.terminated:
      case TournamentStateEnum.inprogress:
        return 2;
      case TournamentStateEnum.closed:
        return 0;
      case TournamentStateEnum.open:
      case TournamentStateEnum.scheduled:
        return 1;
      default:
        return 0;
    }
  }

  useEffect(() => {
    typeof tournament?.state === "number" && setActiveTab(defaultTab(Number(tournament?.state)))
  }, [tournament?.state])

  const tabLabels = [
    {
      label: t('Participants'),
      value: 0,
    },
    {
      label: t('Prize Pool'),
      value: 1,
    },
    {
      label: t('Rankings'),
      value: 2
    },
    {
      label: t('Table List'),
      value: 3,
    }
  ];

  if (tournament?.isGuaranteeSatellite) {
    tabLabels.push({
      label: 'Satellite List',
      value: 4,
    })
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    SoundManager.play(Sound.clickButton);
    setActiveTab(newValue);
  };

  return (
    <div className='tournament-info-table'>
      {isMobileScreenWidth || isMobile ? (
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="Game details tabs"
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
          variant="scrollable"
          sx={{
            padding: '0 0 5px 0',
            minHeight: '30px',
            '.MuiTab-root:first-of-type': {
              marginLeft: '16px',
            },
            '.MuiTab-root:last-of-type': {
              marginRight: '16px',
            }
          }}
        >
          <TabStyled className="tournament-info-table-tabs" label={t('Participants')} {...a11yProps(0)} />
          <TabStyled className="tournament-info-table-tabs" label={t('Prize Pool')} {...a11yProps(1)} />
          <TabStyled className="tournament-info-table-tabs" label={t('Ranking')} {...a11yProps(2)} />
          <TabStyled className="tournament-info-table-tabs" label={t('Table List')} {...a11yProps(3)} />
          {tournament?.isGuaranteeSatellite && <TabStyled className="tournament-info-table-tabs" label={t('Satellite Tournament')} {...a11yProps(4)} />}
        </Tabs>
      ) : (
        <div className='tournament-info-select'>
          <label>{t('View by')}</label>
          <Select
            value={activeTab}
            onChange={(e) => {
              setActiveTab(Number(e.target.value));
              SoundManager.play(Sound.clickButton);
            }}
            aria-label="Game details tabs"
            variant="outlined"
            className='select-component'
          >
            {tabLabels.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}

      <TabPanel value={activeTab} index={0}>
        {listParticipant?.length > 0 ? (
          <ParticipantsTable listParticipant={listParticipant} />
        ) : (
          <Box sx={{ height: '250px' }}>
            <EmptyData />
          </Box>
        )}
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        {tournament?.type === 'Crazy_Prize' && tournament?.state !== TournamentStateEnum.inprogress ? (
          <Box sx={{ height: '250px' }}>
            <CrazyPrizeRule cost={tournament?.cost} />
          </Box>
        ) : (
          <PrizePoolTable
            subscribeSimulation={tournament?.type !== 'Scheduled'}
            prizeDistribution={prizeDistribution}
            prizeInProgress={prizeInProgress}
          />
        )}
      </TabPanel>

      <TabPanel value={activeTab} index={2}>
        {isMatchInprocess ? <Ranking tournamentId={tournamentId} isChipGames={isChipGames} /> : (
          <FlexBox flexDirection="column" alignItems="center" justifyContent="center" sx={{ height: '250px' }}>
            <ParticipantIcon />
            <TextWrapper>
              <Typography variant="h5">{t("No classifiche yet")}</Typography>
            </TextWrapper>
          </FlexBox>
        )}
      </TabPanel>

      <TabPanel value={activeTab} index={3}>
        <TableList tournamentId={tournamentId} isChipGames={isChipGames} />
      </TabPanel>

      <TabPanel value={activeTab} index={4}>
        <SatelliteTournament tournamentId={tournamentId} isChipGames={isChipGames} onClose={onClose} />
      </TabPanel>
    </div>
  );
};