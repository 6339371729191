import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GameHelper,
  selectCurrentUser,
  selectGames,
  selectMatches,
  selectRounds,
  matchActions,
  selectMatchState,
  Game,
  GameRound,
  User,
  MatchState,
  isPopup,
  formatMoney,
  convertCentToEuro,
  CashTable,
  selectCashOnGoingGame,
  CashTableGameRound,
  selectCashRounds,
  cashTableActions,
  selectConfigState,
  UserSettings,
  selectActiveGame,
} from '@quanticogames/gameclient-core';
import { CountDown } from '@quanticogames/gameclient-components';
import { useTranslation } from 'react-i18next';
import TableComponent from 'shared/components/tables';
import { TableCell, TableRow, useMediaQuery, useTheme } from '@mui/material';
import { FlexBox } from 'shared/Flex';
import { useLocation } from 'react-router-dom';
import { mapGameIdToGame } from 'utils/mapGame';
import { getGameType } from 'utils/tournamentTitle';
import { PATH_ROUTES } from 'constants/routes';

export const OngoingMatches: React.FC<{ playCb?: any }> = ({ playCb }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const matches: any[] = useSelector(selectMatches);
  const cashTableGames: CashTable[] = useSelector(selectCashOnGoingGame)

  const games: Game[] = useSelector(selectGames);
  const rounds: GameRound[] = useSelector(selectRounds);
  const cashRounds: CashTableGameRound[] = useSelector(selectCashRounds);
  const currentUser: User = useSelector(selectCurrentUser);
  const matchState: MatchState = useSelector(selectMatchState);
  const isOpenSwitcher = matchState?.isOpenSwitcher;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const idGame = query.get('id');
  const isCashTable = window.location.href.includes(PATH_ROUTES.TABLE);

  const [tables, setTables] = useState([])
  const [isSetTableDone, setIsSetTableDone] = useState(false);
  const userSettings: UserSettings = useSelector(selectConfigState);
  const [showTour, setShowTour] = useState<boolean>(false);
  const isAlreadyTour = localStorage.getItem('isAlreadyTour') === 'true';
  const gameActive:Game = useSelector(selectActiveGame);

  const dataFake = [
    {
      id: '12345',
      game: `${gameActive?.code} DEMO`,
      tableId: 12345,
      gameMode: 'Tournament',
      totalPrize: `€ ${formatMoney(convertCentToEuro(10))}`,
      timeout: 60,
      isMyTurn: true,
      sitout: false
    }
  ]

  useEffect(() => {
      if (userSettings?.showTutorial && !isAlreadyTour) {
        setShowTour(true);
      }
    }, [currentUser]);

  useEffect(() => {
    const onGoingMath = matches.map((match) => {
      const game = games.find((g) => g.id === match.gameId);
      const round = rounds.find((r) => r.matchId === match.id);
      const interaction = round && round.activeInteraction?.playerId === currentUser.id ? round.activeInteraction : null;
      const timeout = interaction?.timeOut;
      const totalTimeout = GameHelper.getTotalTimeout(interaction);
      const isMyTurn = interaction && interaction.playerId === currentUser.id;
      const cost = match?.cost ?? JSON.parse(match?.settings ?? "{}")?.cost;
      
      return {
        ...match,
        game: game?.code,
        tableId: match?.id,
        gameMode: getGameType(match?.type, t),
        totalPrize: `€ ${formatMoney(convertCentToEuro(cost))}`,
        timeout: isMyTurn ? (timeout > 0 ? timeout : 0) : null,
        totalTimeout: totalTimeout,
        isMyTurn: isMyTurn,
        sitout: round?.sitout
      };
    });

    const cashTables = cashTableGames.map((table) => {
      const game = mapGameIdToGame(table.gameId);
      const round = cashRounds.find((r) => r.tableId === table.id);
      const interaction = round && round.activeInteraction?.playerId === currentUser.id ? round.activeInteraction : null;
      const isMyTurn = interaction && interaction.playerId === currentUser.id;
      const timeout = interaction?.timeOut;
      return {
        id: table.id,
        game: game,
        tableId: table.id,
        gameMode: 'Cash',
        totalPrize: `€ ${formatMoney(convertCentToEuro(table.invitation))}`,
        timeout: isMyTurn ? (timeout > 0 ? timeout : 0) : null,
        isMyTurn: isMyTurn,
        sitout: false
      }
    });
    if(showTour) {
      setTables(dataFake)
    } else setTables([...onGoingMath, ...cashTables])
    
    setIsSetTableDone(true)
  }, [matches, cashTableGames, rounds, cashRounds, games, currentUser, showTour])

  useEffect(() => {
    if (isOpenSwitcher) {
      const payload = tables.filter((t) => t.gameMode !== 'Cash').map((t) => ({
        matchId: t.id,
        gameCode: t.game,
        type: 'tournament',
      }));
      const payloadCash = tables.filter((t) => t.gameMode === 'Cash').map((t) => ({
        matchId: t.id,
        gameCode: t.game,
        type: 'cash',
      }));
      if (payload.length > 0) {
        dispatch(matchActions.fetchInteractions(payload));
      }
      if (payloadCash.length > 0) {
        dispatch(cashTableActions.fetchInteractions(payloadCash));
      }
    }
  }, [isOpenSwitcher, isSetTableDone]);

  const matchWindows: string[] = (localStorage['matchWindows'] && localStorage['matchWindows'].split(',')) || [];

  useEffect(() => {
    if (matchWindows.length === 0) {
      dispatch(matchActions.updateActiveMatches({}));
    }
  }, [matchWindows?.length]);

  const play = (id, gameCode, isCash = false) => {
    const idTournamentOpen = idGame === id;
    if (idTournamentOpen && isCashTable === isCash && isPopup) return;
    if (isCash) dispatch(cashTableActions.open({ tableId: id, gameCode }));
    else dispatch(matchActions.play({ matchId: id, gameCode }));
    playCb && playCb();
  };

  const pushActionTimeoutInFirst = tables.sort((a, b) => Number(b.timeout) - Number(a.timeout));
  const getTimeout = pushActionTimeoutInFirst.filter((time) => time.timeout > 0);
  const sortActionTimeout = getTimeout.sort((a, b) => Number(a.timeout) - Number(b.timeout));
  const getNoTimeout = pushActionTimeoutInFirst.filter((time) => time.timeout === 0);
  const finishTable = [...sortActionTimeout, ...getNoTimeout];

  const handleOpenNewTab = ({ id, gameCode, isCash }) => {
    const idTournamentOpen = id === idGame;
    if (idTournamentOpen && isPopup) return;
    if (isCash) {
      dispatch(cashTableActions.openTable({ id, gameCode }));
    } else dispatch(matchActions.openMatchWindow({ id, gameCode }));
  };

  type Columns = {
    id: string;
    label: string;
    align: 'left' | 'right' | 'center' | 'inherit' | 'justify';
    minWidth: string;
    render?: (row) => JSX.Element;
  };

  const TurnTime: React.FC<{ timeout: number; totalTimeout: number }> = ({ timeout, totalTimeout }) => {
    const [widthPercentage, setWidthPercentage] = useState(0);
    const [timeLeft, setTimeLeft] = useState<number>(timeout);

    const updateTimeLeft = (second) => {
      setTimeLeft(second);
    };

    useEffect(() => {
      const percentage = (timeLeft / totalTimeout) * 100;
      setWidthPercentage(percentage);
    }, [timeLeft, totalTimeout]);

    useEffect(() => {
      let myInterval = setInterval(() => {
        const time = timeLeft - 1;
        updateTimeLeft(time);
      }, 1000);

      return () => {
        clearInterval(myInterval);
      };
    });

    return <span className="number-timeout" style={{ width: `${widthPercentage}%` }}></span>;
  };

  const columns: Columns[] = useMemo(() => {
    return [
      {
        id: 'game',
        label: 'Game',
        align: 'left',
        minWidth: '',
      },
      {
        id: 'tableId',
        label: 'Tournament/Table ID',
        align: 'left',
        minWidth: '55px',
        maxWidth: '145px'
      },
      {
        id: 'gameMode',
        label: 'Game Mode',
        align: 'left',
        minWidth: '',
      },
      {
        id: 'totalPrize',
        label: 'Cost/Invitation',
        align: 'left',
        minWidth: '',
        maxWidth: '125px'
      },
      {
        id: 'timeout',
        label: 'Turn',
        align: 'left',
        minWidth: '110px',
        render: (row) => {
          const { timeout, totalTimeout, sitout, isMyTurn } = row;

          if (sitout) return <>{t('Sitout')}</>;
          return isMyTurn ?
            (
              <div className="notification-timeout">
                <TurnTime timeout={timeout} totalTimeout={totalTimeout} />
                <CountDown second={timeout} format="mm:ss" />
              </div>
            ) : (<></>)
        },
      },
    ];
  }, [])

  const MatchSwitcherItemMobile = ({ data }) => {
    const { timeout, totalTimeout, sitout, isMyTurn } = data;
    return (
      <FlexBox className="match-switch-mobile-item" width="100%" justifyContent="space-between">
        <FlexBox flexDirection="column" className="match-switch-info" gap={15}>
          <FlexBox className="match-switch-line" gap={2}>
            <span className="match-switch-label">{t('Game')}:</span>
            <span className="uppercase">{data?.game}</span>
          </FlexBox>
          <FlexBox className="match-switch-line" gap={2}>
            <span className="match-switch-label">{data.gameMode === 'Cash' ? t('Invito') : t('Costo')}:</span>
            {data?.totalPrize}
          </FlexBox>
          <FlexBox className="match-switch-line" gap={2}>
            <span className="match-switch-label">{data.gameMode === 'Cash' ? t('Table Id') : t('Tournament ID ')}:</span>
            {data?.tableId}
          </FlexBox>
        </FlexBox>
        <FlexBox flexDirection="column" className="match-switch-info" alignItems="flex-start" gap={19}>
          <FlexBox className="match-switch-line" flexDirection="column">
            <span className="match-switch-label">{t('Game Mode')}:</span>
            {t(data?.gameMode)}
          </FlexBox>
          {sitout && (
            t('Sitout')
          )}
          {!sitout && isMyTurn && (
            <div className="notification-timeout">
              <TurnTime timeout={timeout} totalTimeout={totalTimeout} />
              <CountDown second={timeout} format="mm:ss" />
            </div>
          )}
        </FlexBox>
      </FlexBox>
    );
  };

  const MatchSwitcherItemDesktop = ({ data }) => {
    const { timeout, totalTimeout, sitout, isMyTurn } = data;
    return (
      <>
        <span className="column-game">{data?.game}</span>
        <span className="column-tournament">{data?.tableId}</span>
        <span className="column-mode">{t(data?.gameMode)}</span>
        <span className="column-cost">{data?.totalPrize}</span>
        <span className="column-turn">
          {sitout && (t('Sitout'))}
          {!sitout && isMyTurn && (
              <div className="notification-timeout">
                <TurnTime timeout={timeout} totalTimeout={totalTimeout} />
                <CountDown second={timeout} format="mm:ss" />
              </div>
            )}
        </span> 
      </>
    );
  };

  return (
    <>
      {tables.length === 0 ? (
        <div className="notification-empty">{t('You have not any ongoing')}</div>
      ) : (
        <>
          {isMobile ? (
            <FlexBox flexDirection="column" gap={15} className="match-switch-mobile-list">
              {tables.map((item, index) => (
                <FlexBox
                  flexDirection="column"
                  width={'100%'}
                  id={`match-switch-mobile-${showTour ? "fake" : index}`}
                  key={index}
                  onClick={() => play(item.id, item.game, item.gameMode === 'Cash')}
                >
                  <MatchSwitcherItemMobile data={item} />
                </FlexBox>
              ))}
            </FlexBox>
          ) : (
            <FlexBox flexDirection={"column"} className="match-switch-table">
              <FlexBox className="match-switch-table-header" width="100%" alignItems={"center"} justifyContent={"flex-start"}>
                <span className="column-game">{t("Game")}</span>
                <span className="column-tournament">{t("Tournament/Table ID")}</span>
                <span className="column-mode">{t("Game Mode")}</span>
                <span className="column-cost">{t("Cost/Invitation")}</span>
                <span className="column-turn">{t("Turn")}</span>
              </FlexBox>
              <FlexBox flexDirection="column" className="match-switch-desktop-list" width="100%">
              {tables.map((item, index) => (
                <FlexBox
                  width="100%"
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  id={`match-switch-mobile-${showTour ? "fake" : index}`}
                  key={index}
                  className="match-switch-desktop-item"
                  onClick={() => play(item.id, item.gameMode === 'Cash')}
                >
                  <MatchSwitcherItemDesktop data={item} />
                </FlexBox>
              ))}
            </FlexBox>
            </FlexBox>
          )}
        </>
      )}
    </>
  );
};
