import React, { useCallback, useEffect, useState } from 'react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { CashTable, cashTableActions, Game, GameCode, GameHelper, 
  selectActiveGame, Sound, SoundManager, tournamentActions, tournamentApi,
  cashTableApi,
  CashTableConfig } from '@quanticogames/gameclient-core'
import { useTranslation } from 'react-i18next'
import './index.scss'
import { BADGES_COLLECTION } from 'constants/badges'
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';
import { useDispatch, useSelector } from 'react-redux'
import SortItem from 'shared/components/TournamentListTable/sortItem'
import Favorite from './favorite'
import PassModal from 'pages/tournament/modal/modal-pass'
import ButtonComponent from 'shared/components/button'
import TableNoData from 'shared/components/tables/component/table-nodata'
import { FilterIcon } from 'assets/icons/FilterIcon'
import { SortingOrder } from 'types/commonTable'
interface CashTableList {
  data: CashTable[];
  onSelect: (id: number, index: number) => void;
  onFilterModal: (content: string) => void;
  onSortTable: (column: string, direction: SortingOrder) => void;
}

function CashListDesktop(props: CashTableList) {
  const { data, onSelect, onFilterModal, onSortTable } = props;
  const dispatch = useDispatch();
  const game: Game = useSelector(selectActiveGame);
  const [contentDialog, setContentDialog] = useState<string>('');
  const [showPassModal, setShowPassModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const isMobile = GameHelper.isMobile();
  const [sorting, setSorting] = React.useState<SortingState>([])

  const [sortDirection, setSortDirection] = React.useState<SortingOrder>(SortingOrder.ASC)
  const [sortColumn, setSortColumn] = React.useState<string>('')
  const handleSort = (column) => {
    setSortDirection((prev) => {
      if (column !== sortColumn) {
        return SortingOrder.ASC;
      }
      switch (prev) {
        case SortingOrder.ASC:
          return SortingOrder.DESC;
        case SortingOrder.DESC:
          return SortingOrder.ASC;
      }
    });
    setSortColumn(column);
  };

  useEffect(() => {
    onSortTable(sortColumn, sortDirection)
  }, [sortDirection])
  const handleActionFavorite = async (data: CashTable, favorite: { id: number; favoriteId: number }) => {

    const element = document.getElementById(`tournament-favorite-${data?.id}`);
    const svgElement = element?.querySelector('svg');
    svgElement?.classList.add('pulse-tournament-favorite')
    setTimeout(() => {
      svgElement?.classList.remove('pulse-tournament-favorite')
    }, 1000);
    if (favorite) {
      dispatch(cashTableActions.removeFavoriteTournament({
        id: favorite?.favoriteId,
      }));
      await cashTableApi.updateFavoriteTournament(favorite?.favoriteId, false);
      return;
    }
    const response: CashTableConfig = await cashTableApi.createFavoriteTournament(data?.id);

    dispatch(cashTableActions.addFavoriteTournament({
      config: response,
    }));

  };
  const handleOpenTable = useCallback((id: number) => {
    if (!game) return;
    dispatch(cashTableActions.openTable({
      id,
      gameCode: game.code,
    }));
  }, [game]);

  const handleTournamentsDetail = (data: CashTable, index) => {
    SoundManager.play(Sound.clickButton);
    dispatch(tournamentActions.tournamentsDetail(data.id));
    onSelect(data.id, index);
  };

  const closePassModal = () => {
    setShowPassModal(false);
    dispatch(tournamentActions.resetAdvanceToNextRound({}));
  }

  const columns = React.useMemo<Array<ColumnDef<CashTable>>>(() => [
    {
      accessorKey: 'favorite',
      header: '',
      size: 60,
      cell: ({ row }) => {
        const rowData = row.original;
        return (
          <Favorite tour={rowData} handleActionFavorite={handleActionFavorite} />
        )
      }
    },
    {
      header: () => <div>{t('Description')}
        <ButtonComponent
          onClick={() => onFilterModal('description')}
          variant="text"
          className="filter-btn description-filter"
          icon={<FilterIcon />}
          size="large"
          sx={{ minWidth: 'auto', marginLeft: '5px' }}
        />
      </div>,
      id: 'description',
      size: 380,
      cell: ({ row }) => {
        const rowData = row.original;
        return (
          <div className='tournament-des-title'>{rowData.title} - Cash</div>
        );
      }
    },
    {
      header: () => <SortItem isMinContent={false} name="Invitation / Cost" column={'invitation'} handleSort={handleSort} sortColumn={sortColumn} sortDirection={sortDirection} t={t} />,
      id: 'invitation',
      size: 206,
      cell: ({ row }) => {
        const data = row.original;
        return (
          <span>{`€ ${formatMoney(convertCentToEuro(game.code === GameCode.SetteEMezzo ? data?.cost : data.invitation))}`}</span>
        );
      }
    },
    {
      header: t('Badge'),
      size: 206,
      cell: ({ row }) => {
        const data = row.original;
        return (
          <>
            <div className='badge-list'>
              {BADGES_COLLECTION['Cash_Table']?.icon}
            </div>
          </>
        );
      }
    },
    {
      header: () => <SortItem name="Players" column={'players'} handleSort={handleSort} sortColumn={sortColumn} sortDirection={sortDirection} t={t} />,
      id: 'players',
      size: 170,
      cell: ({ row }) => {
        const data = row.original;
        return <>
          <>{`${data.activeCount}/${data.numberOfSeats} ${t(isMobile ? 'players' : '')}`}</>
        </>;
      }
    },

    {
      header: () =>
        <p style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          width: '140px',
        }}>{t('Actions')}</p>,
      id: 'actions',
      size: 140,
      cell: ({ row }) => {
        const data = row.original;
        return (
          <ButtonComponent
            onClick={(e) => {
              e.stopPropagation();
              handleOpenTable(data.id)
            }}
            variant="outlined"
            title={t('Play')}
            className='lobby-button'
            rouned='large'
            size='large'
            fullWidth
          />
        )
      }
    },
  ], [sortColumn, sortDirection])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    state: {
      sorting,
    },
    onSortingChange: setSorting,

  })

  const { rows } = table.getRowModel()

  //The virtualizer needs to know the scrollable container element
  const tableContainerRef = React.useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 50, //estimate row height for accurate scrollbar dragging,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== 'undefined' &&
        navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,

  })

  const tournamentContainer = document.querySelector('.tournament-table-container');

  const containerHeight = tournamentContainer?.clientHeight;

  //All important CSS styles are included as inline styles for this example. This is not recommended for your code.
  return (
    <div className='tournament-table-container'>
      <PassModal isOpen={showPassModal} onClose={() => closePassModal()} content={contentDialog} />
      <div
        ref={tableContainerRef}
        style={{
          overflow: 'auto', //our scrollable table container
          position: 'relative', //needed for sticky header
          height: containerHeight + 'px',
        }}
      >
        {/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
        <table style={{ display: 'grid' }}>
          <thead
            style={{
              display: 'grid',
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: 'white',
            }}
          >
            {table.getHeaderGroups().map(headerGroup => (
              <tr
                key={headerGroup.id}
                style={{ display: 'flex', width: '100%' }}
              >
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      style={{
                        display: 'flex',
                        width: header.getSize(),
                        height: '55px',
                        alignItems: 'center',
                        padding: '5px 6px'
                      }}
                    >
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody
            style={{
              display: 'grid',
              height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
              position: 'relative', //needed for absolute positioning of rows
              marginBottom: '10px'
            }}
          >
            {rowVirtualizer.getVirtualItems().map(virtualRow => {
              const row = rows[virtualRow.index] as Row<CashTable>
              return (
                <tr
                  data-index={virtualRow.index} //needed for dynamic row height measurement
                  ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                  key={row.id}
                  className={'tournament-item-row'}
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                    width: '100%',
                    height: '50px',
                    borderTop: '1px solid #d2d2d3',
                  }}
                  onClick={() => handleTournamentsDetail(row.original, virtualRow.index)}
                >
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td
                        key={cell.id}
                        style={{
                          display: 'flex',
                          width: cell.column.getSize(),
                          alignItems: 'center',

                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>

              )
            })}
            {
              data.length === 0 && (
                <TableNoData />
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default CashListDesktop