import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import {
  BoardCardGame,
  BoardGameContext,
  GameHelper,
  matchActions,
  selectActiveMatchId,
  selectCasino,
  selectCashTableRound,
  selectRound,
  tournamentActions,
  useCardType,
  selectActiveTableId,
  cashTableActions,
  convertCentToEuro,
  SoundManager,
  Sound,
  IFRAME_MSG_TYPE,
  isPopup,
  selectActiveTournament,
  gameActions,
} from '@quanticogames/gameclient-core';

import { PATH_ROUTES } from 'constants/routes';
import { GameFlow } from 'games/game-flow';
import SideBar from 'layouts/sidebar';
import Table from 'shared/components/table';
import Loading from 'shared/components/loading';
import FullScreen from 'shared/FullScreenButton';
import { EndSession, ConfirmEndSession } from 'shared';
import TournamentInformation from 'shared/components/tournament-information';

import CashTableInformation from 'pages/cash-table/component/cash-table-information';
import { useTranslation } from 'react-i18next';
import { ChatAnimation } from 'shared/Chat/Component/chat-animation';
import ModalJoinTable from 'pages/cash-table/modal/modal-join-table';
import ModalJoinTableSuccess from 'pages/cash-table/modal/modal-join-table-success';

import ModalForceLeave from 'pages/cash-table/modal/ModalForceLeave';
import ModalTerminate from 'pages/cash-table/modal/ModalTerminate';
import ButtonComponent from 'shared/components/button';
import { InfoOutlined } from '@mui/icons-material';
import ModalSitNotification from 'pages/cash-table/modal/ModalSitNotification';
import { ColpoGrosso } from 'games/colpo-grosso';
import { GAME_ARR, IFRAME_GAME_ID } from 'constants/common';
import { isMobileApp } from 'constants/common';
import { LostConnection } from 'shared/LostConnection';

const LayoutGame = () => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const isMobile = GameHelper.isMobile();
  const skinType: string = useSelector(selectCasino) || 'quantico';
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [showCrazyPrizeAnimation, setShowCrazyPrizeAnimation] = useState<boolean>(false);
  const isCashTable = window.location.href.includes(PATH_ROUTES.TABLE);
  const id: number = useSelector(isCashTable ? selectActiveTableId : selectActiveMatchId);
  
  const [searchParams] = useSearchParams();
  const idFromUrl = searchParams.get('id');
  const round: any = useSelector(isCashTable ? selectCashTableRound() : selectRound());
  const [isShowInfoTable, setIsShowInfoTable] = useState<boolean>(false);
  const tournament = useSelector(selectActiveTournament);
  const location = useLocation();
  const [isShowFullScreen, setIsShowFullScreen] = useState<boolean>(false);
  const gameName = useMemo(() => {
    const arr = location?.pathname.split('/')?.filter(item => item !== '');
    return arr[arr.length - 1];
  }, [location?.pathname]);

  const [nextMatchId, setNextMatchId] = useState(null);
  const [colpoData, setColpoData] = useState(null);

  useEffect(() => {
    const currentId = id || idFromUrl || null;
    if (currentId) {
      setLoading(true);
      if (isCashTable) {
        dispatch(cashTableActions.loadCashTable(Number(currentId)));
        return () => { };
      }

      dispatch(tournamentActions.getTournament({ matchId: Number(currentId) }));
      return () => {
        dispatch(matchActions.sitout({ matchId: Number(currentId) }));
      };
    }
  }, [id, idFromUrl]);

  const frameUrlParams = useMemo(() => {
    if (!idFromUrl) return '';
    const sessionId = searchParams.get('sessionId');
    const token = localStorage.getItem('token');
    const paramObj: any = { id: idFromUrl, ...(sessionId ? { sessionId } : { token }) };
    if (isPopup) paramObj.popup = true;
    return Object.keys(paramObj).map(k => k + '=' + paramObj[k]).join('&');
  }, [searchParams, isPopup, idFromUrl]);

  useEffect(() => {
    if (round?.loaded && !GAME_ARR.includes(gameName)) setLoading(false);
  }, [round?.loaded]);

  useEffect(() => {
    if (!round?.crazyPrize?.showAnimation) return;
    const showAnimation = localStorage.getItem(`isRunAnimationsColpoGrosso_${id}`);
    if (showAnimation) return;
    const crazyPrize = new ColpoGrosso({
      data: {
        ...round.crazyPrize,
        cost: convertCentToEuro(round.tournamentCost || 0),
        prize: convertCentToEuro(round.totalPrize),
      },
      container: '.colpo-grosso-wrapper',
      onComplete: () => {
        localStorage.setItem(`isRunAnimationsColpoGrosso_${id}`, 'true');
        setShowCrazyPrizeAnimation(false);
        crazyPrize?.unmount();
      },
    });

    crazyPrize.start();
    setShowCrazyPrizeAnimation(true);

    return () => {
      crazyPrize?.unmount();
    };
  }, [round?.crazyPrize?.showAnimation]);

  useEffect(() => {
    if (colpoData) {
      const showAnimation = localStorage.getItem(`isRunAnimationsColpoGrosso_${id}`);
      if (showAnimation) return;
      const crazyPrize = new ColpoGrosso({
        data: { ...colpoData },
        container: '.colpo-grosso-wrapper',
        onComplete: () => {
          localStorage.setItem(`isRunAnimationsColpoGrosso_${id}`, 'true');
          setShowCrazyPrizeAnimation(false);
          crazyPrize?.unmount();
        },
      });

      crazyPrize.start();
      setShowCrazyPrizeAnimation(true);
      setColpoData(null);
    }
  }, [colpoData]);

  const [boardGame, updateBoardGame] = useState<BoardCardGame>(null);
  const setBoardGame = (board: BoardCardGame) => {
    if (boardGame) {
      boardGame.unmount();
      boardGame.destroy();
    }

    updateBoardGame(board);
  };

  useEffect(() => {
    return () => boardGame?.destroy();
  }, [boardGame]);

  const cardType = useCardType(gameName);
  useEffect(() => {
    if (cardType && boardGame) boardGame.setCardType(cardType);
  }, [cardType]);

  const checkShowFull = () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isIphone = /iPhone/.test(navigator.userAgent);
    const isApp = isMobileApp();
    setIsShowFullScreen(!isSafari && !isIphone && !isApp && isMobile);
  };

  const handleOpenInfoTable = () => {
    SoundManager.play(Sound.clickButton);
    setIsShowInfoTable(!isShowInfoTable);
  };

  useEffect(() => {
    const cb = (e) => {
      if (e?.data) {
        const { type, data } = e.data;
        if (type === IFRAME_MSG_TYPE.IFRAME_LOADED) setLoading(false);
        if (type === IFRAME_MSG_TYPE.REMATCH) {
          dispatch(tournamentActions.removeReMatchTournament(data));
          dispatch(tournamentActions.play({tournamentId: data.tournamentId}));
        }
        if (type === IFRAME_MSG_TYPE.GO_TO_LOBBY) {
          if (isPopup) window.close();
          else {
            SoundManager.play(Sound.clickButton.toString());
            dispatch(gameActions.goLobby({ gameId: tournament?.gameId }));
          }
          dispatch(matchActions.updateActiveMatches({}));
        }
        if (type === IFRAME_MSG_TYPE.JOIN_TABLE) {
          dispatch(cashTableActions.setBuyinPopup({
            open: true,
            seatId: data,
          }));
        }
        if (type === IFRAME_MSG_TYPE.JOIN_TABLE_REBUY) {
          dispatch(cashTableActions.setBuyinPopup({
            open: true,
            seatId: data,
            rebuy: true,
          }));
        }
        if (type === IFRAME_MSG_TYPE.OPEN_NEW_MATCH) {
          data && dispatch(matchActions.play({ matchId: data }));
          setNextMatchId(data);
          const cache = localStorage["matchWindows"];
          let matchWindows: string[] = (cache && cache.split(",")) || [];
          matchWindows = matchWindows.filter(x => x !== idFromUrl);
          localStorage["matchWindows"] = matchWindows.join(",");
        }
        if (type === IFRAME_MSG_TYPE.SHOW_ANIMATION_COLPO) {
          data && setColpoData(data);
        }
        if (type === IFRAME_MSG_TYPE.CLOSE_REBUY_MODAL) {
          dispatch(cashTableActions.setBuyinPopup({
            open: false,
            loading: false,
            seatId: null,
            rebuy: false,
          }));
        }
        if (type === IFRAME_MSG_TYPE.UPDATE_ROUND) {
          dispatch(matchActions.updateRound({
            ...data
          }));
        }
      }
    }
    window.addEventListener('message', cb);

    return () => {
      window.removeEventListener('message', cb);
    };
  }, []);

  useEffect(() => {
    const frame = document.querySelector<HTMLObjectElement>(`#${IFRAME_GAME_ID}`);
    if (frame && !loading) frame.contentWindow.postMessage({ type: IFRAME_MSG_TYPE.SET_LANG, data: i18n.language }, '*');
  }, [i18n.language, loading]);

  useEffect(() => {
    if (isPopup) {
      if (nextMatchId) {
        const cache = localStorage["matchWindows"];
        let matchWindows: string[] = (cache && cache.split(",")) || [];
        const existed = matchWindows.some(x => x === nextMatchId.toString());
        if (!existed) matchWindows.push(nextMatchId);
        localStorage["matchWindows"] = matchWindows.join(",");
      }
      setNextMatchId(null);
    }
  }, [isPopup, nextMatchId, idFromUrl]);

  useEffect(() => {
    checkShowFull();
    window.addEventListener('resize', checkShowFull);
    return () => {
      window.removeEventListener('resize', checkShowFull);
    };
  }, []);

  return (
    <BoardGameContext.Provider value={{ boardGame, setBoardGame }}>
      <div className="main-page-game">
        <div
          className={`colpo-grosso-container ${!showCrazyPrizeAnimation ? 'invisible' : 'z-[10000]'
            } flex items-center justify-center`}
        >
          <div className="colpo-grosso-wrapper absolute"></div>
        </div>
        <div
          className={`
                  game-container flex flex-col ${skinType || 'quantico'}
                  fixed left-0 right-0 bottom-0 z-[100] bg-game bg-game-${gameName}
                  ${isMobile ? 'top-0' : 'top-[60px]'}
              `}
        >
          <div className={`header-mobile w-[93px] px-[15px] py-[10px] flex items-center justify-start z-10`}>
            <a className="header-bar px-[5px] py-[5px] cursor-pointer" onClick={() => setOpen(true)}>
              <img src="/images/common/menuNew.svg" alt="menu" width="" height="" />
            </a>
            {isShowFullScreen && <FullScreen />}
          </div>
          <div
            id="main-table"
            className={`main-table main-table-${gameName} flex items-center justify-start md:justify-center flex-col ${gameName}`}
          >
            <div className="info-table-box">
              <ButtonComponent
                onClick={handleOpenInfoTable}
                icon={<InfoOutlined color="inherit" />}
                variant="text"
                size="large"
                className="info-table-icon"
                sx={{ padding: 0, minWidth: 0 }}
              />
              {isShowInfoTable && <>{isCashTable ? <CashTableInformation /> : <TournamentInformation />}</>}
            </div>
            {GAME_ARR.includes(gameName) ? (
              <>
                <iframe
                  className='iframe-game-wrapper'
                  id={IFRAME_GAME_ID}
                  src={`${process.env.REACT_APP_IFRAME_BASE_URL || ''}/${gameName.toLowerCase()}${isCashTable ? '/cash' : ''}/?${frameUrlParams}`} />
                <ChatAnimation frameId={IFRAME_GAME_ID} />
              </>
            ) : (
              <>
                <Table>
                  <ChatAnimation />
                  <Outlet />
                </Table>
                <GameFlow />
              </>
            )}
          </div>
          <SideBar open={open} onClose={() => setOpen(false)} />
          {isCashTable ? (
            <>
              <ModalJoinTable />
              <ModalJoinTableSuccess />
              <ModalForceLeave />
              <ModalTerminate />
              <ModalSitNotification />
            </>
          ) : (
            <></>
          )}

          {loading && <Loading color="#fff" className="top-0" inGame={true} />}
        </div>
        <EndSession />
        <ConfirmEndSession />
        <LostConnection />
      </div>
    </BoardGameContext.Provider>
  );
};
export default LayoutGame;