import { convertCentToEuro, selectSatelliteState, tournamentActions } from "@quanticogames/gameclient-core";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import ModalComponent from "shared/components/modal"
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import "./index.scss"

export const SatelliteModal = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch()
  
    const satelliteState: any = useSelector(selectSatelliteState);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setOpen(satelliteState.showPopup);
    }, [satelliteState])

    const onClose = () => {
        dispatch(tournamentActions.updateSatelliteStatus({
            showPopup: false,
        }))
    }
    return (
        <ModalComponent
            open={open}
            title={t('Satellite Win!')}
            onClose={onClose}
            typeModal='filter'
            showFooter={true}
            onOk={onClose}
            textOk={t('Ok')}
            showIconClose={false}
            size="small"
            className="satellite-modal-container"
        >
            {satelliteState.isSubscribed && <div>
                <p>{t('win satellite notification 1', { tournamentCode: satelliteState?.code })}</p>
            </div>}
            {!satelliteState.isSubscribed && <div>
                <p>{t('win satellite notification 2', { tournamentCode: satelliteState?.description?.tournamentCode, amount: convertCentToEuro(satelliteState?.description?.amount) })}</p>
            </div>}

        </ModalComponent>
    )

}