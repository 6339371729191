import styled from "@emotion/styled";

export const Input = styled('input')(({  }) => ({
    backgroundColor: 'rgb(18 14 28 / 50%)',
}));

export const InputWrapper = styled('div')(
    ({ }) => `
    border-radius: 4px;
    padding: 1px;
    display: flex;
  `,
);