import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Game, clubApi, selectActiveGame, IClubSettings, selectClubCanceled, gameActions, selectActiveClub, selectUserClubs, selectCurrentUser, selectOrientation, GameHelper, User } from '@quanticogames/gameclient-core';
import LayoutPage from 'layouts/layout-page';
import './index.scss';
import { ClubModeItem } from 'pages/club/components/tabs';
import { useTranslation } from 'react-i18next';
import IconLoading from 'assets/icons/Loading';
import ModalClubDeactivate from 'pages/club/components/modal/club-deactive';
import ModalNotice from 'pages/club/components/modal/club-notice';

export const ClubModePage: React.FC = (props) => {

    const { t } = useTranslation('club')
    const dispatch = useDispatch();
    const activeGame: Game = useSelector(selectActiveGame);
    const activeClub: any = useSelector(selectActiveClub)
    const currentUser: User = useSelector(selectCurrentUser)
    const userClubs: any = useSelector(selectUserClubs)
    const [hasClub, setHasClub] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const clubCancelled: any = useSelector(selectClubCanceled);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const clubId = params.get('clubId')
    const url = new URL(window.location.href);
    const isMobilePortrait = useSelector(selectOrientation) === 'portrait';
    const isMobileLandScape = useSelector(selectOrientation) === 'landscape';
    useEffect(() => { 
        const layoutElement = document.getElementById('layout-page');
        if (layoutElement && isMobileLandScape) {
            layoutElement.style.height = '320px';
        }
        const mainContainer = document.querySelector('.main-container') as HTMLElement;

        if (mainContainer) { 
            mainContainer.style.overflow = 'hidden';
        }

        return () => { 
            if (layoutElement) {
                layoutElement.style.height = '100%';
            }
            if(mainContainer) {
                mainContainer.style.overflow = 'auto';
            }
        }
    }, [isMobileLandScape]);
    useEffect(() => {
        if (url.pathname.includes('/club') && clubId) {
            getUserClubs(Number(clubId))
        }
        window.history.replaceState(null, "", url.pathname)
    }, [
        clubId
    ]);

    useEffect(() => {
        if (clubCancelled.showing) {
            getUserClubs()
        }
    }, [clubCancelled]);

    const getUserClubs = async (id: number = null) => {
        try {
            const data: IClubSettings[] = await clubApi.getListClub(activeGame?.id)
            dispatch(gameActions.updateClubsInfo(data.map((club, index) => {
                return {
                    id: club.id,
                    clubSetting: club,
                    active: index === 0,
                    tournamentAAMS: club?.tournamentAAMS,
                    tournamentCode: club?.tournamentCode
                }
            })))
            dispatch(gameActions.updateActiveClub({
                id: id ? id : data[0]?.id,
            }))
            setLoading(false)
            for (let i = 0; i < data.length; i++) {
                if (data[i].clubMembers?.length > 0) {
                    for (let j = 0; j < data[i].clubMembers.length; j++) {
                        if (data[i].clubMembers[j].isOwner && data[i].clubMembers[j].userId === currentUser?.id) {
                            setHasClub(true)
                            return;
                        }
                    }
                }
            }
            setHasClub(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (activeGame?.id) {
            setLoading(true)
            getUserClubs()
        }
    }, [activeGame]);

    const handleCreateClub = (id: number) => {
        setLoading(true)
        setTimeout(() => {
            getUserClubs(id)
        }, 1000)
    }

    const handleCancelClub = () => {
        setLoading(true)
        const newClubs = userClubs.slice(1)
        dispatch(gameActions.updateClubsInfo(newClubs))
        setHasClub(false)
        getUserClubs()
    }

    const handleCancelInvite = async () => {
        try {
            setHasClub(false)
            dispatch(gameActions.removeInvitation({ clubId: activeClub.id }));
            await clubApi.refuseInvite({
                clubId: activeClub.id,
            });
            getUserClubs()
        }
        catch (error) {
          console.log(error);
        }
    };

    useEffect(() => {
        if (userClubs.length === 0) {
            setHasClub(false)
        }
        else {

        }
    }, [userClubs])

   
    const orientation = useSelector(selectOrientation);
    const scaleScreenTournament = () => {
        setTimeout(() => {
            const wrapperEl = document.querySelector('.header-tabs-container') as HTMLElement;
            const clubElement = document.querySelector('.club-layout') as HTMLElement;
            const clientWidth = wrapperEl?.clientWidth;
            const clientHeight = document.querySelector('.main-container')?.clientHeight;
            const elW = 1176;
            const elH = 570;
            let w;
            let h = 0;
            if (clientWidth > 900) {
                w = 0;
            } else {
                w = 8
            }
            if (clientHeight < 750) {
                h = 8;
            }
            if (clientWidth > 500 && clubElement) {
                const scaleW = (clientWidth - w) / elW;
                const scaleH = (clientHeight - h) / elH;
                const isUseHScale = orientation === 'desktop' && scaleW > scaleH;
                const usedScale = isUseHScale ? scaleH : scaleW;
                clubElement.style.transform = `scale(${(usedScale).toFixed(3)})`;
                const nowWidth = document.getElementById('club-layout').clientWidth * (usedScale);
                const layoutWidth = document.getElementById('layout-page').clientWidth;
                const transformWidth = (layoutWidth - nowWidth);
                clubElement.style.transformOrigin = '0px 0px'
                clubElement.style.marginLeft = `${transformWidth / 2}px`;
                
            } else if (clubElement) {
                clubElement.style.transform = '';
                clubElement.style.transformOrigin = "top center";
                clubElement.style.marginLeft = '';
            }

        }, 200);
    };

    useEffect(() => {
        scaleScreenTournament();
        const handleResize = () => {
            scaleScreenTournament();
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [orientation]);

    useEffect(() => { 
        if (orientation === 'portrait') {
            window.scrollTo(0, 20);
        }
    }, [orientation]);

    return (
        <LayoutPage>
            <div className='club-layout' id="club-layout">
                <div className='overlay'></div>
                {loading &&
                    <div className='loading'>
                        <IconLoading />
                    </div>
                }
                <div className='tabs-desktop'>
                    {!hasClub &&
                        <div className={`tab ${!activeClub ? 'tab-active' : null}`} onClick={() => {
                            dispatch(gameActions.updateActiveClub({
                                id: -1
                            }))
                        }}>
                            {t("Create")}
                        </div>
                    }
                    {userClubs.length > 0 && userClubs.map((club, index) => {
                        if(!club?.clubSetting) return null;
                        return (
                            <div key={index} className={`tab ${club?.id === activeClub?.id ? 'tab-active' : null}`} onClick={() => {
                                dispatch(gameActions.updateActiveClub({
                                    id: club.id
                                }))
                            }}>
                                {club.clubSetting?.tournamentCode}
                            </div>
                        )
                    })}
                </div>
                <ClubModeItem onCreateFinish={handleCreateClub} onCancelFinish={handleCancelClub} onCancelInvite={handleCancelInvite}/>
            </div>
            <ModalClubDeactivate />
            <ModalNotice />
            <div className='club-footer'></div>
        </LayoutPage>
    );
};



