import React, { useEffect, useState } from 'react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { Game, selectActiveGame } from '@quanticogames/gameclient-core'
import { useTranslation } from 'react-i18next'
import './index.scss'
import { TournamentItemHelper } from './desktopHelpers'
import { BADGES_COLLECTION } from 'constants/badges'
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';
import { StateLabel } from 'pages/tournament/component/tournament-state'
import { TOURNAMENT_STATES_TITLES } from 'pages/tournament/component/tournament-label'
import TournamentItemButton from 'shared/components/TournamentListTable/item-button'
import { useSelector } from 'react-redux'
import Favorite from './favorite'
import ButtonComponent from 'shared/components/button'
import { FilterIcon } from 'assets/icons/FilterIcon';
import { SortingOrder } from 'types/commonTable'
import SortItem from 'shared/components/TournamentListTable/sortItem'
import { getHighlightClass } from 'utils/tournamentTable'
import Tooltip from '@mui/material/Tooltip';
import { mockTableData } from 'constants/tutorial'


function TournamentListFake() {
  const data = mockTableData;
  const tournamentItem = TournamentItemHelper({onAction: () => {}});
  const game: Game = useSelector(selectActiveGame);
  const { renderTitle } = tournamentItem;
  const { t } = useTranslation();
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [sortDirection, setSortDirection] = React.useState<SortingOrder>(SortingOrder.ASC)
  const [sortColumn, setSortColumn] = React.useState<string>('');
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [tournamentWidth, setContainerWidth] = useState<number>(1165);
  const isScheduled = window.location.pathname.includes('tournaments');

  useEffect(() => {
    const handleResize = () => {
      const tournamentContainer = document.querySelector('.tournament-table-container');
      setContainerWidth(tournamentContainer?.clientWidth ?? 1165);
      setContainerHeight(tournamentContainer?.clientHeight);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSort = (column) => {
  };

  const columns = React.useMemo<Array<ColumnDef<any>>>(() => [
    {
      accessorKey: 'favorite',
      header: '',
      size: tournamentWidth * 0.04,
      cell: ({ row }) => {
        const rowData = row.original;
        return (
          <Favorite tour={rowData} handleActionFavorite={() =>{}} />
        )
      }
    },
    {
      header: () => <div>{t('Description')}
        <ButtonComponent
          onClick={() => {}}
          variant="text"
          className="filter-btn description-filter"
          icon={<FilterIcon />}
          size="large"
          sx={{ minWidth: 'auto', marginLeft: '5px' }}
        />
      </div>,
      id: 'Description',
      size: tournamentWidth * 0.38,
      cell: ({ row }) => {
        const rowData = row.original;
        const title = renderTitle(rowData, true);
        const isShowPopup = isScheduled;
        return (
          <div className={`tournament-des tournament-des-${rowData.id}`}>
            <div className="tournament-des-content">
              <Tooltip title={title} placement="top-start" disableHoverListener={!isShowPopup} >
                <p style={{
                  width: tournamentWidth * 0.38 - 30
                }} className={`tournament-des-title ${isScheduled ? 'tournament-des-title-schedule' : ''}`}>{title}</p>
              </Tooltip>
            </div>
          </div>
        );
      }
    },
    {
      header: t('Badge'),
      size: tournamentWidth * 0.15,
      cell: ({ row }) => {
        const data = row.original;
        return (
          <div className="badge-list">
            {data.cost === 0 && <>{BADGES_COLLECTION['Free']?.icon}</>}
            {data.structure === 'MT' && data.matchType === 0 && data?.type !== 'Scheduled' ? (
              <div className="badge-icon">{`${data.maxPlayers}P`}</div>
            ) : (
              <>{BADGES_COLLECTION[data.matchType]?.icon}</>
            )}
            {data.allowToken && <>{BADGES_COLLECTION['Token_Icon']?.icon}</>}
            {BADGES_COLLECTION[data?.type]?.icon}
            {(data?.isGuaranteeSatellite || data?.parentGuaranteeId) && BADGES_COLLECTION['Satellite']?.icon}
          </div>
        );
      }
    },
    {
      header: () => <SortItem name="Cost" column={'cost'} handleSort={handleSort} sortColumn={sortColumn} sortDirection={sortDirection} t={t} />,
      id: 'cost',
      size: tournamentWidth * 0.08,
      cell: ({ row }) => {
        const data = row.original;
        return (
          <>
            <span>{data.cost === 0 ? t('Free') : `€ ${formatMoney(convertCentToEuro(data.cost))}`}</span>
          </>
        );
      }
    },
    {
      header: () => <SortItem name="Players" column={'players'} handleSort={handleSort} sortColumn={sortColumn} sortDirection={sortDirection} t={t} />,
      id: 'players',
      size: tournamentWidth * 0.1,
      cell: ({ row }) => {
        const data = row.original;
        return <>{`${data.registered}/${data.maxPlayers}`}</>;
      }
    },
    {
      header: () => <div>{t('State')}
        <ButtonComponent
          onClick={() => {}}
          variant="text"
          className="filter-btn description-filter"
          icon={<FilterIcon />}
          size="large"
          sx={{ minWidth: 'auto' }}
        /></div>,
      id: 'state',
      size: tournamentWidth * 0.12,
      cell: ({ row }) => {
        const data = row.original;
        const state = data.state;

        return (
          <StateLabel
            color={TOURNAMENT_STATES_TITLES[state]?.color}
            background={TOURNAMENT_STATES_TITLES[state]?.background}
          >
            {t(TOURNAMENT_STATES_TITLES[state]?.title)}
          </StateLabel>
        )
      }
    },
    {
      header: () => <div className='w-full flex justify-center' style={{
        width: tournamentWidth * 0.12
      }}>
        {t('Actions')}
      </div>,
      id: 'actions',
      size: tournamentWidth * 0.12,
      cell: ({ row }) => {
        const data = row.original;
        return (
          <TournamentItemButton data={data} onAction={() => {}} handleOpenNewTab={() => {}} handlePlayScheduled={() => {}} />
        )
      }
    },
  ], [sortDirection, sortColumn, tournamentWidth, game])


  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    state: {
      sorting,
    },
    onSortingChange: setSorting,

  })

  const { rows } = table.getRowModel()

  //The virtualizer needs to know the scrollable container element
  const tableContainerRef = React.useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 50, //estimate row height for accurate scrollbar dragging,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== 'undefined' &&
        navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 7,

  })

  //All important CSS styles are included as inline styles for this example. This is not recommended for your code.
  return (
    <div className='tournament-table-container tournament-container tournament-Normal'>
      <div
        ref={tableContainerRef}
        style={{
          overflow: 'auto', //our scrollable table container
          overflowX: 'hidden',
          position: 'relative', //needed for sticky header
          height: containerHeight + 'px', //should be a fixed height
        }}
      >
        {/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
        <table style={{ display: 'grid' }}>
          <thead
            style={{
              display: 'grid',
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: 'white',
            }}
          >
            {table.getHeaderGroups().map(headerGroup => (
              <tr
                key={headerGroup.id}
                style={{ display: 'flex', width: '100%' }}
              >
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      style={{
                        display: 'flex',
                        width: header.getSize(),
                        height: '55px',
                        alignItems: 'center',
                        padding: '5px 6px'
                      }}
                    >
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody
            style={{
              display: 'grid',
              height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
              position: 'relative', //needed for absolute positioning of rows
              marginBottom: '10px'
            }}
          >
            {rowVirtualizer.getVirtualItems().map(virtualRow => {
              const row = rows[virtualRow.index] as Row<any>

              return (
                <tr
                onClick={() => {}}
                  data-index={virtualRow.index} //needed for dynamic row height measurement
                  ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                  key={row.id}
                  className={`tournament-item-row type-Normal ${getHighlightClass(row.original.highlight)}`}
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                    width: '100%',
                    height: '50px',
                    borderTop: '1px solid #d2d2d3',
                  }}
                >
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td
                        key={cell.id}
                        style={{
                          display: 'flex',
                          width: cell.column.getSize(),
                          alignItems: 'center',
                          wordBreak: 'break-word',

                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TournamentListFake