import { REGULATION_URL, TOURNAMENT_TYPE } from 'constants/common'
import React, { useState, useEffect } from 'react'
import { FlexBox } from 'shared/Flex'
import {  useSelector } from 'react-redux';
import {
    Tournament,
    selectTournaments,
    selectActiveGame,
    Game,
    GameHelper,
    selectCasino
} from '@quanticogames/gameclient-core';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';

interface ColpoGrossoProps {
    type: string;
    subscribe: (tournament: Tournament) => void;
    onSelect: (id: number) => void;
}

const ColpoGrosso = (props: ColpoGrossoProps) => {
    const { type, onSelect, subscribe } = props;
    const { t } = useTranslation();
    const tournaments = useSelector(selectTournaments) as Tournament[];
    const [showColpoGrossoPrize1, setShowColpoGrossoPrize1] = useState<boolean>(false);
    const [showColpoGrossoPrize5, setShowColpoGrossoPrize5] = useState<boolean>(false);
    const [showColpoGrossoPrize10, setShowColpoGrossoPrize10] = useState<boolean>(false);
    const [showFilottoPrize1, setShowFilottoPrize1] = useState<boolean>(false);
    const [showFilottoPrize7, setShowFilottoPrize7] = useState<boolean>(false);
    const [showFilottoPrize18, setShowFilottoPrize18] = useState<boolean>(false);
    const activeGame: Game = useSelector(selectActiveGame);
    const theme = useTheme();
    const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('sm'));
    const isMobile = GameHelper.isMobile() && isMobileScreenWidth;
    const skinType: string = useSelector(selectCasino) || 'quantico';
    const [lang] = useState<string>(localStorage.getItem('i18nextLng') || 'it');

    const findTournamentPrize = (prize) => {
        const tournament = tournaments.find((tournament) =>
            tournament.hasPassword === false &&
            tournament.cost == prize && tournament.registered < tournament.maxPlayers
        );
        return tournament
    }
    const tourmanentColpogrosso = () => {
        setShowColpoGrossoPrize1(findTournamentPrize(100) !== null);
        setShowColpoGrossoPrize5(findTournamentPrize(500) !== null);
        setShowColpoGrossoPrize10(findTournamentPrize(1000) !== null);
        setShowFilottoPrize1(findTournamentPrize(100) !== null)
        setShowFilottoPrize7(findTournamentPrize(700) !== null)
        setShowFilottoPrize18(findTournamentPrize(1800) !== null)
    }

    useEffect(() => {
        tourmanentColpogrosso();
    }, [activeGame, window.location.href, tournaments]);

    const handleOpenPrize = (prize) => {
        if (!tournaments || tournaments.length === 0) {
            return null;
        }
        const tournament = findTournamentPrize(prize);
        if (tournament) {
            if (!tournament.isSubscribed) {
                subscribe(tournament);
            }
            onSelect(tournament.id);
        } else {
            throw new Error(`Could not find colpo grosso tournament cost=${prize}`);
        }
    }
    return (
        <div className={`tournament-type ${type === TOURNAMENT_TYPE.CRAZY_PRIZE ? 'colpo-grosso-tournament' : 'filotto-tournament'}`}>
            {type === TOURNAMENT_TYPE.CRAZY_PRIZE && (
                <FlexBox flexDirection="column" justifyContent="center" gap={17} alignItems="center" className="colpo-grosso-main">
                    <div className="colpo-grosso-images">
                        <img src={`/images/tournament/${lang}-colpogrosso-${isMobile ? 'mobile': 'desktop'}.${isMobile ? 'png':'webp'}`} width={'100%'} height={'auto'} />
                    </div>
                    <FlexBox flexDirection="column" justifyContent="center" className="colpo-grosso-content">
                        <p className="colpo-grosso-title">{t('Select your buy-in')}</p>
                        <FlexBox alignItems="center" className="colpo-grosso-list" width="100%" gap={15}>
                            <FlexBox className="colpo-grosso-item" flexDirection="column" alignItems="center" justifyContent="flex-start" onClick={() => handleOpenPrize(100)}>
                                {showColpoGrossoPrize1 && <>
                                    <p className='colpo-grosso-prize'>€1</p>
                                    <p className="colpo-grosso-text">{t("Win up to")}</p>
                                    <p className="colpo-grosso-total-prize">€50.000</p>
                                </>}
                            </FlexBox>
                            <FlexBox className="colpo-grosso-item" flexDirection="column" alignItems="center" justifyContent="flex-start" onClick={() => handleOpenPrize(500)}>
                                {showColpoGrossoPrize5 && <>
                                    <p className='colpo-grosso-prize'>€5</p>
                                    <p className="colpo-grosso-text">{t("Win up to")}</p>
                                    <p className="colpo-grosso-total-prize">€250.000</p>
                                </>}
                            </FlexBox>
                            <FlexBox className="colpo-grosso-item" flexDirection="column" alignItems="center" justifyContent="flex-start" onClick={() => handleOpenPrize(1000)}>
                                {showColpoGrossoPrize10 && <>
                                    <p className='colpo-grosso-prize'>€10</p>
                                    <p className="colpo-grosso-text">{t("Win up to")}</p>
                                    <p className="colpo-grosso-total-prize">€500.000</p></>}
                            </FlexBox>
                        </FlexBox>
                        <FlexBox alignItems="center" width="100%" className="colpo-grosso-bottom">
                            <div className="colpo-grosso-bottom-content">
                                <a href={`${REGULATION_URL}/lobby?tabs=Colpo-grosso&casino=${skinType}`} target={isMobile ? '' : '_blank'} className="colpo-grosso-link">{t("BIG SHOOT's rule description")}</a>
                            </div>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            )}

            {type === TOURNAMENT_TYPE.TREBLE && (
                <FlexBox flexDirection="column" justifyContent="center" alignItems="center" gap={17} className="filotto-main">
                    <div className="filotto-images">
                        <img src={`/images/tournament/${lang}-filotto-new-${isMobile ? 'mobile': 'desktop'}.${isMobile ? 'png':'webp'}`} width={'100%'} height={'auto'} />
                    </div>
                    <FlexBox flexDirection="column" justifyContent="center" className="filotto-content">
                        <p className="filotto-title">{t('Select your buy-in')}</p>
                        <FlexBox alignItems="center" className="filotto-list" width="100%" gap={15}>
                            <FlexBox className="filotto-item" flexDirection="column" alignItems="center" justifyContent="flex-start" onClick={() => handleOpenPrize(100)}>
                                {showFilottoPrize1 && <>
                                    <p className='filotto-prize'>€1</p>
                                    <p className="filotto-text">{t("win")}</p>
                                    <p className="filotto-total-prize">€6,40</p>
                                </>}
                            </FlexBox>
                            <FlexBox className="filotto-item" flexDirection="column" alignItems="center" justifyContent="flex-start" onClick={() => handleOpenPrize(700)}>
                                {showFilottoPrize7 && <>
                                    <p className='filotto-prize'>€7</p>
                                    <p className="filotto-text">{t("win")}</p>
                                    <p className="filotto-total-prize">€50</p>
                                </>}
                            </FlexBox>
                            <FlexBox className="filotto-item" flexDirection="column" alignItems="center" justifyContent="flex-start" onClick={() => handleOpenPrize(1800)}>
                                {showFilottoPrize18 && <>
                                    <p className='filotto-prize'>€18</p>
                                    <p className="filotto-text">{t("win")}</p>
                                    <p className="filotto-total-prize">€130</p></>}
                            </FlexBox>
                        </FlexBox>
                        <FlexBox alignItems="center" width="100%" className="filotto-bottom">
                            <div className="filotto-bottom-content">
                                <a href={`${REGULATION_URL}/lobby?tabs=Filotto&casino=${skinType}`} target={isMobile ? '' : '_blank'} className="filotto-link">{t("3-IN-A-ROW's rule description")}</a>
                            </div>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            )}
        </div>
    )
}

export default React.memo(ColpoGrosso)