import React, { useEffect, useState } from 'react';
import {
  Box,
  Pagination,
  styled,
  TableCell,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import {
  Tournament,
  TournamentMatch,
  tournamentApi,
} from '@quanticogames/gameclient-core';
import TableComponent from 'shared/components/tables';

import { statusTournament } from 'utils/tournamentTitle';
import TagComponent from 'shared/Tags';
import theme from 'theme';
import { useNavigate } from 'react-router';

type SatelliteTournament = {
  data?: Tournament | null;
  tournamentId?: number;
  satellites?: TournamentMatch[];
  isChipGames: boolean;
  onClose: () => void;
};

const PaginationWrapper = styled(Box)(() => ({
  paddingTop: '28px',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#0B0E11',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
  },
}));

export const SatelliteTournament: React.FC<SatelliteTournament> = ({ tournamentId, onClose }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [satellites, setSatellite] = useState<any>();
  const [total, setTotal] = useState<any>(0);
  const getList = async (page) => {
    try {
      const params = {
        tournamentId,
        page,
        pageSize: 10,
      } as any
      const participantResponse = await tournamentApi.getTournamentSatellite(params);

      setSatellite(participantResponse?.data);
      setTotal(participantResponse?.total);
    } catch (error) {
      console.error('Error fetching participants:', error);
    }
  };

  useEffect(() => {
    getList(page);
  }, [page]);


  const HEAD_DATA_TABLE_LISTS = [
    {
      id: 'id',
      label: 'ID Table',
    },
    {
      id: 'status',
      label: 'Status',
    },
  ];

  return (
    <>
      <TableComponent columns={HEAD_DATA_TABLE_LISTS}>
        {satellites?.length > 0 && satellites?.map((tournament) => {
          const statusItem = statusTournament.find(item => item.value === tournament?.state);
          return (
            <>
              <TableRow key={`satellite-table-item-${tournament?.id}`}>
                <TableCell align="left" width={'150'}>
                  <p 
                    style={{
                      color: '#1DA25B',
                      fontWeight: 'bold',
                      marginLeft: 10,
                      cursor: tournament?.state === 5 ? '' : 'pointer',
                    }} 
                  onClick={() => {
                    if(tournament?.state === 5) return;
                    navigate("/sit-and-go?code=" + tournament?.code);
                    onClose()
                  }}>
                    {tournament?.code}
                  </p>
                </TableCell>
                <TableCell align="left" width="100">
                  <TagComponent text={statusItem.label} color={statusItem.color} />
                </TableCell>
              </TableRow>

            </>
          );
        })}
      </TableComponent>
      {isDesktop && total > 0 && (
        <PaginationWrapper>
          <StyledPagination
            onChange={(e) => setPage(page)}
            count={Math.ceil(satellites?.total / (satellites?.pageSize || 1))}
            hidePrevButton
            hideNextButton
          />
        </PaginationWrapper>
      )}
    </>
  );
};
