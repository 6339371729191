import { chatActions, IFRAME_MSG_TYPE, selectActiveGame, selectActiveMatchId, selectChatAnimations, selectChatTableEnabled, handleEmoji as sendEmoji, handleTextMsg as sendText } from "@quanticogames/gameclient-core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const ChatAnimation = ({ frameId }: { frameId?: string }) => {
    const dispatch = useDispatch();
    const animationEvents: any[] = useSelector(selectChatAnimations);
    const roomId = useSelector(selectActiveMatchId);
    const chatTableEnabled = useSelector(selectChatTableEnabled);
    const activeGame: any = useSelector(selectActiveGame)
    useEffect(() => {
        const event = animationEvents && animationEvents.length && animationEvents[animationEvents.length - 1];
        if (!event) return;

        switch (event.eventName) {
            case 'Emoji':
                handleEmoji(event.data);
                break;
            case 'Message':
                handleMsg(event.data);
                break;
        }

        dispatch(chatActions.removeAnimationEvent({ conversationId: roomId }));
    }, [animationEvents]);

    const handleEmoji = ({ title, playerId }) => {
        if (!chatTableEnabled) return;
        const isReverse = activeGame?.id === 14; // Solitario
        if (frameId) {
            const frame = document.querySelector<HTMLObjectElement>(`#${frameId}`);
            if (frame) frame.contentWindow.postMessage({ type: IFRAME_MSG_TYPE.MSG_EMOJI, playerId, title, isReverse }, '*');
            return;
        }
        sendEmoji({ title, playerId, isReverse })
    };

    const handleMsg = ({ message, playerId, conversationId }) => {
        if (!chatTableEnabled || conversationId?.includes('helpdesk') || conversationId?.includes('lobby')) return;
        const isReverse = activeGame?.id === 14; // Solitario
        if (frameId) {
            const frame = document.querySelector<HTMLObjectElement>(`#${frameId}`);
            if (frame) frame.contentWindow.postMessage({ type: IFRAME_MSG_TYPE.MSG_TEXT, playerId, message, isReverse }, '*');
            return;
        }
        sendText({ message, playerId, isReverse });
    };

    return (
        <></>
    );
};

