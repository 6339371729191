import { useTranslation } from "react-i18next";
import './index.scss';

const ScheduledPage = () => {
    const { t } = useTranslation();
    const timeFrom = "2:00 AM";
    const timeTo = "4:00 AM";

    return <div className="unexpected-page">
        <div className="unexpected-images">
            <img src="/images/common/system-scheduled-maintenance.webp"/>
        </div>
        <div className="unexpected-content">
            <p>{t("Scheduled page content", {timeFrom, timeTo})}</p>
        </div>
    </div>
}
export default ScheduledPage