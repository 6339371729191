import { useTranslation } from "react-i18next";
import ModalComponent from "shared/components/modal"
import "./club.scss";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectClubCanceled } from "@quanticogames/gameclient-core";


interface ClubJoinNoticeProps {
    open: boolean;
    AAMSCode: string;
    TournamentCode: string;
    onClose: () => void;
}
export const ClubJoinNotice = (props: ClubJoinNoticeProps) => {
    const {t} = useTranslation('club')
    const { open, AAMSCode, TournamentCode, onClose } = props;
    
    const clubCancelled: any = useSelector(selectClubCanceled);

    useEffect(() => {
        if(clubCancelled?.showing) {
            onClose()
        }
    }, [clubCancelled?.showing])
   
    return (
        <ModalComponent
            sx={{ zIndex: 100000010 }}
            open={open}
            title={t('Tournament registration')}
            showIconClose={true}
            showFooter={false}
            size='small'
            onClose={() => {
                onClose()
            }}
            className="modal-club-canceled"
        >
            <p> {AAMSCode ? t('You have registered for the', { AAMSCode: AAMSCode, tournamentCode: TournamentCode }) : t('You have registered for the free', { tournamentCode: TournamentCode })}</p>
        </ModalComponent>
    )
}