import { gameActions, selectClubCanceled} from '@quanticogames/gameclient-core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ModalComponent from 'shared/components/modal';
import "./club.scss";

const ModalClubCanceled = () => {
  const { t } = useTranslation('club');
  const dispatch = useDispatch();
  const clubCancelled: any = useSelector(selectClubCanceled);
  const handleClose = () => {
    dispatch(gameActions.updateClubCanceled({ showing: false }));
  }
  return (
    <ModalComponent 
      open={clubCancelled.showing} 
      onClose={handleClose} 
      className="modal-club-canceled"
      size='small'
      title={t("Notification")}
      textOk={t('Close')}
      onOk={handleClose}
      showFooter={false}
    >
      <p>{t('tournament cancelled', {code: clubCancelled.tournamentCode})}</p>
    </ModalComponent>
  );
};

export default ModalClubCanceled;
