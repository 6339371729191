import { all } from 'redux-saga/effects'
import { authSaga, chatSaga, gameSaga, matchSaga, tournamentSaga, cashTableSaga, configSaga } from '@quanticogames/gameclient-core';
// import { assoPigliaTuttoSaga } from 'games/assoPigliaTutto';
import { cirullaSaga } from 'games/cirulla';
import { bestiaSaga } from 'games/bestia';
import { peppaSaga } from 'games/peppa';
import { trentunoSaga } from 'games/trentuno';
import { ginRummySaga } from 'games/ginRummy';

export default function* rootSaga() {
  yield all([
    authSaga(),
    chatSaga(),
    gameSaga(),
    tournamentSaga(),
    matchSaga(),
    // tresetteSaga(),
    // assoPigliaTuttoSaga(),
    cirullaSaga(),
    bestiaSaga(),
    // scoponeSaga(),
    // solitarioSaga(),
    cashTableSaga(),
    // telesinaSaga(),
    // telesinaCashSaga(),
    peppaSaga(),
    trentunoSaga(),
    ginRummySaga(),
    configSaga(),
  ])
}
