import { Tournament, TournamentStateEnum, ParticipantStatusEnum, selectCurrentUser, selectBalanceInfo } from "@quanticogames/gameclient-core"
import LockIcon from "assets/icons/Lock";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ButtonComponent from "shared/components/button";

interface ItemButtonProps {
    data: Tournament;
    onAction: (action: string, data: any, index?: number) => void;
    handlePlayScheduled: (id: number) => void;
    handleOpenNewTab: (e: any, id: number) => void;
}

const ItemButton = (props: ItemButtonProps) => {
    const { data, onAction, handleOpenNewTab, handlePlayScheduled } = props;
    const currentUser = useSelector(selectCurrentUser)
    const balance = useSelector(selectBalanceInfo)
    const { t } = useTranslation('common');
    const participantsLength = data?.participants?.length;
    const maxPlayers = data?.maxPlayers;
    const registeredNumber = data?.registered;
    
    const isMultipleMatch = data?.type === 'Crazy_Prize' || data?.type === 'Scheduled' || data?.type === 'Treble' || data?.parentGuaranteeId // Satellite;
    
    let nonClickable =
        data?.state === TournamentStateEnum.scheduled ||
        data?.state === TournamentStateEnum.closed ||
        data?.state === TournamentStateEnum.terminated;
    nonClickable = nonClickable || data?.currentPlayerStatus == ParticipantStatusEnum.Eliminated
        || data?.participants?.some(item => item.userId == currentUser.id && item.status == ParticipantStatusEnum.Eliminated)

    const isSubscribed = data?.isSubscribed;
    const canPlay = data?.state === TournamentStateEnum.inprogress && isSubscribed;
    const canSubscribe = !isSubscribed && data.state != TournamentStateEnum.inprogress;
    const canUnsubscribe =
        isSubscribed && data.state != TournamentStateEnum.closed && data.state != TournamentStateEnum.inprogress;

    const isNoneClickSpectator = data?.state === TournamentStateEnum.inprogress && isMultipleMatch && !isSubscribed;

    return (
        <div style={{
            display: 'flex',
            width: '100%',
        }}>
            {canPlay && (
                <div className="flex items-center play-button">
                    <ButtonComponent
                        variant="outlined"
                        title={t('Play')}
                        disabled={nonClickable}
                        rouned="large"
                        size="large"
                        color="primary"
                        fullWidth
                        className="lobby-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (data.type === 'Scheduled' || data?.parentGuaranteeId) {
                                handlePlayScheduled(data.id);
                            } else {
                                handleOpenNewTab(e, data.id);
                            }
                        }}
                        startIcon={data?.hasPassword && <span style={{ position: 'absolute', left: '16px', bottom: '11px' }}><LockIcon /></span>}
                    />
                </div>
            )}
            {canSubscribe && (
                <div className="subscribe-button">
                    <ButtonComponent
                        variant="contained"
                        title={t('Subscribe')}
                        rouned="large"
                        size="large"
                        color="success"
                        fullWidth
                        className="lobby-button"
                        disabled={
                            nonClickable ||
                            balance === undefined ||
                            participantsLength == maxPlayers ||
                            maxPlayers === registeredNumber
                        }
                        onClick={(e) => {
                            e.stopPropagation();
                            onAction('subscribe', data);
                        }}
                        startIcon={data?.hasPassword && <span style={{ position: 'absolute', left: '14px', bottom: '12px' }}><LockIcon fill='white' /></span>}
                    />
                </div>
            )}

            {canUnsubscribe && (
                <div className="play-button">
                    <ButtonComponent
                        variant="outlined"
                        title={t('Unsubscribe')}
                        className="lobby-button"
                        disabled={nonClickable || data.isSatelliteSubscriber}
                        color="error"
                        rouned="large"
                        size="large"
                        fullWidth
                        onClick={(e) => {
                            e.stopPropagation();
                            onAction('unsubscribe', data.id);
                        }}
                    />
                </div>
                
            )}

            {!isSubscribed && data.state == TournamentStateEnum.inprogress && (
                <div className="play-button">
                    <ButtonComponent
                        variant="outlined"
                        title={t('Spectator')}
                        className="lobby-button"
                        disabled={isNoneClickSpectator}
                        color="primary"
                        rouned="large"
                        size="large"
                        fullWidth
                        onClick={(e) => {
                            handleOpenNewTab(e, data.id);
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default ItemButton;