import { useEffect, useRef, useState } from "react";
import IconLoading from "assets/icons/Loading";
import { useTranslation } from "react-i18next";
type LoadingProps = {
    color?: string,
    background?: string,
    className?: string,
    inGame?: boolean;
}

const preLoadImage = (imagePath: string) => {
    const tmpImg = new Image();
    tmpImg.src = imagePath;
    tmpImg.onload = () => {};
    tmpImg.onerror = () => {};
  };

const Loading = ({ color, background = "#123335", className, inGame = false }: LoadingProps) => {
    const { t } = useTranslation();
    const [width, setWidth] = useState<number>(10);
    const elemRef = useRef(null);

    const [isLoaded, setIsLoaded] = useState<boolean>(false); 

    useEffect(() => {
        preLoadImage("/images/logo/skin/logo-quantico.webp");
        setTimeout(() => {
          setIsLoaded(true);
        }, 500);
      }, []);

    useEffect(() => {
        const elem = elemRef.current;
        let id: NodeJS.Timeout | null = null;
        id = setInterval(() => {
            setWidth((prevWidth) => {
                if (prevWidth >= 99) {
                    if (id) clearInterval(id);
                    return prevWidth;
                }
                const newWidth = prevWidth + 1;
                if (elem) {
                    elem.style.width = `${newWidth}%`;
                    elem.innerText = `${newWidth}%`;
                }
                return newWidth;
            });
        }, 10)
    
        return () => {
            if (id) clearInterval(id);
        };
    }, []);

    return (
        <div className={`loader-container z-[1200] fixed top-0 left-0 w-[100vw] bottom-0 bg-[${background}] ${className ? className : ''}`}>
            <div className="loader absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                {(inGame && isLoaded )?
                    <div className="loading-game">
                        <div className="loading-game-content">
                            <div className="loading-logo animation-container">
                                <img src="/images/logo/skin/logo-quantico.webp" alt="Image" className="image" />
                            </div>
                            <div className="h-[24px]">
                                 <div className="loading-inprogress-bar" id="myProgress">
                                    <div className="loading-inprogress-bar-child" id="myBar" style={{ width: `${width}%` }}></div>
                                    <div className="loading-inprogress-bar-child-blur" style={{ width: `${width}%` }}></div>
                                </div>
                                <div className="loading-text">{t("Loading")}...</div>
                            </div>
                        </div>
                    </div>
                    :
                    <span className="w-[50px]">
                        <IconLoading color={`${color}`} />
                    </span>
                }
            </div>
        </div>
    );
};
export default Loading;