import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectGame } from './select-game';
import { ChangeGameIconSvg } from '../../../assets/icons/ChangeGameIconSvg';
import { PATH_ROUTES } from '../../../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  Game,
  GameHelper,
  Sound,
  SoundManager,
  gameActions,
  selectActiveGame,
  tournamentActions,
  selectOpenTutorial,
} from '@quanticogames/gameclient-core';
import ModalComponent from 'shared/components/modal';
import { mapStyleGame } from 'utils/styleGame';
import { t } from 'i18next';
import { cashGame } from 'constants/common';

export const ChangeGame = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const activeGame: Game = useSelector(selectActiveGame);
  const openTutorialValue = useSelector(selectOpenTutorial);
  const smallText = activeGame?.code === 'AssoPigliaTutto' && GameHelper.isMobile() && GameHelper.isPortrait();

  useEffect(() => {
    if (openTutorialValue) {
      setIsOpen(false);
    }
  }, [openTutorialValue])
  const handleClickOpen = () => {
    SoundManager.play(Sound.clickButton);
    setIsOpen(true);
  };

  const handleClose = () => {
    SoundManager.play(Sound.clickButton);
    setIsOpen(false);
  };

  const handleClickButton = (game) => {
    if (game.code === activeGame.code) {
      setIsOpen(false);
      return;
    };
    if (cashGame.includes(activeGame?.code)) {
      dispatch(gameActions.unSubscribeCashGame({ gameId: activeGame?.id }));
    }
    dispatch(gameActions.unSubscribeGame({ gameId: activeGame?.id }));
    dispatch(gameActions.activeGame({ game }));
    dispatch(tournamentActions.resetFilters({}));
    navigate(PATH_ROUTES.SIT_AND_GO);
    setIsOpen(false);
  };

  return (
    <>
      <div
        className="change-game-button"
        onClick={handleClickOpen}
        style={{
          background: mapStyleGame(activeGame?.code)?.backgroundGradient,
          color: mapStyleGame(activeGame?.code)?.color,
          ...(smallText ? { fontSize: 12 } : {}),
        }}
      >
        <div className='change-game' style={{ borderColor: mapStyleGame(activeGame?.code)?.color }}>
          <span className='change-game-name'>{activeGame?.name}</span>
        </div>
        <ChangeGameIconSvg />
      </div>
      <ModalComponent open={isOpen} onClose={handleClose} title={t('Select Game')} size='large'>
        <SelectGame onClick={handleClose} onClickButton={handleClickButton} />
      </ModalComponent>
    </>
  );
};
