import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Container, FormControlLabel, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import { COLORS } from '../../constants/colors';
import { SearchInput } from '../../shared/Filters';
import { chipFullType, cashAndSit, skillGames, tournamentAndSit, REGULATION_URL } from '../../constants/common';
import { SKIN } from 'constants/skin';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './index.scss';
import {
  TournamentFilter,
  TournamentStateEnum,
  tournamentActions,
  SoundManager,
  Sound,
  selectActiveGame,
  Game,
  GameHelper,
  selectCasino,
  IClubSettings,
  selectUserClubs,
  gameActions,
  clubApi,
} from '@quanticogames/gameclient-core';
import { useDispatch } from 'react-redux';
import { NAV_LINKS_SKILL_GAMES, NAV_LINK_CASH_GAMES, SIT_AND_CASH, OTHER_GAMES, TOURNAMENT_AND_SIT } from 'constants/router';
import { Toggle } from 'shared/components/toggle';
import ButtonComponent from 'shared/components/button';
import { FlexBox } from 'shared/Flex';
import { FilterIcon } from 'assets/icons/FilterIcon';
import FilterMobile from 'layouts/control-panel/component/filter-mobile';
import { PATH_ROUTES } from 'constants/routes';
import FilterMobileCashTable from 'layouts/control-panel/component/filter-mobile-cashtable';
import { InfoOutlined } from '@mui/icons-material';
import { ClubIcon } from 'assets/icons/ClubIcon';

const Root = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '20px',
  '.active': {
    svg: {
      path: {
        fill: '#fff',
      },
    },
  },
}));

const Text = styled('span')(() => ({
  marginLeft: '8px',
  fontSize: '0.875rem',
}));

export const ControlPanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const skinType: string = useSelector(selectCasino) || 'quantico';
  const isMobile: boolean = GameHelper.isMobile();
  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const game: Game = useSelector(selectActiveGame);
  const userClubs: any = useSelector(selectUserClubs);
  const isCashTable = window.location.href.includes(PATH_ROUTES.CASH_TABLE);
  const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
  const hideFullTableDefault = isMobile || isMobileScreenWidth ? false : true;
  const [isFilterStatus, setIsFilterStatus] = useState<boolean>(
    localStorage['hideFullTable'] ? JSON.parse(localStorage['hideFullTable']) : hideFullTableDefault
  );
  const [isClickMode, setIsClickMode] = useState<boolean>(false)
  const [hideClub, setHideClub] = useState<boolean>(false);

  const filterFullToggle = (e) => {
    SoundManager.play(Sound.clickButton);
    const checked = e.target.checked;
    localStorage.setItem('hideFullTable', checked);
    setIsFilterStatus(checked);
  };

  useEffect(() => {
    setIsClickMode(false)
    const updatedFilterOptions = {
      state: isFilterStatus && TournamentStateEnum.open,
    } as TournamentFilter;
    dispatch(tournamentActions.updateFilters(updatedFilterOptions));
  }, [isFilterStatus, isClickMode]);

  let navLinkDataActive = [];

  if (skillGames.includes(game?.code)) {
    navLinkDataActive = NAV_LINKS_SKILL_GAMES;
  } else if (chipFullType.includes(game?.code)) {
    navLinkDataActive = NAV_LINK_CASH_GAMES;
  } else if (cashAndSit.includes(game?.code)) {
    navLinkDataActive = SIT_AND_CASH;
  } else if (tournamentAndSit.includes(game?.code)) {
    navLinkDataActive = TOURNAMENT_AND_SIT;
  }
  else {
    navLinkDataActive = OTHER_GAMES;
  }

  useEffect(() => {
    if (game?.code === 'Trentuno' || game?.code === 'GinRummy') {
      setHideClub(true)
    }
    else {
      setHideClub(false)
    }
  }, [game?.code]);

  const CustomToggle = (props) => (
    <Toggle
      variant="dark"
      backgroundicon={`${SKIN[skinType].backgroundToggle}`}
      backgroundtoggledown={`${SKIN[skinType]?.backgroundToggleDown}`}
      {...props}
    />
  );

  const onModeClick = () => {
    setIsClickMode(true)
    SoundManager.play(Sound.clickButton)
    const updatedFilterOptions = {
      code: '',
      state: isFilterStatus && TournamentStateEnum.open,
    } as TournamentFilter;
    dispatch(tournamentActions.resetFilters(updatedFilterOptions))
  }

  useEffect(() => {
    const getUserClubs = async (id: number = null) => {
      try {
        const data: IClubSettings[] = await clubApi.getListClub(game?.id)
        dispatch(gameActions.updateClubsInfo(data.map((club, index) => {
          return {
            id: club.id,
            clubSetting: club,
            active: index === 0,
            tournamentAAMS: club?.tournamentAAMS,
            tournamentCode: club?.tournamentCode
          }
        })))
      }
      catch (error) {
        console.log(error)
      }
    }

    if(game?.id){
      getUserClubs()
    }

  }, [game?.id]);

  return (
    <div className="header-tabs">
      <Container>
        <Root className="header-tabs-container">
          {(!isMobileScreenWidth && !isMobile) && (
            <div className="nav-link-game-mode items-center flex gap-[28px] header-menu">
              {navLinkDataActive.map((item, index) => (
                <NavLink
                  onClick={onModeClick}
                  key={`nav-link-${index}`}
                  to={item.link}
                  className="header-menu-link"
                >
                  {item.icon}
                  <Text>{t(item.title)}</Text>
                </NavLink>
              ))}
              {!hideClub && <NavLink
                onClick={onModeClick}
                to={PATH_ROUTES.CLUB}
                className="header-menu-link club-menu-link"
              >
                <ClubIcon />
                <Text>{t('Club')}</Text>
               {userClubs.length > 0 && <span className="club-count">{" "} ({userClubs.length})</span>}
              </NavLink>}
            </div>
          )}
          <Stack
            spacing={(isMobile || isMobileScreenWidth) ? 3 : 6}
            direction='row'
            alignItems='center'
            justifyContent={(isMobile || isMobileScreenWidth) ? 'space-between' : 'flex-end'}
            sx={{ padding: '5px 0', width: '100%' }}
          >
            {(isMobile || isMobileScreenWidth) ? (
              <FlexBox alignItems='center' gap={10}>
                {isMobileScreenWidth && game?.code && (
                  <a
                    onClick={() => SoundManager.play(Sound.clickButton)}
                    href={`${REGULATION_URL}/game?tabs=${game?.code}&casino=${skinType}`}
                    className="question-icon flex items-center text-[#fff]"
                  >
                    <InfoOutlined color={SKIN[skinType].colorNameUser} style={{ width: 26, height: 26 }} />
                  </a>
                )}

                <ButtonComponent
                  variant='text'
                  onClick={() => setShowModalFilter(true)}
                  icon={<FilterIcon />}
                  className='filter-btn !min-w-[0]'
                />
                {isCashTable ? (
                  <FilterMobileCashTable open={showModalFilter} onClose={() => setShowModalFilter(false)} />
                ) : (
                  <FilterMobile open={showModalFilter} onClose={() => setShowModalFilter(false)} />
                )}
              </FlexBox>
            ) : (
              <FormControlLabel
                control={<CustomToggle checked={isFilterStatus} />}
                label={t('Hide full tables')}
                labelPlacement="end"
                sx={{
                  color: COLORS.white,
                  marginRight: 0,
                  '& .MuiFormControlLabel-label': {
                    fontSize: '12px',
                    fontWeight: 400,
                    flex: 1,
                    whiteSpace: 'nowrap',
                    marginRight: 0,
                    color: COLORS.white,
                  },
                }}
                onChange={filterFullToggle}
              />
            )}
            <SearchInput />
          </Stack>
        </Root>
      </Container>
    </div>
  );
};
