import Loading from 'shared/components/loading';
import { CASH_GAME_TABLE_HEAD_DATA, CashGameTableItem } from 'pages/cash-table/component/list/cash-table-item';
import { useEffect, useMemo, useState } from 'react';
import ModalComponent from 'shared/components/modal';
import TableComponent from 'shared/components/tables';
import TableNoData from 'shared/components/tables/component/table-nodata';
import { SortingOrder } from 'types/commonTable';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  CashTable,
  CashTableActiveState,
  Game,
  GameHelper,
  User,
  cashTableActions,
  selectActiveGame,
  selectCashTables,
  selectCashTablesLoading,
  selectCurrentUser,
  selectFavoriteCashTables,
  selectFilterOptions,
  tournamentActions,
} from '@quanticogames/gameclient-core';
import useDrawerToggle from 'hooks/useDrawerToggle';
import { DRAWER_POSITIONING } from 'types/rightSideDrawer';
import { SideDrawer } from 'shared/components/side-drawer';
import { CashGameDetail } from 'pages/cash-table/component/detail';
import { useMediaQuery, useTheme } from '@mui/material';
import { CashTableFilter } from 'shared/Filters/CashTableFilter';
import { useCashTableFilter } from 'shared/Filters/FilterConstants';
import { useTranslation } from 'react-i18next';
import CashListDesktop from 'shared/components/CashList/desktop';
import CashListMobile from 'shared/components/CashList/mobile';
const TableCash = () => {
  const [sortDirection, setSortDirection] = useState<SortingOrder.ASC | SortingOrder.DESC>(SortingOrder.ASC);
  const [sortColumn, setColumn] = useState<string>();
  const [showFilterDescription, setShowFilterDescription] = useState<boolean>(false);
  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = GameHelper.isMobile();
  const dispatch = useDispatch();
  const loading: boolean = useSelector(selectCashTablesLoading);
  const tables: CashTable[] = useSelector(selectCashTables);

  const { drawerAnchor, toggleDrawer } = useDrawerToggle();
  const handleClose = () => toggleDrawer(DRAWER_POSITIONING.RIGHT, false);
  const filterOptions: any = useSelector(selectFilterOptions)
  const currentUser: User = useSelector(selectCurrentUser);
  const favoriteTables: any = useSelector(selectFavoriteCashTables);
  const game: Game = useSelector(selectActiveGame);
  const [filterList, setFilterList] = useState<any>({});
  const [initialFilterList, setInitialFilterList] = useState({});
  const [oldGameMode, setOldGameMode] = useState<string>(null)

  const { t } = useTranslation();
  let listCashTable = [];

  const onSelect = (id: number) => {
    dispatch(cashTableActions.loadCashTable(id));
    toggleDrawer(DRAWER_POSITIONING.RIGHT, true);
  };

  const handleSortTable = (column, direction) => {
    setColumn(column);
    setSortDirection(direction);
  };

  const handleFilter = (column) => {
    if (column === 'description') {
      setShowFilterDescription(true);
    }
  };

  const isUserInTournament = (tournament, id) => tournament.participants?.some((p) => p.userId === id);

  const isUserSubscribe = (tournament, id) =>
    isUserInTournament(tournament, id) &&
    tournament?.state !== CashTableActiveState.Pending &&
    tournament?.state !== CashTableActiveState.Inprogress;

  if (tables.length > 0 && currentUser !== null && currentUser !== undefined) {
    listCashTable = tables.filter((cashtb) => {
      let filter = true;

      filter =
        cashtb?.state !== CashTableActiveState.Cancelled &&
        cashtb?.state !== CashTableActiveState.Terminated;

      if (filter && game?.id !== cashtb.gameId) {
        filter = false;
      }

      if (filter) {
        filter = filter && Boolean(cashtb?.numberOfSeats);
      }
      if (filter && filterOptions?.costRanges && filterOptions.costRanges.length > 0) {
        const inRange = !!filterOptions.costRanges.find(
          (range) => cashtb.invitation <= range.costTo && cashtb.invitation >= range.costFrom
        );
        filter = inRange;
      }
      if (filter && filterOptions?.code && cashtb?.code) {
        const cashtbConvert = {
          ...cashtb,
          titleConvert: `${cashtb.id} - ${cashtb.title} - Cash`,
        };

        filter = cashtb.code.toLowerCase().includes(filterOptions.code.toLowerCase()) || cashtbConvert.titleConvert.toLowerCase().includes(filterOptions.code.toLowerCase());
      }
      if (filter && filterOptions?.state && cashtb?.state) {
        filter = cashtb?.activeCount !== cashtb?.numberOfSeats
        // filterOptions.state == cashtb?.state ||
        // cashtb.participants?.some((user) => user.userId === currentUser?.id) ||
        // cashtb?.state === CashTableActiveState.Pending;
      }
      if (filterOptions?.maxPlayers?.length > 0 && cashtb?.numberOfSeats && filter) {
        filter = filterOptions.maxPlayers.indexOf(cashtb.numberOfSeats) > -1;
      }
      return filter;
    });

    listCashTable.sort((a, b) => {
      const isUserInTournamentA = isUserInTournament(a, currentUser.id);
      const isUserInTournamentB = isUserInTournament(b, currentUser.id);

      if (isUserInTournamentA && !isUserInTournamentB) return -1;
      if (!isUserInTournamentA && isUserInTournamentB) return 1;
    });

    listCashTable.sort((a, b) => {
      const isUserSubscribeA = isUserSubscribe(a, currentUser.id);
      const isUserSubscribeB = isUserSubscribe(b, currentUser.id);

      if (isUserSubscribeA && !isUserSubscribeB) return -1;
      if (!isUserSubscribeA && isUserSubscribeB) return 1;
    });
  }

  const sortListCashTables = useMemo(() => {
    if (sortColumn && sortDirection) {
      const dir = sortDirection === SortingOrder.ASC ? 1 : -1;
      const newCashTable = [...listCashTable];
      newCashTable.sort((a, b) => (a[sortColumn] > b[sortColumn] ? 1 : -1) * dir);
      return [...newCashTable];
    }
    const [favorite, other] = listCashTable.reduce(
      ([fav, other], table) => {
        if (table.favorite) {
          fav.push(table);
        } else {
          other.push(table);
        }
        return [fav, other];
      },
      [[], []]
    );
    return [...favorite, ...other];
  }, [listCashTable, sortColumn, sortDirection]);

  const onClose = () => setShowFilterDescription(false)

  useEffect(() => setInitialFilterList(filterList), [showFilterDescription]);

  const handleCancelFilter = () => {
    setFilterList(initialFilterList);
    onClose();
  }

  const handleApply = () => {
    dispatch(tournamentActions.updateFilters(filterList));
    onClose();
  }

  const cashTableFilter = useCashTableFilter();

  const filterData = (name: string, values: any[]) => {
    const updatedFilterOptions: any = { ...filterList };
    const options = cashTableFilter[name].options.filter(o => values.includes(o.id));
    switch (name) {
      case 'cost':
        updatedFilterOptions.costLabels = options.map(o => o.label);
        updatedFilterOptions.costRanges = options.map(o => o.costRange);
        break;

      case 'maxPlayers':
        updatedFilterOptions.maxPlayersLabels = options.map(o => o.label);
        updatedFilterOptions.maxPlayers = options.map(o => o.maxPlayers);
        break;
    }
    setFilterList(updatedFilterOptions);
  };

  useEffect(() => {
    if (window.location.href === oldGameMode) return;
    setOldGameMode(window.location.href)
    setFilterList({})
  }, [window.location.href]);

  return (
    <>
      {loading ? (
        <Loading color="#0E345E" background="#fff" className="!top-[200px] !md:top-[192px] loading-tournament" />
      ) : (
        <>
          {(isMobileScreenWidth || isMobile) ? (
            <div className="tournament-mobile-list">
              {sortListCashTables.length > 0 ?
                (
                  <CashListMobile data={sortListCashTables} onSelect={onSelect} />
                )
                : (
                  <TableNoData />
                )}
            </div>
          ) : (
            <CashListDesktop data={sortListCashTables} onSelect={onSelect} onFilterModal={handleFilter} onSortTable={handleSortTable} />
          )}

          <ModalComponent
            open={showFilterDescription}
            className='cashtable-filter-modal'
            title={t('Description filter')}
            onClose={handleCancelFilter}
            typeModal='filter'
            onCancel={handleCancelFilter}
            onOk={handleApply}
            textOk={t('Apply')}
            textCancel={t('Cancel')}
            showFooter={true}
            size='small'
          >
            <CashTableFilter filterList={filterList} filterData={filterData} />
          </ModalComponent>
        </>
      )}

      <SideDrawer
        anchor="right"
        open={drawerAnchor.right}
        onClose={handleClose}
        showHeader={true}
        titleHeader={t('Tournament information')}
        showButtonBack={false}
      >
        <CashGameDetail onClose={handleClose} />
      </SideDrawer>
    </>
  );
};
export default TableCash;
