import { CashTable, GameHelper, selectFavoriteCashTables, selectOrientation } from '@quanticogames/gameclient-core';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { CashGameTableItemMobile } from 'pages/cash-table/component/list/cash-item-mobile';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

interface CashListMobileProps { 
    data: CashTable[];
    onSelect: (id: number) => void;
}
function CashListMobile(props: CashListMobileProps) {
    const { data, onSelect } = props;
    const isLandscape = useSelector(selectOrientation) === 'landscape';
    // The scrollable element for your list
    const listRef = React.useRef(null)
    const favoriteTables = useSelector(selectFavoriteCashTables);
    
    const [enable, setEnable] = React.useState(false);
    // The virtualizer
    const virtualizer = useWindowVirtualizer({
        count: data.length,
        scrollMargin: listRef.current?.offsetTop ?? 0,
        estimateSize: () => isLandscape ? 55 : 120,
        overscan: 10,
        enabled: enable,
    })
    
    useEffect(() => { 
        setEnable(false);
        setTimeout(() => { 
            setEnable(true);
        }, 100);
    }, [isLandscape])
    
    return (
        <>
            <div ref={listRef} className="List">
                <div
                    style={{
                        height: `${virtualizer.getTotalSize()}px`,
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    {virtualizer.getVirtualItems().map((item, index) => {
                        const tournament = data[item.index];
                        return (
                            <div
                                key={index}
                                className={item.index % 2 ? 'ListItemOdd' : 'ListItemEven'}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: `${item.size}px`,
                                    transform: `translateY(${item.start - virtualizer.options.scrollMargin
                                        }px)`,
                                    // borderBottom: '1px solid #e0e0e0',
                                }}
                            >
                                <CashGameTableItemMobile
                                    key={`tournament-mobile-${tournament.id}`}
                                    index={item.index}
                                    data={tournament}
                                    onSelect={onSelect}
                                    favorite={favoriteTables.find(fav => fav.id === tournament.id)}
                                />
                            </div>
                        )
                    }
                    )}
                </div>
            </div>
        </>
    )
}

export default React.memo(CashListMobile);