import { useTranslation } from "react-i18next"
import { colorTags } from "utils/tournamentTitle"
import React from "react"
import "./index.scss"
type TagsComponentTyping = {
    text: string,
    color?: colorTags
}

const TagsComponent = ({text, color = colorTags.PROCESSING}: TagsComponentTyping) => {
    const { t } = useTranslation('common')
    return (
        <span className={`tags-component tags-${color}`}>{t(text)}</span>
    )
}

export default React.memo(TagsComponent)