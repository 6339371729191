import common from './common.json';
import briscola from './briscola.json';
import burraco from './burraco.json';
import itPoker from './itPoker.json';
import scala40 from './scala40.json';
import scopa from './scopa.json';
import tresette from './tresette.json';
import telesina from './telesina.json';
import setteEMezzo from './setteEMezzo.json';
import peppa from './peppa.json';
import cirulla from './cirulla.json';
import solitario from './solitario.json';
import scopone from './scopone.json';
import bestia from './bestia.json';
import assoPigliaTutto from './assoPigliaTutto.json';
import trentuno from './trentuno.json';
import club from './club.json';
import ginRummy from './ginRummy.json';

export default {
  common,
  briscola,
  burraco,
  itPoker,
  scala40,
  scopa,
  tresette,
  telesina,
  setteEMezzo,
  peppa,
  cirulla,
  solitario,
  bestia,
  assoPigliaTutto,
  scopone,
  trentuno,
  club,
  ginRummy,
};
