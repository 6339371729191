import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    BuyinPopup,
    CashTable,
    cashTableActions,
    selectBuyinPopup,
    selectCashTable,
} from '@quanticogames/gameclient-core';
import {
    StyledAnnounce,
} from './styled';
import ModalComponent from 'shared/components/modal';
import ButtonComponent from 'shared/components/button';
import { useTranslation } from 'react-i18next';

const ModalJoinTableSuccess: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const table: CashTable = useSelector(selectCashTable);
    const buyinPopup: BuyinPopup = useSelector(selectBuyinPopup);
    const { ticketId } = buyinPopup;
    const timeoutRef = useRef<any>();

    const onCloseModal = () => {
        dispatch(cashTableActions.setBuyinPopup({
            aamsCode: null,
            ticketId: null,
        }));
    };

    useEffect(() => {
        if (ticketId) {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => onCloseModal(), 2500);
        };
    }, [ticketId]);

    return (
        <ModalComponent 
            open={!!ticketId} 
            title={t('Buy-in')}
            typeModal='confirm'
        >
            <StyledAnnounce>
                <p>{t('You have registered for table')} <span>{table?.code}</span> {t('with ticket')} <span>{ticketId}</span></p>
            </StyledAnnounce>
            <ButtonComponent
                title={t('Close')}
                onClick={onCloseModal}
            />
        </ModalComponent>
    )
}

export default ModalJoinTableSuccess;
