import React, { useState } from 'react';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';

function FullScreenButton() {
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const clickScreenMode = () => {
    try {
      if (fullScreen && document?.fullscreenElement) {
        setFullScreen(false);
        document?.exitFullscreen();
      } else {
        setFullScreen(true);
        requestFullscreen();
      }
    }
    catch (error) {
      console.log('error', error);
    }
  };

  const requestFullscreen = () => {
    const element = document.documentElement as HTMLElement & {
      webkitRequestFullscreen?: () => Promise<void>;
      mozRequestFullScreen?: () => Promise<void>;
      msRequestFullscreen?: () => Promise<void>;
    };
    
    const requestMethod =
      element.requestFullscreen ||
      element?.webkitRequestFullscreen ||
      element?.mozRequestFullScreen ||
      element?.msRequestFullscreen;
    if (requestMethod) {
      requestMethod.call(element);
    }
  };
  return (
    <div className="">
      <button
        className="ml-1 mt-[5px] pb-[2px] px-[3px] max-w-[110px] rounded text-white bg-[#175EBE]"
        onClick={clickScreenMode}
      >
        {fullScreen ? (
          <ZoomInMapIcon style={{ fontSize: '20px', color: 'white', marginBottom: 1 }} />
        ) : (
          <ZoomOutMapIcon style={{ fontSize: '20px', color: 'white', marginBottom: 1 }} />
        )}
      </button>
    </div>
  );
}

export default FullScreenButton;
