import Button from '@mui/material/Button';
import './index.scss';

export type ColorButton = 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';

type ButtonComponentProps = {
    title?: string,
    startIcon?: React.ReactNode,
    endIcon?: React.ReactNode,
    fullWidth?: boolean,
    href?: string,
    size?: 'small' | 'medium' | 'large',
    sx?: any,
    variant?: 'contained' | 'outlined' | 'text',
    color?: ColorButton,
    rouned?: 'medium' | 'large',
    className?: string,
    disabled?: boolean,
    onClick?: React.MouseEventHandler<HTMLElement>;
    icon?: React.ReactNode,
    target?: string
}

const ButtonComponent = (props: ButtonComponentProps) => {
    const {title, 
        startIcon, 
        endIcon, 
        fullWidth, 
        href, 
        variant = 'outlined', 
        sx, 
        color = 'primary', 
        size='medium',
        rouned,
        className,
        disabled,
        onClick,
        icon,
        target
    } = props;

    return (
        <Button 
            startIcon={startIcon || null}
            endIcon={endIcon || null}
            fullWidth={fullWidth}
            href={href}
            variant={variant}
            color={color}
            size={size}
            disabled={disabled}
            onClick={onClick}
            target={target}
            className={`
                ${ rouned ? `btn-rouned-${rouned}` : '' }
                ${ className ? className : '' }
                button-${size}
                button-component
            `}
            sx={{
                fontSize: '14px',
                borderRadius: '3px',
                padding: '0 10px',
                ...sx,
            }}
        >
            {icon ? icon : title}
        </Button>
    )
}
export default ButtonComponent