import { useEffect, useMemo, useState } from 'react';
import { styled, TableCell, TableRow } from '@mui/material';
import {
  PricePoolDistribution,
  selectActiveTournament,
  Tournament,
  TournamentStateEnum,
} from '@quanticogames/gameclient-core';
import { useSelector } from 'react-redux';
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';
import TableComponent from 'shared/components/tables';
import { useTranslation } from 'react-i18next';
import { ConditionComponent } from 'shared/condition';
import { Select, Space } from 'antd';

const HEAD_DATA_PRIZE_POOL = [
  {
    id: 'position',
    label: 'Pos.',
    align: 'center'
  },
  {
    id: 'prize',
    label: 'Prize',
  },
];

const StyledTableCell = styled(TableCell)(() => ({}));

type PrizePoolTableProps = {
  subscribeSimulation?: boolean;
  prizeInProgress: any;
  prizeDistribution: PricePoolDistribution[];
};

export const PrizePoolTable = ({
  prizeInProgress,
  prizeDistribution,
}: PrizePoolTableProps) => {
  const { t } = useTranslation();
  const tournament: Tournament = useSelector(selectActiveTournament);
  const [prizeDistribute, setPrizeDistribute] = useState<number[]>([]);
  const [subcribeNumber, setSubcribeNumber] = useState<number>(tournament?.minPlayers);
  const [satellitePrize, setSatellitePrize] = useState<number[]>([]);


  const isInGame =
    tournament?.state === TournamentStateEnum.inprogress ||
    tournament?.state === TournamentStateEnum.end ||
    tournament?.state === TournamentStateEnum.terminated;

  const subcribeSimulationList = Array.from(new Set(prizeDistribution?.map((entry) => entry?.participants)).values());

  useEffect(() => {
    if (isInGame) {
      if (prizeInProgress) {
        handleSubscribeSimulation(tournament?.participants?.length);
      }
      return;
    }
    if (prizeDistribution) handleSubscribeSimulation(subcribeNumber);
  }, [prizeDistribution, prizeInProgress, isInGame]);

  const handleSubscribeSimulation = (e: number) => {
    setSubcribeNumber(e);
    let number: number;

    if (e >= subcribeSimulationList[subcribeSimulationList.length - 1]) {
      number = subcribeSimulationList[subcribeSimulationList.length - 1];
    } else {
      for (let i = 0; i < subcribeSimulationList.length - 1; i++) {
        if (e >= subcribeSimulationList[i] && e < subcribeSimulationList[i + 1]) {
          number = subcribeSimulationList[i];
        }
      }
    }

    const prizeDistributeArr = [];
    prizeDistribution
      .filter((item) => item.participants === number)
      .forEach((item) => {
        const a = item.toPos - item.fromPos;
        if (a !== 0) {
          for (let index = 0; index <= a; index++) {
            prizeDistributeArr.push(item.rate);
          }
        } else {
          prizeDistributeArr.push(item.rate);
        }
      });

    setPrizeDistribute(prizeDistributeArr);
  };

  const totalPrize = useMemo(() => {
    if (tournament?.freeroll) return tournament?.rewardValue;
    const totalPrizeByCost = (tournament?.cost - tournament?.fee) * subcribeNumber;
    return Math.max(totalPrizeByCost, tournament?.guaranteedValue);
  }, [tournament, subcribeNumber]);

  const subscribeOption = useMemo(() => {
    const minPlayers = tournament?.minPlayers || 0;
    const maxPlayers = tournament?.maxPlayers || 0;
    const players = maxPlayers - minPlayers + 1;
    return Array.from(Array(players)).map((_, index) => minPlayers + index);
  }, [tournament]);

  useEffect(() => {

    if (tournament?.parentGuaranteeCode) {
      const parentCost = tournament?.parentGuaranteeCost;
      const fee = tournament?.fee;
      const totalPrize = ((tournament?.cost - fee) * tournament?.minPlayers)
      const secondPrize = totalPrize - parentCost;
      setSatellitePrize([parentCost, secondPrize]);
    }
  }, [tournament]);

  return (
    <>
      {tournament?.type === 'Scheduled' && !isInGame && (
        <div className="flex justify-end mb-[10px] items-center pr-[5px]">
          <div className="mr-[10px]">{t("Simulation prize pool")}</div>
          {/* <select
            className="w-[80px] h-[30px] rounded-[4px] border-[1px] border-[#D9D9D9]"
            onChange={(e) => handleSubscribeSimulation(Number(e.target.value))}
          >
            {subscribeOption?.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select> */}
          <Select
            defaultValue={subcribeNumber}
            style={{ width: 120 }}
            onChange={handleSubscribeSimulation}
            options={subscribeOption.map(item => {
              return { value: item, label: item }
            })}
          />
        </div>
      )}
      <ConditionComponent condition={Boolean(tournament?.parentGuaranteeCode)}>
        <p style={{
          textAlign: 'right',
          marginRight: 14,
          fontSize: 14,
        }}>
          {t('Simulation prize pool') + ' ' + tournament?.minPlayers}
        </p>
      </ConditionComponent>
      <TableComponent columns={HEAD_DATA_PRIZE_POOL}>
        {Array.from(Array(subcribeNumber)).map((_, index) => {
          return (
            <TableRow key={`prize-pool-table-item-${index}`}>
              <StyledTableCell width="60px" style={{ textAlign: 'center' }}>
                {index + 1}°
              </StyledTableCell>
              <ConditionComponent condition={!tournament?.parentGuaranteeCode}>
                <StyledTableCell>
                  {index >= prizeDistribute?.length
                    ? '-'
                    : !isInGame
                      ? `€ ${formatMoney(
                        convertCentToEuro(Math.floor(prizeDistribute[index] * totalPrize))
                      )}`
                      : `€ ${formatMoney(convertCentToEuro(prizeInProgress[index]?.amount))}`}
                </StyledTableCell>
              </ConditionComponent>

              <ConditionComponent condition={Boolean(tournament?.parentGuaranteeCode)} >
                <StyledTableCell>
                  <ConditionComponent condition={index === 0}>
                    {t('qual.', { tournamentCode: tournament?.parentGuaranteeCode })}
                  </ConditionComponent>
                  <ConditionComponent condition={index === 1}>
                    {`€ ${formatMoney(convertCentToEuro(satellitePrize[1]))}`}
                  </ConditionComponent>
                  <ConditionComponent condition={index >= 2}>
                    {` - `}
                  </ConditionComponent>
                </StyledTableCell>
              </ConditionComponent>
            </TableRow>
          );
        })}
      </TableComponent>
    </>
  );
};