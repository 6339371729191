
import {
  selectBalance,
  selectWaitingTournament,
  Tournament,
  gameActions,
  selectTokens,
  convertCentToEuro,
  formatMoney,
} from '@quanticogames/gameclient-core';
import { Box, Pagination, styled, TableCell, TableRow} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import ModalComponent from 'shared/components/modal';
import { useTranslation } from 'react-i18next';

type ModalConfirmSubscribeProps = {
  isOpen: boolean;
  data: any;
  onCloseModal?: () => void;
  checkbox?: boolean;
  getToken?: (token) => void;
};
const TokenModal = ({ isOpen, data, onCloseModal, checkbox, getToken }: ModalConfirmSubscribeProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cost = data?.cost;
  const [isEnoughMoney, setIsEnoughMoney] = useState<boolean>(false);
  const [isNoEnoughMoney, setIsNoEnoughMoney] = useState<boolean>(false);
  const balance = useSelector(selectBalance) as number;
  const waitingTournament = useSelector(selectWaitingTournament(data?.id)) as Tournament;
  const tokens = useSelector(selectTokens) as any;
  const tokenData = Array.isArray(tokens) ? tokens : tokens.data;
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    const rootApi = data?.tournamentId ? 'listAvailableToken?' : 'listToken?';
    const queryString = Object.keys(data)
      .map((key) => key + '=' + data[key])
      .join('&');

    dispatch(gameActions.getTokens(rootApi + queryString + `&page=${page}`));
  }, []);

  useEffect(() => {
    if (balance >= cost) {
      setIsEnoughMoney(true);
    } else {
      setIsNoEnoughMoney(true);
    }
  }, []);

  useEffect(() => {
    if (waitingTournament?.aamsCode) {
      onCloseModal();
      return;
    }
  }, [waitingTournament]);

  const getTokenCode = (e, row) => {
    if (e.target.checked) {
      getToken(row.code);
      setTimeout(() => {
        onCloseModal();
      }, 100);
    }
  };

  const PaginationWrapper = styled(Box)(() => ({
    paddingTop: '28px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  }));

  const StyledPagination = styled(Pagination)(({ theme }) => ({
    '& .MuiPaginationItem-root': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '17px',
      color: '#0B0E11',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main,
        },
      },
    },
  }));

  return (
    <>
      <ModalComponent
        open={isOpen}
        showIconClose={isEnoughMoney || isNoEnoughMoney}
        title={t('Registration tournament')}
        onClose={onCloseModal}
      >
        <TableContainer component={Paper} className='table-component'>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#3F51B5' }}>
                <TableCell sx={{ color: '#ffffff', width: '100px' }}>Nome</TableCell>
                <TableCell sx={{ color: '#ffffff', borderLeft: '1px solid ' }} align="center">
                  Gioco
                </TableCell>
                <TableCell sx={{ color: '#ffffff', borderLeft: '1px solid ' }} align="center">
                  Torneo
                </TableCell>
                <TableCell sx={{ color: '#ffffff', borderLeft: '1px solid ' }} align="center">
                  Valore
                </TableCell>
                <TableCell sx={{ color: '#ffffff', borderLeft: '1px solid ' }} align="center">
                  Data di scadenza
                </TableCell>

                {checkbox && (
                  <TableCell sx={{ color: '#ffffff', borderLeft: '1px solid ' }} align="center">
                    Scegliere
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            {tokenData.length !== 0 && (
              <TableBody>
                {tokenData.map((row) => (
                  <TableRow key={row.code}>
                    <TableCell component="th" scope="row">
                      {row?.tokenIssuanceName}
                    </TableCell>
                    <TableCell align="center" sx={{ borderLeft: '1px solid #ffffff' }}>
                      {JSON.parse(row?.gameCodes).join(', ')}
                    </TableCell>
                    <TableCell align="center" sx={{ borderLeft: '1px solid #ffffff' }}>
                      {row?.tournamentCode ? row.tournamentCode : '-'}
                    </TableCell>
                    <TableCell align="center" sx={{ borderLeft: '1px solid #ffffff' }}>
                      {row?.remainValue ? `€ ${formatMoney(convertCentToEuro(row?.remainValue))}` : '-'}
                    </TableCell>
                    <TableCell align="center" sx={{ borderLeft: '1px solid #ffffff' }}>
                      {String(new Date(row.expiredDate).getDate()).padStart(2, '0') +
                        '/' +
                        String(new Date(row.expiredDate).getMonth() + 1).padStart(2, '0') +
                        '/' +
                        new Date(row?.expiredDate).getFullYear()}
                    </TableCell>
                    {checkbox ? (
                      <TableCell align="center" sx={{ borderLeft: '1px solid #ffffff' }}>
                        <Checkbox onClick={(e) => getTokenCode(e, row)} />
                      </TableCell>
                    ) : (
                      ''
                    )}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {tokenData.length === 0 && <>Non hai alcun token</>}
          <PaginationWrapper>
            <StyledPagination
              onChange={(e, page) => setPage(page)}
              count={Math.ceil(tokenData.length / 10)}
              hidePrevButton
              hideNextButton
            />
          </PaginationWrapper>
        </TableContainer>
      </ModalComponent>
    </>
  );
};
export default TokenModal;
