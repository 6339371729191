import { Tournament, GameHelper, selectFavoriteCashTables, selectOrientation, selectFavoritesTournament, tournamentApi, tournamentActions } from '@quanticogames/gameclient-core';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import TournamentItemMobile from 'pages/tournament/component/list/tournament-item-mobile';
import PassModal from 'pages/tournament/modal/modal-pass';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getHighlightClass } from 'utils/tournamentTable';

interface CashListMobileProps {
    data: Tournament[];
    onSelect: (id: number) => void;
    onAction: (action: string, id: number, index?: number) => void;
}
function TournamentListMobile(props: CashListMobileProps) {
    const { data, onSelect, onAction } = props;
     const { t } = useTranslation();
    const [showPassModal, setShowPassModal] = React.useState<boolean>(false);
    const [contentDialog, setContentDialog] = React.useState<string>('');
    const isLandscape = useSelector(selectOrientation) === 'landscape';
    const dispatch = useDispatch();
    // The scrollable element for your list
    const listRef = React.useRef<HTMLDivElement | null>(null)
    const tournamentFavorite = useSelector(selectFavoritesTournament);
    const [enable, setEnable] = React.useState(false);
    // The virtualizer
    const virtualizer = useWindowVirtualizer({
        count: data.length,
        scrollMargin: listRef.current?.offsetTop ?? 0,
        estimateSize: () => isLandscape ?  55 : 120,
        overscan: 10,
        enabled: enable,
    })
    useEffect(() => {
        setEnable(false);
        setTimeout(() => {
            setEnable(true);
        }, 100);
    }, [isLandscape])

    const handlePlayScheduled = (id: number) => {
        const fetchData = async () => {
            try {
                const response = await tournamentApi.getPlayerStatus(id);
                if (response) {
                    if (response?.status === 2) {
                        setContentDialog('You have been eliminated from this tournament');
                        setShowPassModal(true);
                        return;
                    }
                    if (response?.status === 1 && response?.ongoingMatches?.length === 0) {
                        setContentDialog(t('You have passed to the next round, wait for your opponent'));
                        setShowPassModal(true);
                        return;
                    }
                }
                onAction('play', id);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    };

    const closePassModal = () => {
        setShowPassModal(false);
        dispatch(tournamentActions.resetAdvanceToNextRound({}));
    }

    return (
        <>
            <PassModal isOpen={showPassModal} onClose={() => closePassModal()} content={contentDialog} />
            <div ref={listRef}>
                <div
                    style={{
                        height: `${virtualizer.getTotalSize()}px`,
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    {virtualizer.getVirtualItems().map((item, index) => {
                        const tournament = data[item.index];
                        return (
                            <div
                                key={index}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    transform: `translateY(${item.start - virtualizer.options.scrollMargin}px)`,
                                }}
                            >
                                <TournamentItemMobile 
                                    key={`tournament-mobile-${tournament.id}`}
                                    index={item.index}
                                    data={tournament}
                                    onSelect={onSelect}
                                    onAction={onAction}
                                    className={`${getHighlightClass(tournament?.highlight)}`}
                                    favorite={tournamentFavorite.find(fav => fav.id === tournament.id)}
                                    handlePlayScheduled={handlePlayScheduled}
                                />
                            </div>
                        )
                    }
                    )}
                </div>
            </div>
        </>
    )
}

export default React.memo(TournamentListMobile);